import React, { useReducer } from 'react';
import cn from 'classnames';
import s from './CacheBackLink.module.scss';

export type CacheBackLinkProps = {
  className?: string;
};

export const CacheBackLink = ({ className }: CacheBackLinkProps) => {
  const [opened, toggle] = useReducer((v) => !v, false);
  return (
    <div className={cn(s.root, className)}>
      <button onClick={toggle} type="button" className={s.btn}>
        Условия акции с кешбэком
      </button>
      {opened && (
        <>
          <br />
          <br />
          <div className="c x0 y1 w2 h0">
            <div className="t m0 x1 h2 y2 ff1 fs0 fc0 sc0 lsb ws3">
              ПРАВИЛА АКЦИИ «ОСАГО с кешбэком 10% на услуги связи»<span className="ff2"> </span>
            </div>
            <div className="t m0 x2 h2 y3 ff1 fs0 fc0 sc0 lsb ws3">
              (далее по тексту –<span className="ff2"> </span>«Правила», Акция)<span className="ff2"> </span>
            </div>
            <div className="t m0 x3 h3 y4 ff3 fs0 fc1 sc0 lsb ws3"> </div>
            <div className="t m0 x3 h3 y5 ff4 fs0 fc1 sc0 lsb ws3">
              1.ОБЩИЕ ПОЛОЖЕНИЯ<span className="ff3"> </span>
            </div>
            <div className="t m0 x3 h3 y6 ff3 fs0 fc1 sc0 lsb ws3"> </div>
            <div className="t m0 x4 h3 y7 ff3 fs0 fc0 sc0 lsb ws3">
              1.1. <span className="_ _0"></span>
              <span className="ff4">
                Акция, <span className="_ _0"></span>проводится
              </span>
              <span className="ls0"> </span>
              <span className="ff4">
                в <span className="_ _0"></span>целях <span className="_ _0"></span>п<span className="_ _1"></span>
                родвижения <span className="_ _0"></span>услуг <span className="_ _0"></span>Организатора{' '}
                <span className="_ _0"></span>Акции<span className="_ _1"></span> <span className="_ _2"></span>
                <span className="ls5">
                  и <span className="_ _0"></span>
                </span>
                <span className="fc1 ws0">направлена</span>
              </span>
              <span className="fc1 ls0">
                {' '}
                <span className="ff4 lsb">на </span>
              </span>
            </div>
            <div className="t m0 x4 h3 y8 ff4 fs0 fc1 sc0 lsb ws3">
              привлечение <span className="_ _3"></span>внимания <span className="_ _3"></span>потенциальных{' '}
              <span className="_ _3"></span>потребителей <span className="_ _3"></span>(абонентов{' '}
              <span className="_ _3"></span>Организатора) <span className="_ _3"></span>к
              <span className="ff3">
                {' '}
                <span className="_ _3"></span>
                <span className="ff4">
                  Услу<span className="_ _3"></span>г<span className="_ _1"></span>ами{' '}
                </span>
              </span>
            </div>
            <div className="t m0 x4 h4 y9 ff4 fs0 fc1 sc0 lsb ws3">
              Организатора <span className="_ _4"> </span>Акции, <span className="_ _4"> </span>на{' '}
              <span className="_ _4"> </span>формирование <span className="_ _4"> </span>и<span className="_ _1"></span>
              <span className="lsc ws1">/и</span>ли <span className="_ _4"> </span>поддержание{' '}
              <span className="_ _4"> </span>интереса <span className="_ _4"> </span>к <span className="_ _4"> </span>
              ним, <span className="_ _4"> </span>а <span className="_ _4"> </span>также{' '}
            </div>
            <div className="t m0 x4 h3 ya ff4 fs0 fc1 sc0 lsb ws0">
              продвижение
              <span className="ff3 ws3">
                {' '}
                <span className="ff4">их на рынке.</span>
                <span className="fc0"> </span>
              </span>
            </div>
            <div className="t m0 x4 h3 yb ff3 fs0 fc0 sc0 lsb ws3">
              1.2. <span className="_ _5"> </span>
              <span className="ff4">
                Акция <span className="_ _5"> </span>не <span className="_ _5"> </span>является{' '}
                <span className="_ _5"> </span>лотере<span className="_ _6"></span>ей<span className="ff3 ls1"> </span>
                (как <span className="_ _5"> </span>это <span className="_ _5"> </span>определено{' '}
                <span className="_ _5"> </span>Федера<span className="_ _6"></span>льным <span className="_ _5"> </span>
                законом <span className="_ _7"> </span>РФ <span className="_ _5"> </span>от{' '}
              </span>
            </div>
            <div className="t m0 x4 h3 yc ff4 fs0 fc0 sc0 lsb ws3">
              11.11.2003 <span className="_ _8"> </span>№ <span className="_ _8"> </span>138
              <span className="ff3 ws0">-</span>ФЗ <span className="_ _8"> </span>«О <span className="_ _8"> </span>
              лотереях<span className="_ _1"></span>»<span className="_ _3"></span>)<span className="_ _1"></span>
              <span className="ff3">
                , <span className="_ _8"> </span>
              </span>
              п<span className="_ _1"></span>у<span className="_ _6"></span>бличным <span className="_ _8"> </span>конк
              <span className="_ _1"></span>урс<span className="_ _6"></span>ом <span className="_ _8"> </span>(как{' '}
              <span className="_ _8"> </span>это <span className="_ _8"> </span>определено{' '}
              <span className="_ _8"> </span>гл
              <span className="ff3">
                . <span className="_ _8"> </span>57{' '}
              </span>
            </div>
            <div className="t m0 x4 h3 yd ff4 fs0 fc0 sc0 lsb ws3">
              Гражданского <span className="_ _8"> </span>кодекса <span className="_ _9"> </span>РФ)
              <span className="ff3 ls2"> </span>и <span className="_ _8"> </span>проводи<span className="_ _1"></span>
              тся <span className="_ _8"> </span>в <span className="_ _9"> </span>соотве<span className="_ _6"></span>
              тствии <span className="_ _9"> </span>с <span className="_ _8"> </span>условиями,{' '}
              <span className="_ _9"> </span>у<span className="_ _3"></span>становленными{' '}
            </div>
            <div className="t m0 x4 h4 ye ff4 fs0 fc0 sc0 lsb ws3">
              настоящими <span className="_ _4"> </span>Пра<span className="_ _6"></span>вилами.{' '}
              <span className="_ _a"> </span>Акция <span className="_ _a"> </span>не <span className="_ _a"> </span>
              содержит <span className="_ _a"> </span>элемента <span className="_ _a"> </span>риска,{' '}
              <span className="_ _a"> </span>участи<span className="_ _1"></span>е <span className="_ _a"> </span>в{' '}
              <span className="_ _a"> </span>ней <span className="_ _a"> </span>является{' '}
            </div>
            <div className="t m0 x4 h3 yf ff4 fs0 fc0 sc0 lsb ws0">
              добровольным
              <span className="ff3 ws3">
                .{' '}
                <span className="ff4">
                  Фонды, необх<span className="_ _1"></span>одимые для п<span className="_ _1"></span>роведения Акции{' '}
                  <span className="_ _1"></span>формир<span className="_ _1"></span>уются за счет средств
                  <span className="_ _1"></span>{' '}
                </span>
              </span>
            </div>
            <div className="t m0 x4 h3 y10 ff4 fs0 fc0 sc0 lsb ws3">
              Организатора Акции.<span className="ff3"> </span>
            </div>
            <div className="t m0 x4 h3 y11 ff3 fs0 fc0 sc0 lsb ws3">
              1.3. <span className="_ _1"></span>
              <span className="ff4">
                Название <span className="_ _1"></span>Акции: <span className="_ _0"></span>«ОСАГО с{' '}
                <span className="_ _1"></span>кешбэком <span className="_ _1"></span> <span className="_ _b"></span>10%{' '}
                <span className="_ _1"></span>на <span className="_ _b"></span>услуги связи
                <span className="_ _1"></span>»(выше <span className="_ _1"></span>и <span className="_ _1"></span>далее{' '}
                <span className="_ _1"></span>по <span className="_ _b"></span>тексту{' '}
              </span>
            </div>
            <div className="t m0 x4 h3 y12 ff4 fs0 fc0 sc0 lsb ws0">
              –<span className="ff3 ls3 ws3"> </span>
              <span className="ws3">
                «<span className="_ _3"></span>Акц<span className="_ _1"></span>ия<span className="_ _1"></span>»
                <span className="_ _6"></span>). <span className="ff3"> </span>
              </span>
            </div>
            <div className="t m0 x4 h3 y13 ff3 fs0 fc0 sc0 lsb ws0">
              1.3.
              <span className="ff5 ls4 ws3">
                {' '}
                <span className="ff4 lsb">
                  Территория <span className="_ _c"> </span>проведения <span className="_ _7"> </span>Акции:{' '}
                  <span className="_ _c"> </span>Акция <span className="_ _7"> </span>п<span className="_ _1"></span>
                  роводится <span className="_ _7"> </span>на <span className="_ _c"> </span>территории{' '}
                  <span className="_ _7"> </span>Российской{' '}
                </span>
              </span>
            </div>
            <div className="t m0 x5 h3 y14 ff4 fs0 fc0 sc0 lsb ws3">
              Федерации <span className="ff3"> </span>в регионах присутствия{' '}
              <span className="fc2 ws0">Организатора</span>
              <span className="ff3"> . </span>
            </div>
            <div className="t m0 x4 h3 y15 ff3 fs0 fc0 sc0 lsb ws0">
              1.4.<span className="ff5 ls4 ws3"> </span>
              <span className="ws3">
                {' '}
                <span className="ff4">
                  Все действия, <span className="_ _1"></span>пред<span className="_ _1"></span>усмотренные настоящими{' '}
                  <span className="_ _1"></span>Правилами, должн<span className="_ _1"></span>ы бы
                  <span className="_ _1"></span>ть <span className="_ _1"></span>совершены{' '}
                </span>
              </span>
            </div>
            <div className="t m0 x5 h4 y16 ff4 fs0 fc0 sc0 lsb ws3">
              и <span className="_ _b"></span>фиксир<span className="_ _1"></span>у<span className="_ _3"></span>ю
              <span className="_ _1"></span>тся <span className="_ _b"></span>по <span className="_ _b"></span>
              московскому <span className="_ _b"></span>времени, <span className="_ _b"></span>с{' '}
              <span className="_ _b"></span>00 <span className="_ _b"></span>часов <span className="_ _b"></span>00{' '}
              <span className="_ _b"></span>мин<span className="_ _1"></span>у<span className="_ _6"></span>т{' '}
              <span className="_ _b"></span>00 <span className="_ _b"></span>секунд <span className="_ _b"></span>по{' '}
              <span className="_ _b"></span>23 <span className="_ _b"></span>часа <span className="_ _0"></span>59{' '}
            </div>
            <div className="t m0 x5 h4 y17 ff4 fs0 fc0 sc0 lsb ws3">
              минут <span className="_ _9"> </span>59 <span className="_ _9"> </span>сек<span className="_ _1"></span>у
              <span className="_ _6"></span>нд, <span className="_"> </span>включительно,{' '}
              <span className="_ _9"> </span>соответству<span className="_ _6"></span>ющих<span className="_ _1"></span>{' '}
              <span className="_ _9"> </span>календар<span className="_ _1"></span>ных <span className="_"> </span>с
              <span className="_ _6"></span>у<span className="_ _6"></span>ток, <span className="_"> </span>входящих{' '}
              <span className="_ _9"> </span>в{' '}
            </div>
            <div className="t m0 x5 h4 y18 ff4 fs0 fc0 sc0 lsb ws3">
              установленный <span className="_ _d"> </span>период, <span className="_ _d"> </span>если{' '}
              <span className="_ _d"> </span>отдельно <span className="_ _d"> </span>не <span className="_ _8"> </span>
              огов<span className="_ _6"></span>орено <span className="_ _d"> </span>иное.{' '}
              <span className="_ _d"> </span>Любое <span className="_ _d"> </span>время, <span className="_ _8"> </span>
              у<span className="_ _3"></span>казанное <span className="_ _8"> </span>в{' '}
            </div>
            <div className="t m0 x5 h3 y19 ff4 fs0 fc0 sc0 lsb ws3">
              настоящих Правилах, необходимо рассматривать как московское.<span className="ff3"> </span>
            </div>
            <div className="t m0 x4 h3 y1a ff3 fs0 fc0 sc0 lsb ws0">
              1.5.
              <span className="ff4 ws3">
                Организатор <span className="_ _3"></span>Акции: <span className="_ _6"></span>ООО{' '}
                <span className="_ _3"></span>«Т2 <span className="_ _3"></span>Мобайл<span className="_ _1"></span>»
                <span className="_ _6"></span> <span className="_ _3"></span>(ОГРН <span className="_ _6"></span>
                1137746610088, <span className="_ _3"></span>ИНН <span className="_ _6"></span>7743895280,{' '}
                <span className="_ _3"></span>адрес{' '}
              </span>
            </div>
            <div className="t m0 x5 h3 y1b ff4 fs0 fc0 sc0 lsb ws3">
              места <span className="_ _9"> </span>нахождения <span className="_ _9"> </span>108811,{' '}
              <span className="_ _9"> </span>г. <span className="_ _9"> </span>Москва, <span className="_ _9"> </span>
              поселение <span className="_ _9"> </span>Московский, <span className="_ _9"> </span>Киевское{' '}
              <span className="_ _9"> </span>шоссе <span className="_ _9"> </span>22<span className="ff3 ws0">-</span>
              <span className="ls5">й </span>
            </div>
            <div className="t m0 x5 h3 y1c ff4 fs0 fc0 sc0 lsb ws3">
              километр, домовладение 6, строение 1, этаж 5, комната 33).<span className="ff3"> </span>
            </div>
            <div className="t m0 x4 h3 y1d ff3 fs0 fc0 sc0 lsb ws0">
              1.6.
              <span className="ff4 ws3">
                Партнер <span className="_ _e"> </span>Акции <span className="_ _e"> </span>ООО{' '}
                <span className="_ _f"> </span>«<span className="_ _3"></span>МБ <span className="_ _e"> </span>М
                <span className="_ _1"></span>аркетплейс<span className="_ _1"></span>»
                <span className="ff3 ls6"> </span>(имену<span className="_ _6"></span>емый{' '}
                <span className="_ _e"> </span>также <span className="_ _e"> </span>Партнер
                <span className="ff3 ws0">)</span>
                <span className="ff6">
                  . <span className="_ _e"> </span>
                </span>
                Место{' '}
              </span>
            </div>
            <div className="t m0 x5 h3 y1e ff4 fs0 fc0 sc0 lsb ws3">
              нахождения: <span className="_ _0"></span>
              <span className="ff3 ls7"> </span>117218, <span className="_ _0"></span>г. <span className="_ _0"></span>
              Москва, <span className="_ _2"></span>Нахимовский <span className="_ _b"></span>п
              <span className="_ _1"></span>роспект, <span className="_ _0"></span>д. <span className="_ _0"></span>32,{' '}
              <span className="_ _0"></span>эт. <span className="_ _2"></span>12, <span className="_ _0"></span>пом.{' '}
              <span className="_ _2"></span>1, <span className="_ _b"></span>ком. <span className="_ _2"></span>11
              <span className="ff3 lsd">, </span>
            </div>
            <div className="t m0 x5 h3 y1f ff4 fs0 fc0 sc0 lsb ws3">
              ОГРН: <span className="ff3">5077746994815 </span>
            </div>
            <div className="t m0 x4 h3 y20 ff3 fs0 fc0 sc0 lsb ws3">
              1.7. <span className="_ _3"></span> <span className="_ _3"></span>
              <span className="ff4">
                Участник <span className="_ _3"></span>Акции
                <span className="ff3">
                  {' '}
                  <span className="_ _3"></span>
                  <span className="ff4 ws0">
                    –
                    <span className="ff3 ws3">
                      {' '}
                      <span className="_ _3"></span>
                      <span className="ff4">
                        дееспособное <span className="_ _3"></span>физические <span className="_ _3"></span>лицо,{' '}
                        <span className="_ _6"></span>
                        <span className="ff3">
                          {' '}
                          <span className="_ _3"></span>
                          <span className="ff4">
                            гражданин <span className="_ _3"></span> <span className="_ _3"></span>Рос
                            <span className="_ _6"></span>сийской <span className="_ _3"></span>Федерации,{' '}
                          </span>
                        </span>
                      </span>
                    </span>
                  </span>
                </span>
              </span>
            </div>
            <div className="t m0 x4 h4 y21 ff4 fs0 fc3 sc0 lsb ws3">
              достигший <span className="_ _1"></span>18 <span className="_ _1"></span>лет,{' '}
              <span className="_ _1"></span>заключивший <span className="_ _1"></span>с Организ
              <span className="_ _1"></span>атором Акции <span className="_ _1"></span>договор{' '}
              <span className="_ _1"></span>об <span className="_ _1"></span>оказании <span className="_ _1"></span>
              услуг связи{' '}
            </div>
            <div className="t m0 x4 h3 y22 ff4 fs0 fc3 sc0 lsb ws3">
              и обслужива<span className="_ _6"></span>ющийся на <span className="_ _6"></span>тарифных плана
              <span className="_ _6"></span>х<span className="_ _1"></span>
              <span className="ff3"> </span>
              <span className="ws0">
                л<span className="_ _6"></span>инеек<span className="ff3 lsc ws3">: </span>
                <span className="ws3">
                  Blac<span className="_ _6"></span>k, Premium, <span className="_ _6"></span>Безлимит, Везде{' '}
                  <span className="_ _6"></span>онлайн,{' '}
                </span>
              </span>
            </div>
            <div className="t m0 x4 h4 y23 ff4 fs0 fc3 sc0 lsb ws3">
              Игровой, <span className="_ _8"> </span>Ин<span className="_ _1"></span>тернет{' '}
              <span className="_ _8"> </span>для <span className="_ _9"> </span>у<span className="_ _6"></span>стройств,{' '}
              <span className="_ _8"> </span>Классический, <span className="_ _9"> </span>Компаньон,{' '}
              <span className="_ _8"> </span>Лайт, <span className="_ _8"> </span>Мои <span className="_ _9"> </span>
              близкие, <span className="_ _8"> </span>Мой{' '}
            </div>
            <div className="t m0 x4 h4 y24 ff4 fs0 fc3 sc0 lsb ws3">
              онлайн, <span className="_ _8"> </span>Мой <span className="_ _9"> </span>он<span className="_ _6"></span>
              лайн+, <span className="_ _8"> </span>Мой <span className="_ _8"> </span>разговор,{' '}
              <span className="_ _9"> </span>Оди<span className="_ _6"></span>н <span className="_ _8"> </span>на{' '}
              <span className="_ _8"> </span>всех, <span className="_ _9"> </span>Опять <span className="_ _8"> </span>
              дешевле, <span className="_ _8"> </span>Передача<span className="_ _6"></span>{' '}
              <span className="_ _8"> </span>данных<span className="_ _1"></span>,{' '}
            </div>
            <div className="t m0 x4 h4 y25 ff4 fs0 fc3 sc0 lsb ws3">
              Планшет <span className="_ _d"> </span>онлайн, <span className="_ _d"> </span>Премиум,{' '}
              <span className="_ _2"></span>При<span className="_ _1"></span>вет <span className="_ _d"> </span>на{' '}
              <span className="_ _d"> </span>Родину, <span className="_ _d"> </span>Прозрачные{' '}
              <span className="_ _d"> </span>границ<span className="_ _1"></span>ы, <span className="_ _d"> </span>
              Свобода <span className="_ _d"> </span>общения,{' '}
            </div>
            <div className="t m0 x4 h3 y26 ff4 fs0 fc3 sc0 lsb ws3">
              Социальный, <span className="_ _f"> </span>Супер <span className="_ _f"> </span>онлайн,{' '}
              <span className="_ _f"> </span>Хватит, <span className="_ _f"> </span>Я <span className="_ _f"> </span>
              онлайн<span className="_ _1"></span>
              <span className="ff3">
                , <span className="_ _f"> </span>
              </span>
              <span className="fc0">
                совершивший <span className="_ _f"> </span>последовательность{' '}
              </span>
            </div>
            <div className="t m0 x4 h3 y27 ff4 fs0 fc0 sc0 lsb ws3">
              действий, предусмотренных в статье 4 настоящих<span className="_ _1"></span>
              <span className="ff3"> </span>
              <span className="ws0">Правил</span>
              <span className="ff3"> </span>и принявший у<span className="_ _6"></span>частие в Акции
              <span className="_ _1"></span>
              <span className="ff3">
                . <span className="fc1"> </span>
              </span>
            </div>
            <div className="t m0 x4 h3 y28 ff4 fs0 fc0 sc0 lsb ws3">
              Идентификация <span className="_ _9"> </span>Участников <span className="_ _9"> </span>в{' '}
              <span className="_ _9"> </span>целях <span className="_ _9"> </span>проведения{' '}
              <span className="_ _9"> </span>настоящего <span className="_ _9"> </span>Акции{' '}
              <span className="_"> </span>осу<span className="_ _6"></span>ществляется <span className="_ _9"> </span>
              <span className="ls5 ws2">по</span>
              <span className="ff3"> </span>
            </div>
            <div className="t m0 x4 h3 y29 ff4 fs0 fc0 sc0 lsb ws3">
              абонентском номеру<span className="ff3">, </span>указанному участником<span className="ff3"> </span>в
              Анкете.<span className="ff3"> </span>
            </div>
            <div className="t m0 x4 h3 y2a ff4 fs0 fc1 sc0 lsb ws3">
              Организатор <span className="_ _6"></span>Акции<span className="ff3"> </span>
              <span className="ws0">
                в<span className="_ _3"></span>п<span className="_ _1"></span>раве<span className="_ _6"></span>
                <span className="ff3 ws3">
                  {' '}
                  <span className="_ _3"></span>
                  <span className="ff4">
                    и<span className="_ _1"></span>сключить и<span className="_ _6"></span>з{' '}
                    <span className="_ _6"></span>состава<span className="_ _6"></span> <span className="_ _3"></span>
                    Участни<span className="_ _1"></span>ков лиц
                    <span className="ff3">
                      , <span className="_ _3"></span>
                      <span className="ff4">
                        нарушивших <span className="_ _6"></span>положения<span className="ff3"> </span>
                      </span>
                    </span>
                  </span>
                </span>
              </span>
            </div>
            <div className="t m0 x4 h3 y2b ff4 fs0 fc1 sc0 lsb ws3">
              настоящих <span className="_ _0"></span>Правил.<span className="ff3 ls8"> </span>
              <span className="fc0">
                Присоединяясь <span className="_ _0"></span>к <span className="_ _0"></span>Акции
                <span className="_ _1"></span>, <span className="_ _b"></span>участники<span className="_ _1"></span>
                <span className="ff3 ls0"> </span>
                <span className="ws0">Акции</span>
                <span className="ff3 ls9"> </span>полностью <span className="_ _0"></span>соглашаются{' '}
                <span className="_ _0"></span>с{' '}
              </span>
            </div>
            <div className="t m0 x4 h3 y2c ff4 fs0 fc0 sc0 lsb ws3">
              настоящими Правилами.<span className="ff3 fc1"> </span>
            </div>
            <div className="t m0 x4 h3 y2d ff3 fs0 fc0 sc0 lsb ws0">
              1.8
              <span className="ff4 ws3">
                . ОСАГО –<span className="ff3"> </span>обязательное страхование автогражданской ответственности.
                <span className="ff3"> </span>
              </span>
            </div>
            <div className="t m0 x4 h3 y2e ff3 fs0 fc0 sc0 lsb ws3"> </div>
            <div className="t m0 x4 h3 y2f ff3 fs0 fc0 sc0 lsb ws3"> </div>
            <div className="t m0 x4 h3 y30 ff3 fs0 fc0 sc0 lsa ws3">
              {' '}
              <span className="lsb v1"> </span>
            </div>
            <div className="t m0 x3 h3 y31 ff3 fs0 fc0 sc0 lsb ws3"> </div>
            <div className="t m0 x3 h3 y32 ff3 fs0 fc0 sc0 lsb ws3"> </div>
            <div className="t m0 x3 h3 y33 ff3 fs0 fc0 sc0 lsb ws3"> </div>
            <div className="t m0 x3 h3 y34 ff3 fs0 fc0 sc0 lsb ws3"> </div>
          </div>
          <div className="c x0 y1 w2 h0">
            <div className="t m0 x3 h3 y2 ff4 fs0 fc0 sc0 lsb ws3">
              2. ФОНД АКЦИИ<span className="ff3"> </span>
            </div>
            <div className="t m0 x4 h3 y3 ff3 fs0 fc0 sc0 lsb ws3"> </div>
            <div className="t m0 x4 h3 y4 ff4 fs0 fc0 sc0 lsb ws3">
              2.1. Фонд Акции формируется Организатором Акции.<span className="ff3"> </span>
            </div>
            <div className="t m0 x4 h3 y5 ff3 fs0 fc0 sc0 lsa ws3">
              {' '}
              <span className="lsb v1">
                3. <span className="ff4">СРОКИ ПРОВЕДЕНИЯ АКЦИИ</span>{' '}
              </span>
            </div>
            <div className="t m0 x4 h3 y7 ff4 fs0 fc0 sc0 lsb ws3">
              3.1. Общий срок проведения Акции: с<span className="ff3"> </span>24.12.2024 до 31.12.2025. (включительно).{' '}
              <span className="ff3"> </span>
            </div>
            <div className="t m0 x3 h3 y8 ff3 fs0 fc0 sc0 lsb ws3"> </div>
            <div className="t m0 x3 h3 y9 ff3 fs0 fc0 sc0 lsb ws3"> </div>
            <div className="t m0 x3 h3 ya ff3 fs0 fc0 sc0 lsb ws3"> </div>
            <div className="t m0 x3 h3 yb ff3 fs0 fc0 sc0 lsb ws3">
              4. <span className="ff4">ПОРЯДОК УЧАСТИЯ В АКЦИИ</span>{' '}
            </div>
            <div className="t m0 x4 h3 yc ff3 fs0 fc0 sc0 lsb ws3"> </div>
            <div className="t m0 x4 h4 yd ff4 fs0 fc0 sc0 lsb ws3">
              4.1 <span className="_ _b"></span>Для <span className="_ _0"></span>участия <span className="_ _b"></span>
              в <span className="_ _b"></span>Акц<span className="_ _1"></span>ии <span className="_ _b"></span>в{' '}
              <span className="_ _b"></span>п<span className="_ _1"></span>ериод, <span className="_ _b"></span>
              указанный <span className="_ _b"></span>в <span className="_ _b"></span>п.3.1
              <span className="_ _1"></span>. <span className="_ _b"></span>н<span className="_ _1"></span>астоящих{' '}
              <span className="_ _b"></span>Правил <span className="_ _b"></span>Участник <span className="_ _0"></span>
              акции{' '}
            </div>
            <div className="t m0 x4 h3 ye ff4 fs0 fc0 sc0 lsb ws3">
              должен <span className="_ _3"></span>перейти <span className="_ _3"></span>на{' '}
              <span className="_ _10"></span>ресурс <span className="_ _10"></span>Партнера{' '}
              <span className="_ _3"></span>Акции <span className="_ _3"></span>по <span className="_ _3"></span>ссылке
              <span className="ff3">
                {' '}
                <span className="_ _10"></span>
                <span className="fc2 ws0">
                  osa<span className="_ _1"></span>go.t2.ru
                  <span className="fc0 ws3">
                    {' '}
                    <span className="_ _10"></span>
                    <span className="ff4">
                      и <span className="_ _3"></span>оформить<span className="_ _1"></span>{' '}
                      <span className="_ _10"></span>поли<span className="_ _1"></span>с <span className="_ _10"></span>
                      ОСАГО<span className="ff3">, </span>
                    </span>
                  </span>
                </span>
              </span>
            </div>
            <div className="t m0 x4 h4 yf ff4 fs0 fc0 sc0 lsb ws3">
              уплатить <span className="_ _d"> </span>страховую <span className="_ _d"> </span>премию{' '}
              <span className="_ _d"> </span>и <span className="_ _d"> </span> <span className="_ _8"> </span>указать{' '}
              <span className="_ _d"> </span>в <span className="_ _d"> </span>Анкете <span className="_ _d"> </span>при{' '}
              <span className="_ _d"> </span>оформлении <span className="_ _d"> </span>ОСАГО{' '}
              <span className="_ _8"> </span>абонентский{' '}
            </div>
            <div className="t m0 x4 h3 y10 ff4 fs0 fc0 sc0 ls5 ws2">
              но
              <span className="lsb ws3">
                мер Организатора акции на<span className="ff3"> </span>который бу<span className="_ _6"></span>дет
                начислен кешбэк.<span className="_ _1"></span>
                <span className="ff3"> </span>
              </span>
            </div>
            <div className="t m0 x4 h3 y11 ff4 fs0 fc0 sc0 lsb ws3">
              4.2. <span className="_ _3"></span>Максимальная <span className="_ _3"></span>с
              <span className="_ _1"></span>умма <span className="_ _10"></span>к<span className="_ _1"></span>эшбека{' '}
              <span className="_ _3"></span>на <span className="_ _3"></span>оди<span className="_ _1"></span>н{' '}
              <span className="_ _3"></span>абонентский <span className="_ _6"></span>номер{' '}
              <span className="_ _6"></span>–
              <span className="ff3">
                {' '}
                <span className="_ _3"></span>
                <span className="ff4">
                  1000 <span className="_ _3"></span>р<span className="_ _1"></span>ублей{' '}
                  <span className="_ _10"></span>за <span className="_ _6"></span>весь <span className="_ _3"></span>
                  период{' '}
                </span>
              </span>
            </div>
            <div className="t m0 x4 h3 y12 ff4 fs0 fc0 sc0 lsb ws0">
              Акции<span className="ff3 ws3">. </span>
            </div>
            <div className="t m0 x4 h4 y13 ff4 fs0 fc0 sc0 lsb ws3">
              4.3.Кешбэк <span className="_ _11"> </span>начисляется <span className="_ _f"> </span>тольк
              <span className="_ _1"></span>о <span className="_ _f"> </span>по <span className="_ _11"> </span>полисам{' '}
              <span className="_ _11"> </span>ОСАГО, <span className="_ _11"> </span>которые{' '}
              <span className="_ _f"> </span>п<span className="_ _1"></span>одтверждены <span className="_ _f"> </span>
              как{' '}
            </div>
            <div className="t m0 x4 h3 y14 ff4 fs0 fc0 sc0 lsb ws3">
              действующие со стороны Партнера.<span className="ff3"> </span>
            </div>
            <div className="t m0 x4 h4 y15 ff4 fs0 fc0 sc0 lsb ws3">
              4.4. <span className="_ _d"> </span>Кешбэк<span className="_ _1"></span> <span className="_ _d"> </span>
              начисляется <span className="_ _8"> </span>на <span className="_ _d"> </span>абонентский{' '}
              <span className="_ _8"> </span>номер <span className="_ _d"> </span>Организатора{' '}
              <span className="_ _8"> </span>акции, <span className="_ _d"> </span>который{' '}
              <span className="_ _8"> </span>Участни<span className="_ _6"></span>к{' '}
            </div>
            <div className="t m0 x4 h4 y16 ff4 fs0 fc0 sc0 lsb ws3">
              Акции <span className="_ _0"></span>указал <span className="_ _b"></span> <span className="_ _2"></span>{' '}
              <span className="_ _b"></span>п<span className="_ _1"></span>ри <span className="_ _0"></span>оформлении{' '}
              <span className="_ _0"></span>полиса <span className="_ _0"></span>ОСАГО. <span className="_ _0"></span>
              Если <span className="_ _0"></span> <span className="_ _0"></span>Участник<span className="_ _1"></span>{' '}
              <span className="_ _b"></span>Акции<span className="_ _1"></span> <span className="_ _b"></span>{' '}
              <span className="_ _2"></span>у<span className="_ _6"></span>казал <span className="_ _0"></span>в{' '}
              <span className="_ _0"></span>анкете{' '}
            </div>
            <div className="t m0 x4 h3 y17 ff4 fs0 fc0 sc0 lsb ws3">
              абонентский номер другого оператора связи , кешбэк не начисляется.<span className="ff3"> </span>
            </div>
            <div className="t m0 x4 h3 y18 ff3 fs0 fc0 sc0 lsb ws3">
              4.5. <span className="_ _3"></span>
              <span className="ff4">
                Кешбэк <span className="_ _6"></span>в <span className="_ _3"></span>размере{' '}
                <span className="_ _3"></span>10% <span className="_ _6"></span>от <span className="_ _3"></span>с
                <span className="_ _1"></span>ум<span className="_ _6"></span>мы <span className="_ _3"></span>страховой
                <span className="_ _1"></span> <span className="_ _3"></span>премии у<span className="_ _3"></span>
                плаченной<span className="_ _1"></span>
                <span className="ff3">
                  {' '}
                  <span className="_ _3"></span>
                  <span className="ff4">
                    Участник<span className="_ _1"></span>ом <span className="_ _3"></span>Акции
                    <span className="ff3">
                      {' '}
                      <span className="_ _6"></span>
                      <span className="ff4">при </span>
                    </span>
                  </span>
                </span>
              </span>
            </div>
            <div className="t m0 x4 h3 y19 ff4 fs0 fc0 sc0 lsb ws3">
              оформлении <span className="_ _12"> </span>полиса<span className="ff3 lse"> </span>ОСАГО{' '}
              <span className="_ _12"> </span>зачисляется<span className="ff3 lsf"> </span>на{' '}
              <span className="_ _12"> </span>лицевой <span className="_ _12"> </span>счет{' '}
              <span className="_ _12"> </span>абонентского <span className="_ _12"> </span>номера{' '}
            </div>
            <div className="t m0 x4 h4 y1a ff4 fs0 fc0 sc0 lsb ws3">
              Организатора <span className="_ _9"> </span>Акции, <span className="_ _9"> </span>указанный{' '}
              <span className="_ _9"> </span>Участником <span className="_ _9"> </span>Акции{' '}
              <span className="_ _9"> </span>п<span className="_ _1"></span>ри <span className="_ _9"> </span>оформлении{' '}
              <span className="_ _9"> </span>Анкеты. <span className="_ _9"> </span>Кешбэк{' '}
            </div>
            <div className="t m0 x4 h3 y1b ff4 fs0 fc0 sc0 lsb ws3">
              может <span className="_ _9"> </span>быть <span className="_ _8"> </span>и<span className="_ _1"></span>
              спользован<span className="ff3 ls10"> </span>
              <span className="ls11 ws4">только</span>
              <span className="ff3 ls2"> </span>на <span className="_ _9"> </span>оплату <span className="_ _9"> </span>
              услу<span className="_ _3"></span>г <span className="_ _9"> </span>с<span className="_ _1"></span>вязи{' '}
              <span className="_ _9"> </span>Организатора <span className="_ _8"> </span>Акц
              <span className="_ _1"></span>ии <span className="_ _9"> </span>в <span className="_ _9"> </span>тече
              <span className="_ _6"></span>ние{' '}
            </div>
            <div className="t m0 x4 h3 y1c ff4 fs0 fc0 sc0 lsb ws3">
              ______ после начисления.<span className="ff3"> </span>Денежные эквиваленты кешбэка не выдаются и не
              начисляются.<span className="ff3"> </span>
            </div>
            <div className="t m0 x4 h3 y1d ff3 fs0 fc0 sc0 lsb ws0">
              4.
              <span className="ff4 ws3">
                6. <span className="_ _8"> </span>Начисление<span className="ff3 ls12"> </span>кешбэка{' '}
                <span className="_ _8"> </span>производи<span className="_ _1"></span>тся{' '}
                <span className="_ _8"> </span>на <span className="_ _8"> </span>следующий{' '}
                <span className="_ _8"> </span>месяц <span className="_ _8"> </span>за <span className="_ _9"> </span>ме
                <span className="_ _6"></span>сяцем <span className="_ _8"> </span>оформления{' '}
                <span className="_ _9"> </span>
                <span className="ls17">и </span>
              </span>
            </div>
            <div className="t m0 x4 h3 y1e ff4 fs0 fc0 sc0 lsb ws3">
              оплаты полиса ОСАГО.<span className="ff3"> </span>
            </div>
            <div className="t m0 x4 h3 y1f ff3 fs0 fc0 sc0 lsa ws3">
              {' '}
              <span className="lsb v1"> </span>
            </div>
            <div className="t m0 x3 h3 y21 ff3 fs0 fc0 sc0 lsb ws3"> </div>
            <div className="t m0 x3 h3 y22 ff3 fs0 fc0 sc0 lsb ws3">
              5. <span className="ff4">ПЕРЕДАЧА ДАННЫХ ДЛЯ НАЧИСЛЕНИЯ КЕШБЭКА</span>{' '}
            </div>
            <div className="t m0 x3 h3 y23 ff3 fs0 fc0 sc0 lsb ws3"> </div>
            <div className="t m0 x4 h3 y24 ff3 fs0 fc0 sc0 lsb ws0">
              5.1
              <span className="ff5 ws3">
                {' '}
                <span className="_ _6"></span>
                <span className="ff4">
                  Участник <span className="_ _d"> </span>Акции <span className="_ _d"> </span>пор
                  <span className="_ _1"></span>у<span className="_ _3"></span>ч<span className="_ _1"></span>ает{' '}
                  <span className="_ _d"> </span>Партнер<span className="_ _1"></span>у <span className="_ _d"> </span>
                  Акции <span className="_ _d"> </span>передавать<span className="ff3 ls13"> </span>Организатору{' '}
                  <span className="_ _d"> </span>Акции<span className="_ _1"></span> <span className="_ _d"> </span>в{' '}
                  <span className="_ _d"> </span>целях{' '}
                </span>
              </span>
            </div>
            <div className="t m0 x5 h3 y25 ff4 fs0 fc0 sc0 lsb ws3">
              начисления кешбэка следующую информацию:<span className="ff3"> </span>
            </div>
            <div className="t m0 x5 h3 y26 ff3 fs0 fc0 sc0 lsb ws3">
              - <span className="_ _1"></span>
              <span className="ff4 ws0">
                абонентски<span className="_ _1"></span>й
              </span>
              <span className="ls14"> </span>
              <span className="ff4">
                номер <span className="_ _1"></span>Организ<span className="_ _1"></span>атора{' '}
                <span className="_ _1"></span>Акции,<span className="_ _1"></span>
              </span>
              <span className="ls15"> </span>
              <span className="ff4">
                выделенный <span className="_ _1"></span>участнику <span className="_ _1"></span>Акц
                <span className="_ _1"></span>ии <span className="_ _1"></span>и <span className="_ _0"></span>указанный
              </span>{' '}
            </div>
            <div className="t m0 x5 h3 y27 ff4 fs0 fc0 sc0 ls5 ws2">
              им
              <span className="ff3 lsb ws3">
                {' '}
                <span className="ff4">при заполнении Анкеты,</span>{' '}
              </span>
            </div>
            <div className="t m0 x5 h3 y28 ff3 fs0 fc0 sc0 lsb ws3">
              - <span className="_"> </span>
              <span className="ff4">
                размер <span className="_ _9"> </span>страховой <span className="_"> </span>премии{' '}
                <span className="_"> </span>и <span className="_"> </span>иные <span className="_"> </span>данные,{' '}
                <span className="_ _9"> </span>необх<span className="_ _1"></span>одимые <span className="_ _9"> </span>
                Организатору <span className="_"> </span>акции <span className="_"> </span>для{' '}
              </span>
            </div>
            <div className="t m0 x5 h3 y29 ff4 fs0 fc0 sc0 lsb ws3">
              расчета суммы кешбэка, подлежащего начислению в рамках Акции<span className="ff3"> </span>
            </div>
            <div className="t m0 x3 h3 y2a ff3 fs0 fc0 sc0 lsb ws3"> </div>
            <div className="t m0 x3 h3 y2b ff3 fs0 fc0 sc0 lsb ws3"> </div>
            <div className="t m0 x3 h3 y2c ff3 fs0 fc0 sc0 lsb ws0">
              6
              <span className="ff4 ws3">
                . ПЕРСОНАЛЬНЫЕ ДАННЫЕ<span className="ff3">. </span>
              </span>
            </div>
            <div className="t m0 x3 h3 y2d ff3 fs0 fc0 sc0 lsb ws3"> </div>
            <div className="t m0 x4 h3 y2e ff3 fs0 fc0 sc0 lsb ws0">
              6.1
              <span className="ff4 ws3">
                . <span className="_ _10"></span>Прини<span className="_ _1"></span>мая <span className="_ _10"></span>
                участие <span className="_ _10"></span>в<span className="_ _1"></span> <span className="_ _10"></span>
                Акци<span className="_ _1"></span>и, <span className="_ _10"></span>Участник{' '}
                <span className="_ _3"></span>подтверждает <span className="_ _10"></span>свое{' '}
                <span className="_ _10"></span>сог<span className="_ _1"></span>ласие <span className="_ _3"></span>
                Организ<span className="_ _1"></span>атору
                <span className="ff3">
                  {' '}
                  <span className="_ _10"></span>
                  <span className="ff4">
                    Акции <span className="ff3"> </span>
                  </span>
                </span>
              </span>
            </div>
            <div className="t m0 x4 h4 y2f ff4 fs0 fc0 sc0 lsb ws3">
              на <span className="_ _d"> </span>предоставленных <span className="_ _d"> </span>
              <span className="ls5">
                и <span className="_ _d"> </span>
              </span>
              обработку<span className="_ _3"></span> <span className="_ _d"> </span>п<span className="_ _1"></span>
              ерсональных <span className="_ _d"> </span>данных, <span className="_ _d"> </span>включая{' '}
              <span className="_ _d"> </span>сбор, <span className="_ _d"> </span>систематизацию,{' '}
            </div>
            <div className="t m0 x4 h3 y30 ff4 fs0 fc0 sc0 lsb ws3">
              накопление, <span className="_ _9"> </span>х<span className="_ _1"></span>ранение,{' '}
              <span className="_"> </span>у<span className="_ _3"></span>то<span className="_ _1"></span>чнение{' '}
              <span className="_"> </span>(обновление, <span className="_ _9"> </span>изменение),{' '}
              <span className="_"> </span>извлечение, <span className="_ _9"> </span>использование,
              <span className="_ _b"></span>
              <span className="ff3"> </span>
            </div>
            <div className="t m0 x4 h4 y35 ff4 fs0 fc0 sc0 lsb ws3">
              обезличивание, <span className="_ _8"> </span>блокирование, <span className="_ _8"> </span>уда
              <span className="_ _6"></span>ление, <span className="_ _9"> </span>у<span className="_ _3"></span>
              ничтожени<span className="_ _1"></span>е <span className="_ _8"> </span>для{' '}
              <span className="_ _8"> </span>целей <span className="_ _8"> </span>прове<span className="_ _6"></span>
              дения <span className="_ _8"> </span>настоящей{' '}
            </div>
            <div className="t m0 x4 h4 y31 ff4 fs0 fc0 sc0 lsb ws3">
              Акции <span className="_ _a"> </span>н<span className="_ _1"></span>а <span className="_ _a"> </span>весь{' '}
              <span className="_ _a"> </span>срок <span className="_ _4"> </span>ее <span className="_ _4"> </span>
              проведения <span className="_ _4"> </span>и <span className="_ _a"> </span>в{' '}
              <span className="_ _4"> </span>теч<span className="_ _1"></span>ение <span className="_ _a"> </span>3{' '}
              <span className="_ _4"> </span>(трех) <span className="_ _a"> </span>лет <span className="_ _4"> </span>
              после <span className="_ _a"> </span>ее<span className="_ _1"></span> <span className="_ _a"> </span>
              окончания, <span className="_ _4"> </span>в{' '}
            </div>
            <div className="t m0 x4 h3 y32 ff4 fs0 fc0 sc0 lsb ws3">
              соответствии <span className="_ _1"></span>с <span className="_ _b"></span>положениями,{' '}
              <span className="_ _b"></span>предусмотренными <span className="_ _1"></span>Федеральным{' '}
              <span className="_ _1"></span>законом <span className="_ _b"></span>РФ <span className="_ _b"></span>№{' '}
              <span className="_ _1"></span>152<span className="_ _b"></span>
              <span className="ff3 ws0">-</span>ФЗ <span className="_ _1"></span>от{' '}
            </div>
            <div className="t m0 x4 h3 y33 ff4 fs0 fc0 sc0 lsb ws3">
              27 <span className="_ _0"></span>июля <span className="_ _2"></span>2006 <span className="_ _0"></span>г.{' '}
              <span className="_ _2"></span>«<span className="_ _6"></span>О <span className="_ _0"></span>персональных
              <span className="_ _1"></span> <span className="_ _0"></span>данных» <span className="_ _b"></span>(далее{' '}
              <span className="_ _2"></span>–<span className="ff3 ls16"> </span>«Закон»). <span className="_ _0"></span>
              Указанное <span className="_ _2"></span>согласие <span className="_ _0"></span>может{' '}
            </div>
          </div>
          <div className="c x0 y1 w2 h0">
            <div className="t m0 x4 h3 y2 ff4 fs0 fc0 sc0 lsb ws3">
              быть <span className="_ _d"> </span>отозвано<span className="ff3 ls18"> </span>Участником{' '}
              <span className="_ _2"></span>в <span className="_ _d"> </span>любое <span className="_ _d"> </span>вре
              <span className="_ _6"></span>мя <span className="_ _d"> </span>путем <span className="_ _d"> </span>у
              <span className="_ _3"></span>в<span className="_ _1"></span>едомления, <span className="_ _d"> </span>
              напра<span className="_ _6"></span>вленного <span className="_ _d"> </span>по{' '}
              <span className="_ _2"></span>адресу{' '}
            </div>
            <div className="t m0 x4 h3 y3 ff4 fs0 fc0 sc0 lsb ws3">
              места нахождения Организатора Акции.<span className="ff3"> </span>
            </div>
            <div className="t m0 x4 h3 y4 ff3 fs0 fc0 sc0 lsb ws0">
              6.2
              <span className="ff4 ws3">
                . <span className="_ _c"> </span>Факт <span className="_ _c"> </span>участия{' '}
                <span className="_ _c"> </span>в <span className="_ _c"> </span>Акции <span className="_ _c"> </span>
                является <span className="_ _c"> </span>свободным, <span className="_ _c"> </span>конкретным,{' '}
                <span className="_ _c"> </span>информированным <span className="_ _c"> </span>и{' '}
              </span>
            </div>
            <div className="t m0 x4 h3 y5 ff4 fs0 fc0 sc0 lsb ws3">
              сознательным <span className="_ _c"> </span>выражением <span className="_ _13"> </span>согласия{' '}
              <span className="_ _c"> </span>Участник<span className="_ _1"></span>а <span className="_ _7"> </span>н
              <span className="_ _1"></span>а <span className="_ _c"> </span>обработк<span className="_ _1"></span>у
              <span className="_ _3"></span> <span className="_ _13"> </span>Организ<span className="_ _1"></span>атором
              <span className="_ _1"></span>
              <span className="ff3 ls19"> </span>
              <span className="ws0">Акции</span>
              <span className="ff3"> </span>
            </div>
            <div className="t m0 x4 h4 y6 ff4 fs0 fc0 sc0 lsb ws3">
              персональных <span className="_ _b"></span>данных <span className="_ _b"></span>Участник
              <span className="_ _1"></span>а <span className="_ _b"></span>любыми <span className="_ _b"></span>
              способами, <span className="_ _b"></span>необходимыми <span className="_ _b"></span>в{' '}
              <span className="_ _0"></span>целях <span className="_ _b"></span>проведения{' '}
            </div>
            <div className="t m0 x4 h4 y7 ff4 fs0 fc0 sc0 lsb ws3">
              Акции, <span className="_ _5"> </span>и <span className="_ _5"> </span>в <span className="_ _5"> </span>
              порядке, <span className="_ _5"> </span>преду<span className="_ _6"></span>смотренном{' '}
              <span className="_ _5"> </span>настоящими <span className="_ _5"> </span>Правилами.{' '}
              <span className="_ _5"> </span>Под <span className="_ _5"> </span>персональными{' '}
            </div>
            <div className="t m0 x4 h4 y8 ff4 fs0 fc0 sc0 lsb ws3">
              данными в ц<span className="_ _1"></span>елях настоящих<span className="_ _1"></span> Правил п
              <span className="_ _1"></span>онимается любая информация, <span className="_ _1"></span>относящаяся к
              прямо{' '}
            </div>
            <div className="t m0 x4 h4 y9 ff4 fs0 fc0 sc0 lsb ws3">
              или <span className="_ _14"> </span>косвенно <span className="_ _14"> </span>определенному,{' '}
              <span className="_ _14"> </span>или <span className="_ _14"> </span>опреде<span className="_ _6"></span>
              ляемом<span className="_ _1"></span>у <span className="_ _14"> </span>физическому{' '}
              <span className="_ _14"> </span>лицу <span className="_ _14"> </span>(су<span className="_ _6"></span>
              бъекту{' '}
            </div>
            <div className="t m0 x4 h3 ya ff4 fs0 fc0 sc0 lsb ws3">
              персональных данных). <span className="ff3"> </span>
            </div>
            <div className="t m0 x3 h3 yb ff3 fs0 fc0 sc0 lsb ws0">
              6.3
              <span className="ff4 ws3">
                . <span className="_ _10"></span>Участники<span className="_ _1"></span> <span className="_ _10"></span>
                понимают <span className="_ _10"></span>и <span className="_ _3"></span>соглашаются{' '}
                <span className="_ _10"></span>с <span className="_ _10"></span>тем<span className="_ _1"></span>,{' '}
                <span className="_ _10"></span>что <span className="_ _3"></span>персональные{' '}
                <span className="_ _3"></span>данные, <span className="_ _3"></span>указанные{' '}
              </span>
            </div>
            <div className="t m0 x4 h4 yc ff4 fs0 fc0 sc0 lsb ws3">
              в <span className="_ _5"> </span>6.2. <span className="_ _5"> </span>Правил{' '}
              <span className="_ _7"> </span>и <span className="_ _5"> </span>предоставленные{' '}
              <span className="_ _5"> </span>ими <span className="_ _5"> </span>для <span className="_ _7"> </span>
              участия <span className="_ _5"> </span>в <span className="_ _5"> </span>Акции,{' '}
              <span className="_ _7"> </span>бу<span className="_ _6"></span>дут <span className="_ _5"> </span>
              обрабатываться{' '}
            </div>
            <div className="t m0 x4 h3 yd ff4 fs0 fc0 sc0 lsb ws3">
              Организатором <span className="_ _3"></span>Акции <span className="_ _3"></span>{' '}
              <span className="_ _3"></span>
              <span className="ff3">
                {' '}
                <span className="_ _6"></span>
                <span className="ff4">
                  всеми <span className="_ _10"></span>необходи<span className="_ _1"></span>мыми{' '}
                  <span className="_ _10"></span>с<span className="_ _1"></span>пособами <span className="_ _10"></span>
                  в <span className="_ _3"></span>ц<span className="_ _1"></span>елях <span className="_ _3"></span>
                  проведения <span className="_ _3"></span>Акц<span className="_ _1"></span>ии,{' '}
                  <span className="_ _10"></span>и<span className="_ _1"></span> <span className="_ _3"></span>дают{' '}
                </span>
              </span>
            </div>
            <div className="t m0 x4 h3 ye ff4 fs0 fc0 sc0 lsb ws3">
              согласие на такую обработку при принятии настоящих Правил. <span className="ff3"> </span>
            </div>
            <div className="t m0 x3 h3 yf ff3 fs0 fc0 sc0 lsb ws0">
              6.4
              <span className="ff4 ws3">
                . <span className="_ _1"></span>Организ<span className="_ _1"></span>атор <span className="_ _1"></span>
                Акции <span className="_ _b"></span>обязуется <span className="_ _1"></span>соблюдать{' '}
                <span className="_ _b"></span>след<span className="_ _1"></span>у<span className="_ _3"></span>ющи
                <span className="_ _1"></span>е <span className="_ _1"></span>правила <span className="_ _b"></span>и{' '}
                <span className="_ _b"></span>предоставляют{' '}
              </span>
            </div>
            <div className="t m0 x4 h3 y10 ff4 fs0 fc0 sc0 lsb ws3">
              Участнику следу<span className="_ _6"></span>ющие гарантии в отн<span className="_ _1"></span>ошении{' '}
              <span className="_ _6"></span>обработки персональн<span className="_ _1"></span>ых данных:{' '}
              <span className="ff3"> </span>
            </div>
            <div className="t m0 x3 h3 y11 ff3 fs0 fc0 sc0 lsb ws0">
              6.4
              <span className="ff4 ws3">
                .1. <span className="_ _3"></span>обеспечить <span className="_ _3"></span>обработк
                <span className="_ _1"></span>у <span className="_ _3"></span>персональных{' '}
                <span className="_ _3"></span>данных<span className="_ _1"></span> <span className="_ _3"></span>с{' '}
                <span className="_ _3"></span>соблюдени<span className="_ _1"></span>ем <span className="_ _3"></span>
                всех <span className="_ _3"></span>п<span className="_ _1"></span>рименимых{' '}
              </span>
            </div>
            <div className="t m0 x4 h4 y12 ff4 fs0 fc0 sc0 lsb ws3">
              требований <span className="_ _a"> </span>законодательства <span className="_ _a"> </span>Российской{' '}
              <span className="_ _a"> </span>Федера<span className="_ _6"></span>ции <span className="_ _a"> </span>в{' '}
              <span className="_ _a"> </span>области <span className="_ _a"> </span>защиты{' '}
              <span className="_ _a"> </span>персональных{' '}
            </div>
            <div className="t m0 x4 h4 y13 ff4 fs0 fc0 sc0 lsb ws3">
              данных, <span className="_ _a"> </span>в <span className="_ _4"> </span>том{' '}
              <span className="_ _a"> </span>числе <span className="_ _a"> </span>с <span className="_ _a"> </span>
              соблюдени<span className="_ _1"></span>ем <span className="_ _a"> </span>принципов,{' '}
              <span className="_ _a"> </span>требований, <span className="_ _4"> </span>обязательств{' '}
              <span className="_ _a"> </span>оператора{' '}
            </div>
            <div className="t m0 x4 h3 y14 ff4 fs0 fc0 sc0 ls5 ws2">
              пе
              <span className="lsb ws3">
                рсональных данных, у<span className="_ _3"></span>стан<span className="_ _1"></span>овленных Законом;
                <span className="ff3"> </span>
              </span>
            </div>
            <div className="t m0 x3 h3 y15 ff3 fs0 fc0 sc0 lsb ws3">
              {' '}
              6.4
              <span className="ff4">
                .2. <span className="_ _d"> </span>обрабатывать <span className="_ _d"> </span>персональные{' '}
                <span className="_ _d"> </span>данные <span className="_ _d"> </span>то<span className="_ _6"></span>
                лько <span className="_ _d"> </span>в <span className="_ _d"> </span>объеме{' '}
                <span className="_ _d"> </span>и <span className="_ _d"> </span>в <span className="_ _2"></span>целях
                <span className="_ _1"></span> <span className="_ _d"> </span>проведения{' '}
              </span>
            </div>
            <div className="t m0 x4 h4 y16 ff4 fs0 fc0 sc0 lsb ws3">
              Акции, <span className="_ _1"></span>а <span className="_ _b"></span>также <span className="_ _1"></span>в{' '}
              <span className="_ _b"></span>рекла<span className="_ _1"></span>мных <span className="_ _b"></span>целях.{' '}
              <span className="_ _1"></span>Использование <span className="_ _b"></span>и <span className="_ _1"></span>
              и<span className="_ _1"></span>ные <span className="_ _1"></span>виды <span className="_ _b"></span>
              обработки <span className="_ _b"></span>персональных{' '}
            </div>
            <div className="t m0 x4 h3 y17 ff4 fs0 fc0 sc0 lsb ws3">
              данных в целях информирования су<span className="_ _3"></span>бъ<span className="_ _1"></span>ектов
              персональных <span className="_ _1"></span>данных о каких<span className="ff3 ws0">-</span>либо продуктах{' '}
            </div>
            <div className="t m0 x4 h4 y18 ff4 fs0 fc0 sc0 lsb ws3">
              и <span className="_ _4"> </span>ус<span className="_ _6"></span>л<span className="_ _1"></span>у
              <span className="_ _6"></span>гах, <span className="_ _a"> </span>а <span className="_ _4"> </span>также{' '}
              <span className="_ _a"> </span>в <span className="_ _4"> </span>любых <span className="_ _a"> </span>иных{' '}
              <span className="_ _a"> </span>ц<span className="_ _1"></span>елях <span className="_ _a"> </span>
              допускается <span className="_ _a"> </span>только <span className="_ _a"> </span>в{' '}
              <span className="_ _4"> </span>объеме <span className="_ _a"> </span>и <span className="_ _4"> </span>в{' '}
              <span className="_ _a"> </span>случаях,{' '}
            </div>
            <div className="t m0 x4 h3 y19 ff4 fs0 fc0 sc0 lsb ws3">
              предусмотренных Законом; <span className="ff3"> </span>
            </div>
            <div className="t m0 x3 h3 y1a ff3 fs0 fc0 sc0 lsb ws0">
              6.5
              <span className="ff4 ws3">
                .3. <span className="_ _8"> </span>в<span className="ff3 ls2"> </span>сл<span className="_ _1"></span>
                учае <span className="_ _8"> </span>если <span className="_ _9"> </span>Организатор{' '}
                <span className="_ _9"> </span>Акции <span className="_ _9"> </span>в <span className="_ _8"> </span>
                целях <span className="_ _9"> </span>исполнения <span className="_ _8"> </span>своих{' '}
                <span className="_ _9"> </span>обязательств{' '}
              </span>
            </div>
            <div className="t m0 x4 h4 y1b ff4 fs0 fc0 sc0 lsb ws3">
              перед <span className="_ _d"> </span>Участниками <span className="_ _d"> </span>Акции{' '}
              <span className="_ _d"> </span>должн<span className="_ _1"></span>ы <span className="_ _d"> </span>
              передать <span className="_ _d"> </span>или <span className="_ _8"> </span>иным{' '}
              <span className="_ _d"> </span>образом <span className="_ _d"> </span>раскрыть{' '}
              <span className="_ _d"> </span>персональные{' '}
            </div>
            <div className="t m0 x4 h4 y1c ff4 fs0 fc0 sc0 lsb ws3">
              данные <span className="_ _12"> </span>Участников <span className="_ _15"> </span>Акции{' '}
              <span className="_ _12"> </span>третьим <span className="_ _15"> </span>лицам,{' '}
              <span className="_ _15"> </span>ос<span className="_ _1"></span>уществлять{' '}
              <span className="_ _15"> </span>указанные <span className="_ _12"> </span>действия{' '}
              <span className="_ _15"> </span>с{' '}
            </div>
            <div className="t m0 x4 h3 y1d ff4 fs0 fc0 sc0 lsb ws3">
              соблюдением требований Закона; <span className="ff3"> </span>
            </div>
            <div className="t m0 x3 h3 y1e ff3 fs0 fc0 sc0 lsb ws0">
              6.5
              <span className="ff4 ws3">
                .4. <span className="_ _16"> </span>нести <span className="_ _16"> </span>ответственность{' '}
                <span className="_ _16"> </span>за <span className="_ _17"> </span>охрану{' '}
                <span className="_ _16"> </span>и <span className="_ _16"> </span>обеспечение{' '}
                <span className="_ _16"> </span>безопасности <span className="_ _16"> </span>и{' '}
              </span>
            </div>
            <div className="t m0 x4 h4 y1f ff4 fs0 fc0 sc0 lsb ws3">
              конфиденциальности <span className="_ _c"> </span>персональных <span className="_ _13"> </span>данных{' '}
              <span className="_ _18"> </span>Участников <span className="_ _13"> </span>Акции{' '}
              <span className="_ _c"> </span>при <span className="_ _13"> </span>их <span className="_ _13"> </span>
              обработке <span className="_ _c"> </span>в{' '}
            </div>
            <div className="t m0 x4 h3 y20 ff4 fs0 fc0 sc0 lsb ws3">
              соответствии с требованиями законодательства РФ.<span className="ff3"> </span>
            </div>
            <div className="t m0 x3 h3 y21 ff3 fs0 fc0 sc0 lsb ws3">
              {' '}
              6.5
              <span className="ff4">
                .5. <span className="_ _9"> </span>Обеспеч<span className="_ _6"></span>ивать{' '}
                <span className="_ _9"> </span>конфиденциальность <span className="_ _9"> </span>персональных{' '}
                <span className="_ _8"> </span>данных, <span className="_ _9"> </span>в <span className="_ _9"> </span>
                том <span className="_ _8"> </span>числе <span className="_ _9"> </span>не{' '}
              </span>
            </div>
            <div className="t m0 x4 h4 y22 ff4 fs0 fc0 sc0 lsb ws3">
              раскрывать <span className="_ _10"></span>третьим <span className="_ _3"></span>лицам{' '}
              <span className="_ _10"></span>и <span className="_ _3"></span>не <span className="_ _10"></span>
              распространять <span className="_ _10"></span>персональные <span className="_ _3"></span>данные{' '}
              <span className="_ _10"></span>без <span className="_ _10"></span>согласия <span className="_ _3"></span>
              субъекта{' '}
            </div>
            <div className="t m0 x4 h3 y23 ff4 fs0 fc0 sc0 lsb ws3">
              персональных данных, если иное не предусм<span className="_ _6"></span>отрено федеральным законом;
              <span className="_ _1"></span>
              <span className="ff3"> </span>
            </div>
            <div className="t m0 x3 h3 y24 ff3 fs0 fc0 sc0 lsb ws0">
              6.5
              <span className="ff4 ws3">
                .6. Обеспечивать безопасность персональных данных при их обраб<span className="_ _6"></span>отке;{' '}
                <span className="ff3"> </span>
              </span>
            </div>
            <div className="t m0 x3 h3 y25 ff3 fs0 fc0 sc0 lsb ws0">
              6.5
              <span className="ff4 ws3">
                .7. <span className="_ _a"> </span>При <span className="_ _a"> </span>обработке{' '}
                <span className="_ _a"> </span>персональных <span className="_ _a"> </span>данных{' '}
                <span className="_ _a"> </span>принимать <span className="_ _a"> </span>необ
                <span className="_ _6"></span>ходимые <span className="_ _a"> </span>правовые,{' '}
              </span>
            </div>
            <div className="t m0 x4 h4 y26 ff4 fs0 fc0 sc0 lsb ws3">
              организационные <span className="_ _18"> </span>и <span className="_ _18"> </span>технические{' '}
              <span className="_ _18"> </span>меры <span className="_ _18"> </span>или <span className="_ _19"> </span>
              обеспечивать <span className="_ _19"> </span>их <span className="_ _18"> </span>при
              <span className="_ _1"></span>нятие <span className="_ _18"> </span>для <span className="_ _18"> </span>
              защиты{' '}
            </div>
            <div className="t m0 x4 h4 y27 ff4 fs0 fc0 sc0 lsb ws3">
              персональных <span className="_ _8"> </span>данных <span className="_ _d"> </span>от{' '}
              <span className="_ _8"> </span>неправомерного <span className="_ _d"> </span>и
              <span className="_ _1"></span>ли <span className="_ _8"> </span>слу<span className="_ _3"></span>чайн
              <span className="_ _1"></span>ого <span className="_ _8"> </span>досту<span className="_ _3"></span>п
              <span className="_ _1"></span>а <span className="_ _8"> </span>к <span className="_ _8"> </span>ним,{' '}
              <span className="_ _d"> </span>уничтожения,{' '}
            </div>
            <div className="t m0 x4 h3 y28 ff4 fs0 fc0 sc0 lsb ws3">
              изменения, блокирования, <span className="_ _1"></span>копирования, предоставления
              <span className="ff3 ls1a"> </span>п<span className="_ _1"></span>ерсональных данных,{' '}
              <span className="_ _1"></span>а также от{' '}
            </div>
            <div className="t m0 x4 h4 y29 ff4 fs0 fc0 sc0 lsb ws3">
              иных <span className="_ _6"></span>неправомерных <span className="_ _6"></span>действий{' '}
              <span className="_ _6"></span>в <span className="_ _6"></span>отношении <span className="_ _3"></span>
              персональных данных. <span className="_ _3"></span>При этом <span className="_ _3"></span>требования к
              <span className="_ _6"></span>{' '}
            </div>
            <div className="t m0 x4 h4 y2a ff4 fs0 fc0 sc0 lsb ws3">
              защите <span className="_ _5"> </span>обрабатывае<span className="_ _6"></span>мых{' '}
              <span className="_ _5"> </span>персональных <span className="_ _5"> </span>данных,{' '}
              <span className="_ _5"> </span>в <span className="_ _4"> </span>том <span className="_ _5"> </span>числе{' '}
              <span className="_ _5"> </span>необходимые <span className="_ _4"> </span>правовые,{' '}
            </div>
            <div className="t m0 x4 h4 y2b ff4 fs0 fc0 sc0 lsb ws3">
              организационные и техничес<span className="_ _6"></span>кие меры <span className="_ _6"></span>по защите{' '}
              <span className="_ _6"></span>персональных данных от неправоме<span className="_ _6"></span>рного{' '}
            </div>
            <div className="t m0 x4 h4 y2c ff4 fs0 fc0 sc0 lsb ws3">
              или <span className="_"> </span>сл<span className="_ _1"></span>у<span className="_ _3"></span>ч
              <span className="_ _1"></span>айного <span className="_"> </span>дост<span className="_ _1"></span>упа{' '}
              <span className="_"> </span>к <span className="_"> </span>ним, <span className="_ _a"> </span>у
              <span className="_ _3"></span>ничтожени<span className="_ _1"></span>я, <span className="_"> </span>
              изменения, <span className="_"> </span>блокирования, <span className="_"> </span>к
              <span className="_ _1"></span>опирования,<span className="_ _6"></span>{' '}
            </div>
            <div className="t m0 x4 h3 y2d ff4 fs0 fc0 sc0 lsb ws0">
              предоставления<span className="ff3 ls1b ws3"> </span>
              <span className="ws3">
                и <span className="_ _a"> </span>иных <span className="_ _a"> </span>неправомерных{' '}
                <span className="_ _a"> </span>действий <span className="_ _a"> </span>в <span className="_ _a"> </span>
                отношении <span className="_ _a"> </span>пе<span className="_ _6"></span>рсональных{' '}
                <span className="_ _a"> </span>данных{' '}
              </span>
            </div>
            <div className="t m0 x4 h4 y2e ff4 fs0 fc0 sc0 lsb ws3">
              определяются <span className="_ _13"> </span>Организатором <span className="_ _18"> </span>Акции{' '}
              <span className="_ _18"> </span>с <span className="_ _18"> </span>учетом <span className="_ _13"> </span>
              требований <span className="_ _18"> </span>Федера<span className="_ _6"></span>льного{' '}
              <span className="_ _18"> </span>закона <span className="_ _13"> </span>от{' '}
            </div>
            <div className="t m0 x4 h3 y2f ff3 fs0 fc0 sc0 lsb ws0">
              27<span className="ff4 ws3">.07.2006 № 152</span>-
              <span className="ff4 ws3">
                ФЗ «О персональных данных».<span className="ff3"> </span>
              </span>
            </div>
            <div className="t m0 x3 h3 y30 ff3 fs0 fc0 sc0 lsb ws3">
              {' '}
              6.5
              <span className="ff4">
                .8. <span className="_ _1a"> </span>Не <span className="_ _1a"> </span>осу<span className="_ _6"></span>
                ществлять <span className="_ _1a"> </span>тран<span className="_ _1"></span>сграничну
                <span className="_ _3"></span>ю<span className="_ _1"></span> <span className="_ _1a"> </span>передачу{' '}
                <span className="_ _1a"> </span>персональных <span className="_ _1a"> </span>данных,{' '}
              </span>
            </div>
            <div className="t m0 x4 h3 y35 ff4 fs0 fc0 sc0 lsb ws3">
              обрабатываемых в рамках исполнения настоящего пору<span className="_ _6"></span>чения;{' '}
              <span className="ff3"> </span>
            </div>
            <div className="t m0 x3 h3 y31 ff3 fs0 fc0 sc0 lsb ws0">
              6.5
              <span className="ff4 ws3">
                .9. При <span className="_ _3"></span>сборе персональных данных <span className="_ _6"></span>в{' '}
                <span className="_ _6"></span>рамках испол<span className="_ _6"></span>нения настоящего{' '}
                <span className="_ _3"></span>обеспечить{' '}
              </span>
            </div>
            <div className="t m0 x4 h3 y32 ff4 fs0 fc0 sc0 lsb ws0">
              запись,<span className="ff3 ls1c ws3"> </span>
              <span className="ws3">
                систематизацию, <span className="_ _18"> </span>накопление, <span className="_ _13"> </span>х
                <span className="_ _1"></span>ранение, <span className="_ _18"> </span>уточнение{' '}
                <span className="_ _13"> </span>(обновление, <span className="_ _18"> </span>и
                <span className="_ _1"></span>зменение),{' '}
              </span>
            </div>
            <div className="t m0 x4 h4 y33 ff4 fs0 fc0 sc0 lsb ws3">
              извлечение <span className="_ _d"> </span>персональных <span className="_ _2"></span>данных{' '}
              <span className="_ _d"> </span>граждан <span className="_ _2"></span>Р<span className="_ _1"></span>
              оссийской <span className="_ _2"></span>Федерации <span className="_ _d"> </span>с{' '}
              <span className="_ _2"></span>и<span className="_ _1"></span>спользованием <span className="_ _2"></span>
              баз{' '}
            </div>
            <div className="t m0 x4 h3 y34 ff4 fs0 fc0 sc0 lsb ws3">
              данных, находящихся на территории Российской Федерации; <span className="ff3"> </span>
            </div>
          </div>
          <div className="c x0 y1 w2 h0">
            <div className="t m0 x3 h3 y2 ff3 fs0 fc0 sc0 lsb ws3"> </div>
            <div className="t m0 x3 h3 y3 ff3 fs0 fc0 sc0 lsb ws0">
              7
              <span className="ff4 ws3">
                . ПРОЧИЕ УСЛОВИЯ АКЦИИ<span className="ff3"> </span>
              </span>
            </div>
            <div className="t m0 x3 h3 y4 ff3 fs0 fc0 sc0 lsb ws3"> </div>
            <div className="t m0 x3 h3 y5 ff3 fs0 fc0 sc0 lsb ws3"> </div>
            <div className="t m0 x3 h3 y6 ff3 fs0 fc0 sc0 lsb ws3">
              7.1. <span className="_ _0"></span>
              <span className="ff4">
                Совершение <span className="_ _b"></span>д<span className="_ _1"></span>ействий,{' '}
                <span className="_ _0"></span>направленных <span className="_ _b"></span>на{' '}
                <span className="_ _0"></span>участие <span className="_ _b"></span>в <span className="_ _0"></span>
                настоящей <span className="_ _0"></span>Акц<span className="_ _1"></span>ии,{' '}
                <span className="_ _b"></span>является{' '}
              </span>
            </div>
            <div className="t m0 x4 h3 y7 ff4 fs0 fc0 sc0 lsb ws3">
              выражением Участниками согласия с настоящими Правилами.<span className="ff3"> </span>
            </div>
            <div className="t m0 x3 h3 y8 ff3 fs0 fc0 sc0 lsb ws0">
              7.2
              <span className="ff4 ws3">
                . <span className="_ _b"></span>Организатор <span className="_ _0"></span>Акции{' '}
                <span className="_ _b"></span>оставляет <span className="_ _0"></span>за <span className="_ _0"></span>
                собой <span className="_ _b"></span>право <span className="_ _0"></span>отказать{' '}
                <span className="_ _b"></span>в <span className="_ _2"></span>участии <span className="_ _b"></span>
                любому <span className="_ _b"></span>лиц<span className="_ _1"></span>у{' '}
              </span>
            </div>
            <div className="t m0 x4 h3 y9 ff4 fs0 fc0 sc0 lsb ws3">
              (или <span className="_ _d"> </span>гр<span className="_ _1"></span>у<span className="_ _3"></span>п
              <span className="_ _1"></span>пе <span className="_ _d"> </span>лиц) <span className="_ _8"> </span>по{' '}
              <span className="_ _d"> </span>свое<span className="_ _6"></span>му <span className="_ _8"> </span>у
              <span className="_ _3"></span>смотрени<span className="_ _1"></span>ю <span className="_ _d"> </span>и{' '}
              <span className="_ _8"> </span>б<span className="_ _6"></span>ез <span className="_ _d"> </span>указания{' '}
              <span className="_ _d"> </span>причин.<span className="_ _b"></span>
              <span className="ff3 ls1d"> </span>Решение <span className="_ _d"> </span>Организатора{' '}
            </div>
            <div className="t m0 x4 h4 ya ff4 fs0 fc0 sc0 lsb ws3">
              Акции <span className="_ _1"></span>является <span className="_ _b"></span>окончательным{' '}
              <span className="_ _1"></span>и <span className="_ _b"></span>не <span className="_ _1"></span>подлежит{' '}
              <span className="_ _1"></span>п<span className="_ _1"></span>ересмотру<span className="_ _6"></span>.{' '}
              <span className="_ _1"></span>Организатор <span className="_ _b"></span>Акции{' '}
              <span className="_ _b"></span>вправе <span className="_ _1"></span>по{' '}
            </div>
            <div className="t m0 x4 h4 yb ff4 fs0 fc0 sc0 lsb ws3">
              своему <span className="_ _9"> </span>у<span className="_ _6"></span>смотрению{' '}
              <span className="_ _9"> </span>признать <span className="_ _9"> </span>недействительн
              <span className="_ _6"></span>ым <span className="_ _9"> </span>у<span className="_ _6"></span>частие{' '}
              <span className="_ _9"> </span>в <span className="_ _8"> </span>Акц<span className="_ _1"></span>ии,{' '}
              <span className="_ _9"> </span>запретить <span className="_ _9"> </span>у<span className="_ _3"></span>
              частие <span className="_ _9"> </span>в{' '}
            </div>
            <div className="t m0 x4 h4 yc ff4 fs0 fc0 sc0 lsb ws3">
              Акции, <span className="_ _3"></span>не <span className="_ _6"></span>допускать{' '}
              <span className="_ _3"></span>к <span className="_ _6"></span>учас<span className="_ _6"></span>тию{' '}
              <span className="_ _3"></span>в <span className="_ _6"></span>Акции <span className="_ _6"></span>в{' '}
              <span className="_ _3"></span>случае <span className="_ _3"></span>нар<span className="_ _1"></span>уше
              <span className="_ _6"></span>ния <span className="_ _3"></span>Участник<span className="_ _1"></span>ом{' '}
              <span className="_ _3"></span>Ак<span className="_ _1"></span>ции<span className="_ _1"></span>{' '}
              <span className="_ _3"></span>настоящих{' '}
            </div>
            <div className="t m0 x4 h3 yd ff4 fs0 fc0 sc0 lsb ws3">
              Правил, выявления фактов злоупотребления Участником Акции.<span className="ff3"> </span>
            </div>
            <div className="t m0 x3 h3 ye ff3 fs0 fc0 sc0 lsb ws3">
              7.3. <span className="_"> </span>
              <span className="ff4">
                Органи<span className="_ _1"></span>затор <span className="_"> </span>оста<span className="_ _1"></span>
                вляет <span className="_"> </span>з<span className="_ _1"></span>а <span className="_"> </span>собой{' '}
                <span className="_ _a"> </span>право <span className="_ _a"> </span>изменять{' '}
                <span className="_ _a"> </span>или <span className="_"> </span>дополнять <span className="_ _a"> </span>
                настоящие{' '}
              </span>
            </div>
            <div className="t m0 x4 h4 yf ff4 fs0 fc0 sc0 lsb ws3">
              Правила <span className="_ _10"></span>по <span className="_ _3"></span>своему{' '}
              <span className="_ _10"></span>усмотрению, <span className="_ _3"></span>а <span className="_ _10"></span>
              также <span className="_ _3"></span>приостанавливать <span className="_ _10"></span>и{' '}
              <span className="_ _10"></span>п<span className="_ _1"></span>рекращать <span className="_ _10"></span>
              проведение <span className="_ _10"></span>Акции{' '}
            </div>
            <div className="t m0 x4 h3 y10 ff4 fs0 fc0 sc0 lsb ws3">
              при <span className="_"> </span>у<span className="_ _6"></span>словии <span className="_"> </span>
              информирования <span className="_"> </span>Уча<span className="_ _6"></span>стников{' '}
              <span className="_"> </span>Акции <span className="_ _9"> </span>на <span className="_"> </span>сайте{' '}
              <span className="_"> </span>
              <span className="ff3 fc2">
                https://t2.ru/help/article/osago{' '}
                <span className="fc0">
                  <span className="fc4 sc0"> </span>
                </span>
              </span>
            </div>
            <div className="t m0 x4 h3 y11 ff3 fs0 fc0 sc0 lsb ws3">
              . <span className="_ _1b"> </span>
              <span className="ws0 v1">7.4</span>
              <span className="ff4 v1">
                . <span className="_ _9"> </span>Организатор <span className="_ _8"> </span>Акции{' '}
                <span className="_ _9"> </span>не <span className="_ _8"> </span>н<span className="_ _1"></span>есет{' '}
                <span className="_ _9"> </span>отве<span className="_ _6"></span>тственности{' '}
                <span className="_ _9"> </span>за <span className="_ _8"> </span>технически
                <span className="_ _1"></span>е <span className="_ _9"> </span>сбои, <span className="_ _9"> </span>
                которые{' '}
              </span>
            </div>
            <div className="t m0 x4 h3 y13 ff4 fs0 fc0 sc0 lsb ws3">
              могут <span className="_ _3"></span>произ<span className="_ _1"></span>ойти,{' '}
              <span className="_ _6"></span>в <span className="_ _3"></span>рез<span className="_ _1"></span>ультате{' '}
              <span className="_ _3"></span>которых <span className="_ _3"></span>Участник не{' '}
              <span className="_ _3"></span>сможет <span className="_ _6"></span>получить <span className="_ _3"></span>
              кешбэк
              <span className="ff3 ws0">
                .
                <span className="fc1 ws3">
                  {' '}
                  <span className="ff4">
                    Организатор<span className="_ _6"></span>{' '}
                  </span>
                </span>
              </span>
            </div>
            <div className="t m0 x4 h4 y14 ff4 fs0 fc1 sc0 lsb ws3">
              не <span className="_ _d"> </span>несет <span className="_ _d"> </span>ответственности{' '}
              <span className="_ _d"> </span>в <span className="_ _d"> </span>сбоях <span className="_ _d"> </span>либо{' '}
              <span className="_ _d"> </span>неполадках <span className="_ _d"> </span>в <span className="_ _d"> </span>
              работе <span className="_ _2"></span>к<span className="_ _1"></span>омпьютерных{' '}
              <span className="_ _d"> </span>сетей, <span className="_ _d"> </span>сетей{' '}
            </div>
            <div className="t m0 x4 h3 y15 ff4 fs0 fc1 sc0 lsb ws3">
              связи и передачи данных.<span className="ff3 fc0"> </span>
            </div>
            <div className="t m0 x3 h3 y16 ff3 fs0 fc0 sc0 lsb ws0">
              7.5
              <span className="ff4 ws3">
                . <span className="_ _14"> </span>Расходы, <span className="_ _14"> </span>связанные{' '}
                <span className="_ _14"> </span>с <span className="_ _14"> </span>учас<span className="_ _6"></span>тием{' '}
                <span className="_ _14"> </span>в <span className="_ _14"> </span>Акции,{' '}
                <span className="_ _14"> </span>Участники <span className="_ _14"> </span>Акции{' '}
                <span className="_ _14"> </span>несут{' '}
              </span>
            </div>
            <div className="t m0 x4 h3 y17 ff4 fs0 fc0 sc0 lsb ws0">
              самостоятельно
              <span className="ff3">
                ,
                <span className="fc1 ls1e ws3">
                  {' '}
                  <span className="ff4 lsb">
                    кроме <span className="_ _2"></span>тех <span className="_ _2"></span>расходов,{' '}
                    <span className="_ _0"></span>к<span className="_ _1"></span>оторые <span className="_ _0"></span>
                    прямо <span className="_ _2"></span>указаны <span className="_ _0"></span>в{' '}
                    <span className="_ _2"></span>настоящих<span className="_ _1"></span> <span className="_ _2"></span>
                    Правилах, <span className="_ _0"></span>как{' '}
                  </span>
                </span>
              </span>
            </div>
            <div className="t m0 x4 h3 y18 ff4 fs0 fc1 sc0 lsb ws3">
              расходы, производимые за счет Организатора.<span className="ff3"> </span>
            </div>
            <div className="t m0 x3 h3 y19 ff3 fs0 fc1 sc0 lsb ws3">
              7.6. <span className="_"> </span>
              <span className="ff4">
                Орга<span className="_ _6"></span>низатор <span className="_"> </span>не <span className="_ _9"> </span>
                вступает <span className="_ _9"> </span>в <span className="_"> </span>письменные{' '}
                <span className="_ _9"> </span>п<span className="_ _1"></span>ереговоры, <span className="_ _9"> </span>
                либо <span className="_"> </span>иные <span className="_ _9"> </span>контакты{' '}
                <span className="_"> </span>с{' '}
              </span>
            </div>
            <div className="t m0 x4 h4 y1a ff4 fs0 fc1 sc0 lsb ws3">
              лицами, <span className="_ _3"></span>участвующими <span className="_ _10"></span>в{' '}
              <span className="_ _3"></span>Акци<span className="_ _1"></span>и, <span className="_ _3"></span>кроме{' '}
              <span className="_ _10"></span>сл<span className="_ _1"></span>учаев, <span className="_ _10"></span>
              предусмотренных <span className="_ _3"></span>настоящ<span className="_ _1"></span>ими{' '}
              <span className="_ _3"></span>Правилами{' '}
            </div>
            <div className="t m0 x4 h3 y1b ff4 fs0 fc1 sc0 lsb ws3">
              и действующим законодательством.<span className="ff3"> </span>
            </div>
            <div className="t m0 x4 h3 y1c ff3 fs0 fc1 sc0 lsa ws3">
              {' '}
              <span className="fc0 lsb"> </span>
            </div>
          </div>
        </>
      )}
    </div>
  );
};
