import React, { FC, useState } from 'react';
import cn from 'classnames';
import s from './PrivacyPolicyBlock.module.scss';
import { Link } from '../Link/Link';

export type PrivacyPolicyBlockProps = {
  className?: string;
};

export const PrivacyPolicyBlock: FC<PrivacyPolicyBlockProps> = ({ className }) => {
  const [open, setOpen] = useState<boolean>(false);

  return (
    <div className={cn(s.root, className)}>
      <p className={s.info}>
        Сервис по подбору полиса ОСАГО предоставляет ООО «МБ Маркетплейс».
        <br />
        <br />
        Нажимая на кнопку «Оформить заявку» вы подтверждаете, что ознакомлены и принимаете{' '}
        <span className={s.btn} onClick={() => setOpen((prev) => !prev)}>
          {' '}
          условия передачи данных
        </span>
        .
      </p>
      {open && (
        <p className={s.info}>
          Настоящим даю согласие ООО «МБ Маркетплейс» (далее – Компания, адрес местонахождения: 117218, Москва,
          Нахимовский просп., д. 32) на обработку, а также передачу третьим лицам моих персональных данных (в том числе
          сбор, систематизацию, накопление, хранение, уточнение (обновление, изменение), использование, обезличивание,
          блокирование, уничтожение) любым, не противоречащим российскому законодательству способом (в том числе без
          использования средств автоматизации) в целях осуществление предварительного автоматического расчета стоимости
          услуг страхования, предоставление консультации по услугам страхования Компанией и контрагентами Компании и в
          иных указанных в{' '}
          <Link
            className={s.link}
            target="_blank"
            href="https://assets.finuslugi.ru/mp-assets/privacy-policy-mb-marketplace.pdf"
          >
            Политике конфиденциальности
          </Link>{' '}
          целях. При этом такое согласие дается на неограниченный срок и может быть отозвано мной в любое время путем
          передачи Компании соответствующего уведомления в порядке, указанном в{' '}
          <Link
            className={s.link}
            target="_blank"
            href="https://assets.finuslugi.ru/mp-assets/privacy-policy-mb-marketplace.pdf"
          >
            Политике конфиденциальности
          </Link>
        </p>
      )}
    </div>
  );
};
