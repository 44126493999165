import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import { useEvent } from '@core/hooks/useEvent';
import { PolicyOrder, useStore } from '@core';
import { apiGetOrderKeyByEK, apiGetOrderKeyByHK } from '@core/services/api/order';
import { logError } from '@utils/logger';

export const ORDER_KEY = 'order_key';
export const ORDER_KEY_HASH_REGEXP = new RegExp(`^#.*${ORDER_KEY}=(\\w+)$`);
export const EK_KEY = 'ek';
export const EK_KEY_HASH_REGEXP = new RegExp(`^#.*${EK_KEY}=(\\w+)$`);
export const HK_KEY_HASH_REGEXP = /^#s(\w+)$/;

const getEK = (hash: string, search: URLSearchParams) => {
  if (search.has(EK_KEY)) return search.get(EK_KEY);
  if (EK_KEY_HASH_REGEXP.test(hash)) return hash.replace(EK_KEY_HASH_REGEXP, '$1');
  return null;
};

const getHK = (hash: string) => {
  if (HK_KEY_HASH_REGEXP.test(hash)) return hash.replace(HK_KEY_HASH_REGEXP, '$1');
  return null;
};

export const useSavedPolicyOrderMethods = () => {
  const store = useStore();
  const location = useLocation();
  const navigate = useNavigate();
  const [search, setSearch] = useSearchParams();

  const getOrderKey = useEvent(async (params?: { captchaToken?: string }) => {
    if (search.has(ORDER_KEY)) return search.get(ORDER_KEY);
    if (ORDER_KEY_HASH_REGEXP.test(location.hash)) return location.hash.replace(ORDER_KEY_HASH_REGEXP, '$1');

    if (!params?.captchaToken) return null;
    const { captchaToken } = params;
    const ek = getEK(location.hash, search);
    if (ek) {
      try {
        const { ok } = await apiGetOrderKeyByEK({ ek, token: captchaToken });
        return ok;
      } catch (e) {
        logError(e);
      }
    }
    const hk = getHK(location.hash);
    if (hk) {
      try {
        const { ok } = await apiGetOrderKeyByHK({ hk, token: captchaToken });
        return ok;
      } catch (e) {
        logError(e);
      }
    }
    return null;
  });

  const applyPolicyOrderToStore = useEvent((policyOrder: PolicyOrder) => store.setValueFromPolicyOrder(policyOrder));

  const resetPolicyOrderLinks = useEvent((prepareSearch: (_search: URLSearchParams) => URLSearchParams = (x) => x) => {
    search.delete(ORDER_KEY);
    search.delete(EK_KEY);
    setTimeout(() =>
      navigate({
        hash: location.hash,
        search: prepareSearch(search).toString(),
        pathname: location.pathname,
      })
    );

    if (
      ORDER_KEY_HASH_REGEXP.test(location.hash) ||
      EK_KEY_HASH_REGEXP.test(location.hash) ||
      HK_KEY_HASH_REGEXP.test(location.hash)
    ) {
      setTimeout(() => navigate({ hash: undefined, search: location.search, pathname: location.pathname }));
    }
  });

  return {
    applyPolicyOrderToStore,
    getOrderKey,
    resetPolicyOrderLinks,
  };
};
