import { useLayoutEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useEvent } from '@core/hooks/useEvent';

export const replaceBrokenAmp = (string: string) => string.replace(/([&?])?amp(;|%3B)/gi, '$1');

export const useRemoveBrockenAmp = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const effect = useEvent(() => {
    setTimeout(() => setTimeout(() => navigate({ ...location, hash: replaceBrokenAmp(location.hash) })));
  });

  useLayoutEffect(effect, [effect]);
};
