import React, { FC } from 'react';
import cn from 'classnames';
import s from './PandaSvg1.module.scss';

export type PandaSvg1Props = {
  className?: string;
};

export const PandaSvg1: FC<PandaSvg1Props> = ({ className }) => (
  <svg
    className={cn(s.root, className)}
    width="256"
    height="256"
    viewBox="0 0 128 128"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M47.3528 15.3342C47.3528 15.3342 43.5314 9.25879 39.7349 11.4923C39.7349 11.4923 38.2391 12.3271 38.8049 14.5612C39.3562 16.7969 39.3748 19.2707 45.0941 19.4103L47.3528 15.3342Z"
      fill="black"
      stroke="white"
      strokeWidth="0.6"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M94.2644 90.3681C88.2025 77.6086 79.1698 53.4928 64.5361 56.3213C64.5361 56.3213 57.7972 56.7997 53.5526 67.0722C51.1305 72.9355 49.2816 84.3976 42.4631 89.3827C40.0306 92.3293 16.8251 70.0605 14.977 66.6865C11.6506 49.2707 35.1804 73.2889 38.0379 66.3323C40.4454 60.4707 42.7831 54.5139 44.6574 49.6566C45.3725 47.8064 47.157 45.3476 49.2897 44.8906C50.464 44.639 55.3802 47.6807 55.5984 47.5387C56.0551 47.2414 51.4813 44.1804 50.3774 43.4312C49.2735 42.682 42.3643 36.3132 40.2068 30.39C40.2068 30.39 43.2657 22.2431 47.1534 15.6664C49.548 11.6189 51.1397 8.66567 55.3339 6.43072C65.4425 1.02659 79.1566 5.98165 87.0409 17.412C92.2044 24.9103 96.4078 32.9019 102.073 39.5026C103.024 40.6019 108.119 46.6048 111.755 51.5814C142.04 86.6739 81.0595 99.5873 94.2644 90.3681Z"
      fill="black"
      stroke="black"
      strokeWidth="0.6"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M11.6399 32.7018C5.59784 35.267 1.09066 39.4903 2.93226 46.077C8.02914 64.3065 21.1345 75.8401 38.1601 79.4228C59.129 83.8353 48.5599 64.259 46.6397 59.2369C40.1629 57.5372 33.9274 60.7567 29.0465 48.4901C29.0224 47.4364 28.0001 39.5172 26.2919 36.7821C22.7651 31.135 17.8545 30.0633 11.6399 32.7018Z"
      fill="black"
    />
    <path
      d="M13.6773 57.5027C14.65 58.4371 25.0987 57.3346 26.7159 50.3999M45.1188 60.7186C44.1196 59.8206 42.8289 59.0003 41.4258 58.437"
      stroke="white"
      strokeWidth="0.6"
      strokeLinecap="round"
    />
    <ellipse
      cx="16.1333"
      cy="44.1827"
      rx="7.98657"
      ry="5.00327"
      transform="rotate(-31.9603 16.1333 44.1827)"
      fill="white"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M6.05071 41.2374C5.01988 42.5273 4.84824 44.4056 6.04914 44.3606C6.3097 44.3433 6.5448 44.2055 6.89687 43.9902C10.5276 41.9166 8.80615 37.8088 6.05071 41.2374Z"
      fill="white"
      stroke="black"
      strokeWidth="0.6"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M17.9023 33.6509C16.2514 33.6189 14.6571 34.6268 15.4242 35.5518C15.5967 35.7479 15.8491 35.8505 16.2344 35.9986C20.0903 37.6153 22.2999 33.7481 17.9023 33.6509Z"
      fill="white"
      stroke="black"
      strokeWidth="0.6"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12.804 35.4657C12.804 35.4657 11.4829 33.9658 9.68298 36.0223C7.86689 38.0972 9.30505 38.9961 9.30505 38.9961C9.30505 38.9961 10.2893 39.8902 11.854 38.2333C13.4025 36.5948 13.1918 36.0505 12.804 35.4657Z"
      fill="white"
      stroke="black"
      strokeWidth="0.6"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M113.747 109.482C112.126 114.132 94.6902 122.65 91.8502 122.431C80.1786 121.663 79.2969 101.095 69.4086 105.723C68.787 105.244 44.1113 109.742 38.7827 101.7C38.1262 101.244 40.3914 95.5721 39.7728 95.3189C41.0756 91.1314 44.8714 85.4301 46.7357 83.1825C51.3217 78.9719 51.1785 72.7104 53.6604 66.855C57.9026 56.8185 64.5381 56.3372 64.5381 56.3372C78.9407 53.55 113.747 76.7223 113.747 109.482Z"
      fill="white"
      stroke="black"
      strokeWidth="0.6"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M51.4354 79.6946C51.4354 79.6946 56.1477 78.1223 61.6846 78.3418"
      stroke="black"
      strokeWidth="0.6"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M86.8096 33.6199C80.6094 42.7757 67.6907 46.8075 56.5609 45.6234C45.3114 44.4235 40.1956 30.406 40.1956 30.406C40.1956 30.406 44.2285 19.7009 48.9028 12.916C49.8743 11.493 50.882 10.2573 51.894 9.31591C56.5125 4.99856 70.0534 8.57344 75.1942 10.6068C83.9294 14.0405 93.1979 24.1773 86.8096 33.6199Z"
      fill="white"
      stroke="white"
      strokeWidth="0.6"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M62.8845 8.51228C57.9113 11.2787 49.3577 12.5107 49.1686 12.5324C51.2274 9.54029 52.6646 7.78889 54.501 7L62.8845 8.51228Z"
      fill="black"
      stroke="white"
      strokeWidth="0.6"
      strokeMiterlimit="22.9256"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M59.8801 18.2887C60.2111 19.5034 60.5583 20.7309 60.8625 21.7129C61.5168 23.8189 62.864 26.1844 64.5983 27.8128C66.8597 29.926 69.7307 30.6725 72.486 29.3837C74.8362 28.2887 76.9709 25.7006 76.976 23.0473C76.9873 17.6227 67.6684 12.8266 63.0414 12.9595C58.9739 13.0873 58.8761 14.6754 59.8801 18.2887Z"
      fill="black"
      stroke="black"
      strokeWidth="0.6"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M64.6971 22.6432L71.2427 21.8922C71.0353 19.956 69.3342 19.0017 67.7146 19.1433C66.0805 19.2866 64.5441 20.5387 64.6971 22.6432Z"
      stroke="white"
      strokeWidth="0.6"
      strokeMiterlimit="22.9256"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M68.9304 22.1574L71.2286 21.8937C71.1352 21.0791 70.7804 20.4272 70.2856 19.9682C69.4021 20.2316 68.7907 21.0681 68.8942 21.9699C68.9025 22.0427 68.9237 22.0992 68.9304 22.1574Z"
      fill="white"
      stroke="white"
      strokeWidth="0.6"
      strokeMiterlimit="22.9256"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M54.8395 22.6543C55.1204 22.5336 55.2956 22.263 55.2897 21.9542C55.224 13.5468 52.3096 13.3212 49.4551 16.6993C45.9903 20.7959 42.2607 28.6219 49.2161 31.6997C49.5836 31.8196 50.0576 31.4558 50.0142 31.0776C49.864 28.8695 50.4101 26.95 51.446 25.4458C52.2937 24.2285 53.4529 23.285 54.8395 22.6543Z"
      fill="black"
      stroke="black"
      strokeWidth="0.6"
      strokeMiterlimit="22.9256"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M48.2181 24.534L54.0218 23.8681C53.8394 22.1501 52.3279 21.3066 50.8991 21.4411C49.4524 21.5481 48.0917 22.6622 48.2181 24.534Z"
      stroke="white"
      strokeWidth="0.6"
      strokeMiterlimit="22.9256"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M52.0815 24.0321L54.016 23.8102C53.9376 23.1265 53.6398 22.5859 53.2261 22.1913C52.4847 22.409 51.9763 23.1157 52.0631 23.8721C52.0569 23.9465 52.0619 23.9902 52.0815 24.0321Z"
      fill="white"
      stroke="white"
      strokeWidth="0.6"
      strokeMiterlimit="22.9256"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M50.0305 29.4255C50.2976 26.359 52.0367 23.9194 54.839 22.6547"
      stroke="black"
      strokeWidth="0.6"
      strokeMiterlimit="22.9256"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M51.2151 34.7419C51.2151 34.7419 52.0346 36.49 53.2448 37.147"
      stroke="black"
      strokeWidth="0.6"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M51.2014 25.2379C50.6615 25.2852 50.4328 25.9893 50.8191 26.4018C51.647 27.3237 52.7308 27.9067 53.8751 28.247C54.6844 28.4931 54.77 28.4686 55.5026 28.0456C56.5255 27.4566 57.4345 26.6449 58.0338 25.574C58.3166 25.0847 57.9344 24.4507 57.3979 24.527L51.2014 25.2379Z"
      fill="black"
      stroke="black"
      strokeWidth="0.6"
      strokeMiterlimit="22.9256"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M54.9785 30.8027L54.5312 26.9044"
      stroke="black"
      strokeWidth="0.6"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M52.6685 31.5984C56.6378 35.6231 59.557 31.7807 61.2184 27.5079C59.9736 30.274 55.4627 31.6757 52.6685 31.5984Z"
      stroke="black"
      strokeWidth="0.6"
      strokeMiterlimit="22.9256"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M56.6871 30.916C55.7634 31.2136 55.0027 31.0061 54.8976 30.0897C55.0861 31.7334 52.8194 31.7577 51.796 31.3151"
      stroke="black"
      strokeWidth="0.6"
      strokeMiterlimit="22.9256"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M57.2127 30.7381C55.8373 31.338 53.7769 31.6186 52.6692 31.5984C53.9719 32.8047 54.8997 33.1846 55.9737 33.2971C55.8106 32.0042 56.5417 31.1835 57.2127 30.7381Z"
      fill="black"
      stroke="black"
      strokeWidth="0.6"
      strokeMiterlimit="22.9256"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M57.6344 30.5717C58.4177 30.3345 59.4447 30.423 59.6622 30.6485C60.3168 29.6745 60.7515 28.7109 61.2177 27.5079C60.6065 28.8601 59.2196 29.874 57.6344 30.5717Z"
      fill="black"
      stroke="black"
      strokeWidth="0.6"
      strokeMiterlimit="22.9256"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M60.668 26.7013C60.9412 27.1563 61.5085 27.9901 62.123 28.4649"
      stroke="black"
      strokeWidth="0.6"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M24.4003 110.414C24.4003 110.414 22.4677 97.5586 29.6125 92.7124C32.8482 90.5895 37.1527 91.3069 39.9345 93.7812C41.1497 96.1824 43.5802 103.635 46.6255 103.4C49.6855 103.166 51.5888 98.7739 56.2301 101.043C56.2301 101.043 59.5243 100.106 61.1348 104.352C61.1348 104.352 64.517 104.806 65.9811 106.212C75.6003 102.873 84.0336 92.405 93.5796 91.9365C97.5327 91.7461 101.281 93.1224 104.516 95.2893C112.964 100.955 117.269 109.93 110.358 117.222C101.471 126.607 79.5827 127.5 66.8888 127.69C62.9064 127.749 57.4892 127.88 54.6928 124.703C54.6928 124.703 47.504 127.88 38.0605 123.927C32.98 121.819 25.1763 116.197 24.4003 110.414Z"
      fill="black"
      stroke="black"
      strokeWidth="0.6"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M40.8309 94.3824C38.0491 91.9081 32.8482 90.5895 29.6125 92.7124C22.4677 97.5587 24.4003 110.414 24.4003 110.414C25.1763 116.197 32.98 121.819 38.0605 123.927C47.504 127.88 54.6928 124.703 54.6928 124.703C57.4892 127.88 62.9064 127.749 66.8888 127.69C79.5827 127.5 101.471 126.607 110.358 117.222C114.744 113.065 113.784 106.389 112.54 102.542"
      stroke="white"
      strokeWidth="0.6"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M53.5368 103.547C53.5368 103.547 53.5368 103.547 53.5222 103.562C50.5061 106.49 44.1665 114.411 53.3465 123.239C41.985 118.569 47.3876 105.611 52.4095 102.83C53.2733 102.346 54.181 102.932 53.5368 103.547Z"
      fill="white"
      stroke="black"
      strokeWidth="0.6"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M54.3145 114.382L61.2027 104.381"
      stroke="white"
      strokeWidth="0.6"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M49.9941 106.812C49.9941 106.812 51.9853 107.837 52.1903 109.301"
      stroke="white"
      strokeWidth="0.6"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M53.1562 103.342C53.1562 103.342 55.3378 104.367 55.5428 105.831"
      stroke="white"
      strokeWidth="0.6"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M15.1825 67.0516C10.095 61.6994 5.202 54.1977 2.93151 46.0771C1.08991 39.4904 5.59709 35.2671 11.6392 32.7019C17.8538 30.0634 22.7644 31.1351 26.2912 36.7822C27.9993 39.5173 29.0216 47.4365 29.0457 48.4902C32.5612 57.3251 36.9861 58.0547 41.4909 58.4468"
      stroke="white"
      strokeWidth="0.6"
      strokeLinecap="round"
    />
    <path
      d="M43.1413 89.8122C40.3621 91.2733 31.7221 84.0777 24.6137 77.0574C22.2944 74.7668 15.361 67.3115 15.1342 67.0032M111.755 51.5818C108.119 46.6052 103.024 40.6023 102.072 39.503C96.4076 32.9023 92.2042 24.9107 87.0407 17.4124C79.1564 5.98202 65.4423 1.02697 55.3337 6.4311C51.1395 8.66605 49.5478 11.6193 47.1532 15.6667C43.2655 22.2434 40.2065 30.3904 40.2065 30.3904C42.3641 36.3136 49.2733 42.6824 50.3772 43.4316C51.4811 44.1808 56.0617 47.9487 56.0617 47.9487C56.0617 47.9487 50.4638 44.6393 49.2895 44.891C47.1568 45.348 45.3723 47.8068 44.6572 49.657C43.6472 52.2743 42.5027 55.2108 41.2852 58.2797"
      stroke="white"
      strokeWidth="0.6"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M56.9107 5.26272L63.6261 0.468969C69.0047 0.721346 79.0611 3.34021 82.1828 11.7949L79.3238 10.7671L77.6082 8.0165L56.9107 5.26272Z"
      fill="#A7FC00"
      stroke="black"
      strokeWidth="0.6"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M76.8239 10.4348L45.898 6.26089C45.733 6.23561 45.6128 6.08729 45.6381 5.92228L45.8837 4.08143C45.909 3.91642 46.0573 3.79624 46.2223 3.82152L80.3333 8.39636C81.0677 9.40265 81.6974 10.5241 82.1693 11.811L76.8239 10.4348Z"
      fill="#A7FC00"
      stroke="black"
      strokeWidth="0.6"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M87.1495 93.1045C96.4611 90.9059 110.208 97.2618 119.618 94.3077C130.603 81.8789 118.54 60.8945 116.116 57.62L113.022 67.6081C98.4792 64.1654 91.3155 63.6401 78.6203 69.9321C61.9334 78.1865 63.3412 87.3248 63.3412 87.3248C63.3488 87.4865 63.3564 87.6482 63.3797 87.8122C64.0835 93.4407 68.5748 97.6055 76.91 100.042C85.3527 102.511 87.1495 93.1045 87.1495 93.1045Z"
      fill="black"
      stroke="black"
      strokeWidth="0.6"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M73.9609 99.9034C82.4035 102.372 87.1498 93.1046 87.1498 93.1046C91.9786 91.9645 99.2336 91.9902 105.411 95.0843"
      stroke="white"
      strokeWidth="0.6"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M111.989 66.3595C107.707 65.5811 91.7765 63.9254 86.8228 65.8808"
      stroke="white"
      strokeWidth="0.6"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M74.1994 99.166L78.3139 90.7854"
      stroke="white"
      strokeWidth="0.6"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M66.5957 94.1434C66.5957 94.1434 67.2022 88.1359 69.9185 85.8995"
      stroke="white"
      strokeWidth="0.6"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M110.095 95.5178C113.031 95.7092 117.126 95.0896 119.616 94.3076C130.601 81.8789 118.538 60.8945 116.115 57.62C113.691 54.3456 112.48 52.4711 111.639 51.429"
      stroke="white"
      strokeWidth="0.6"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M88.193 9.71972C87.8923 12.8785 86.6602 14.2136 83.3866 15.7387C81.9743 16.4018 78.1371 17.6379 76.9381 16.0512C75.7376 14.4499 76.995 11.1519 77.6779 9.52616C78.8595 6.72316 80.3044 4.28786 83.3275 3.27782C87.2145 1.96234 88.5906 5.60665 88.193 9.71972Z"
      fill="black"
    />
    <path
      d="M78.7117 6.82767C79.7138 5.20109 81.3132 3.95102 83.3283 3.27776C87.2154 1.96228 88.5914 5.60659 88.1938 9.71966C87.9524 12.2557 87.115 13.6437 85.0983 14.8708"
      stroke="white"
      strokeWidth="0.6"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
