import { groups, observer } from '@core';
import React, { FC } from 'react';
import { getUpsaleDesc } from '@core/features/upsale/helpers/getUpsaleDesc';
import cn from 'classnames';
import { Collapse } from '@core/common/components/Collapse';
import { KIDS as KIDSDefault } from '@core/common/constants/kids';
import { KID, KIDSPopover } from '@core/common/components/Popover';
import { SwitchClearProps } from '@core/common/components/_factories/createSwitchClear';
import { GroupedUpsales, SelectedUpsales } from '@core/features/upsale/upsale.types';
import { isRequired } from '@core/features/upsale/helpers/helpers';
import s from './createUpsales.module.scss';
import { getBeautifulPrice } from '@utils/format';

export type UpsalesProps = {
  className?: string;
  disabled?: boolean;
  groupedUpsales: GroupedUpsales | null;
  selectedUpsales: SelectedUpsales | null;
  onCheckUpsale: (checked: boolean, group: string, id: string) => void;
};

export type UpsalesStyle = {
  root?: string;
  header?: string;
  group?: string;
  switchers?: string;
  text?: string;
  unchecked?: string;
  price?: string;
  tip?: string;
  tipOpened?: string;
  kids?: string;
  box?: string;
};

export const createUpsales = <T extends UpsalesProps>(
  {
    SwitchClear,
    KIDS = KIDSDefault,
  }: {
    SwitchClear: React.ComponentType<SwitchClearProps>;
    KIDS?: {
      title: string;
      entities: KID[];
    };
  },
  style?: UpsalesStyle
): FC<T> => {
  const cls = {
    root: cn(s.root, style?.root),
    header: cn(s.header, style?.header),
    group: cn(s.group, style?.group),
    switchers: cn(s.switchers, style?.switchers),
    text: cn(s.text, style?.text),
    unchecked: cn(s.unchecked, style?.unchecked),
    price: cn(s.price, style?.price),
    tip: cn(s.tip, style?.tip),
    tipOpened: cn(s.tipOpened, style?.tipOpened),
    kids: cn(s.kids, style?.kids),
    box: cn(s.box, style?.box),
  };
  return observer(({ className, disabled, onCheckUpsale, selectedUpsales, groupedUpsales }) => {
    if (!groupedUpsales || !Object.keys(groupedUpsales).length) return null;

    const _groups = Object.keys(groupedUpsales);
    const visibleKIDS =
      !!Object.keys(selectedUpsales || {})?.length ||
      _groups.some((group) => groupedUpsales[group].some((i) => isRequired(i.type)));

    return (
      <div className={cn(cls.root, className)}>
        {_groups.map((group) => {
          const requiredGroup = isRequired(group);
          return (
            <div key={group} className={cls.group}>
              <div className={cls.header}>{groups[group?.toLowerCase()]}</div>
              <div className={cls.switchers}>
                {groupedUpsales[group].map((upsale) => {
                  const desc = getUpsaleDesc(upsale);
                  if (!desc) return null;

                  const isMandatory = requiredGroup || isRequired(upsale?.type) || false;
                  const isSelected = selectedUpsales?.[group] === upsale.id || false;
                  const checked = isMandatory || isSelected;
                  const { name, text } = desc;

                  const onCheck = (val = false) => onCheckUpsale(val, group, upsale.id);
                  const price = parseFloat(upsale.packetPrice);

                  return (
                    <div key={name}>
                      <SwitchClear disabled={disabled || isMandatory} onCheck={onCheck} checked={checked}>
                        <div className={cn(cls.text, !checked && cls.unchecked)}>
                          <div
                            dangerouslySetInnerHTML={{ __html: name || upsale.upsaleName || upsale.packetName || '' }}
                          />
                          {!!price && <div className={cls.price}>+&nbsp;{getBeautifulPrice(price)}&nbsp;₽</div>}
                        </div>
                      </SwitchClear>
                      {!!text && (
                        <Collapse
                          className={cn(cls.tip, checked && cls.tipOpened)}
                          onOpen={(root) =>
                            root.current?.scrollIntoView({ inline: 'center', block: 'center', behavior: 'smooth' })
                          }
                          opened={checked}
                        >
                          <div className={cls.box}>
                            <div dangerouslySetInnerHTML={{ __html: text }} />
                          </div>
                        </Collapse>
                      )}
                    </div>
                  );
                })}
              </div>
            </div>
          );
        })}

        {visibleKIDS && <KIDSPopover className={cls.kids} entities={KIDS.entities} title={KIDS.title} />}
      </div>
    );
  });
};
