import React, { useEffect, useRef, useState } from 'react';
import { useOutsideClick } from '@core';
import cn from 'classnames';
import { IInputBase } from '../input.types';
import s from './createInputBase.module.scss';
import { useId } from '@core/hooks/useId';

export type ParamsToOutside = {
  active: boolean;
  value: string;
  onChange: (value: string) => void;
  flagAuto: boolean;
  setActive: React.Dispatch<React.SetStateAction<boolean>>;
};

export type InputBaseProps = IInputBase & {
  onChangeActive?: (active: boolean) => void;
  children?: (params: ParamsToOutside) => React.ReactNode;
  getValueByEvent?: (event: React.ChangeEvent<HTMLInputElement>) => string;
  prepareValueForInput?: (value: string) => string;
  transform?: (value: string) => string;
  bottom?: (params: ParamsToOutside) => React.ReactNode;
  prefix?: React.ReactNode | ((params: ParamsToOutside) => React.ReactNode);
  postfix?: React.ReactNode | ((params: ParamsToOutside) => React.ReactNode);
  wrapperClassName?: string | ((params: ParamsToOutside) => string);
};

export type InputBaseStyle = {
  root: string;
  wrapper: string;
  fullWidth: string;
  inputWrapper: string;
  active: string;
  white: string;
  flagAuto: string;
  error: string;
  hidden: string;
  input: string;
  skeleton: string;
};

const getValueByEventDefault: InputBaseProps['getValueByEvent'] = (e) => e.target.value;
export const createInputBase = (
  {
    Placeholder,
    AutoIcon,
    SkeletonBase,
    ErrorBlock,
  }: {
    Placeholder: React.ComponentType<{ placeholder?: string; active: boolean }>;
    AutoIcon: React.ComponentType<{ onClick?: () => void; show: boolean }>;
    SkeletonBase: React.ComponentType<{ className?: string }>;
    ErrorBlock: React.ComponentType<{ children: React.ReactNode }>;
  },
  style: InputBaseStyle
): React.FC<InputBaseProps> => {
  const cls = {
    root: cn(s.root, style.root),
    wrapper: cn(s.wrapper, style.wrapper, 'InputBase__wrapper'),
    fullWidth: cn(s.fullWidth, style.fullWidth),
    active: cn(s.active, style.active),
    inputWrapper: cn(s.inputWrapper, style.inputWrapper, 'InputBase__inputWrapper'),
    white: cn(s.white, style.white),
    flagAuto: cn(s.flagAuto, style.flagAuto),
    error: cn(s.error, style.error),
    hidden: cn(s.hidden, style.hidden),
    input: cn(s.input, style.input, 'InputBase__input'),
    skeleton: cn(s.skeleton, style.skeleton),
  };

  return ({
    name,
    skeleton,
    view,
    className,
    placeholder,
    maxLength,
    disabled,
    white,
    children,
    value,
    clearAutoValue,
    onFocus,
    flagAuto,
    onBlur,
    error,
    inputMode,
    onChange,
    hideError,
    type = 'text',
    getValueByEvent = getValueByEventDefault,
    prepareValueForInput = (x) => x,
    onKeyPress,
    bottom,
    prefix,
    postfix,
    onChangeActive,
    transform = (x) => x,
    wrapperClassName,
  }) => {
    const [active, setActive] = useState<boolean>(false);

    useEffect(() => {
      onChangeActive?.(active);
    }, [active, onChangeActive]);

    const input = useRef<HTMLInputElement>(null);

    const root = useRef<HTMLLabelElement>(null);
    useOutsideClick(
      root,
      () => {
        if (active) {
          setActive(false);
        }
      },
      active
    );

    const handlerClickWrapper = () => {
      if (!active) {
        setActive(true);
      }
    };

    const handleFocus = (e: React.FocusEvent<HTMLInputElement>) => {
      onFocus?.(e);
      setActive(true);
    };

    const _active = active || !!value;

    const params: ParamsToOutside = {
      active,
      value,
      onChange,
      setActive,
      flagAuto: !!flagAuto,
    };

    const _wrapperClassName = typeof wrapperClassName === 'function' ? wrapperClassName(params) : wrapperClassName;

    const id = useId();

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => onChange(transform(getValueByEvent(e)), e, params);
    const handleBlur = (e: React.FocusEvent<HTMLInputElement>) => {
      onBlur?.(e);
      if (e.relatedTarget) {
        setActive(false);
      }
    };

    const _value = prepareValueForInput(value);

    return (
      <label htmlFor={id} ref={root} className={cn(cls.root, view === 'fullWidth' && cls.fullWidth, className)}>
        <div
          role="presentation"
          className={cn(
            cls.wrapper,
            _active && cls.active,
            white && cls.white,
            flagAuto && cls.flagAuto,
            error && cls.error,
            skeleton && cls.hidden,
            _wrapperClassName
          )}
          onClick={handlerClickWrapper}
        >
          {!skeleton && <Placeholder placeholder={placeholder} active={_active} />}

          {children?.(params)}

          <div className={cls.inputWrapper}>
            {typeof prefix === 'function' ? prefix?.(params) : prefix}
            <input
              id={id}
              ref={input}
              className={cls.input}
              type={type}
              name={name}
              inputMode={inputMode}
              maxLength={maxLength}
              onKeyPress={onKeyPress}
              disabled={disabled}
              value={_value}
              onChange={handleChange}
              onBlur={handleBlur}
              onFocus={handleFocus}
            />
            <AutoIcon show={!!flagAuto} onClick={clearAutoValue} />
            {typeof postfix === 'function' ? postfix?.(params) : postfix}
          </div>
        </div>
        {skeleton && <SkeletonBase className={cls.skeleton} />}
        {bottom?.(params)}
        {!hideError && <ErrorBlock>{error}</ErrorBlock>}
      </label>
    );
  };
};
