import React from 'react';
import { observer } from 'mobx-react-lite';
import cn from 'classnames';
import { useChangeStep, useStore, useVehicleByLicensePlate } from '@core';

import { Button } from '../../../../../common/components/Button';
import { Skeleton } from '../../../../../common/components/Skeleton';
import { InputMask } from '../../../../../common/components/Input/InputMask';
import { LicencePlateSwitch } from '../../../../../common/components/LicencePlateSwitch';
import s from './LicensePlateBlock.module.scss';
import { History } from '../History/History';

export type LicensePlateBlockProps = {
  className?: string;
};

export const LicensePlateBlock = observer(({ className }: LicensePlateBlockProps) => {
  const store = useStore();
  const { nextStep } = useChangeStep();
  const { loading } = useVehicleByLicensePlate();

  const handlerCheckLicensePlate = () => nextStep({ scrollTopFlag: true, updateFlag: false });
  const withoutLicensePlate = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    store.form.resetFields();

    setTimeout(handlerCheckLicensePlate, 0);
  };

  const handleKeyPress = (e: React.KeyboardEvent<Element>) => {
    if (e.key == 'Enter') {
      handlerCheckLicensePlate();
    }
  };

  const vehicleType = String(store.form.fields.type.value);

  const licencePlateMask = vehicleType == '7' ? 'licensePlateMoto' : 'licensePlate';

  return (
    <div className={cn(s.root, className)}>
      <LicencePlateSwitch className={s.switch} />
      <InputMask mask={licencePlateMask} placeholder="Госномер" name="licensePlate" onKeyPress={handleKeyPress} />
      {loading ? (
        <Skeleton style={{ height: 60 }} />
      ) : (
        <Button className={s.btn} styleType="accent" onClick={handlerCheckLicensePlate}>
          Рассчитать
        </Button>
      )}
      <Button className={s.btn} styleType="transparent" onClick={withoutLicensePlate}>
        У меня нет номера
      </Button>
      <History />
    </div>
  );
});

LicensePlateBlock.displayName = 'LicensePlateBlock';
