import { useEffect, useRef } from 'react';
import { useSearchParams } from 'react-router-dom';

import { useStore } from '../providers/StoreProvider';
import { PolicyOrder, removeLoadingActiveOffersStorage, useChangeStep } from '@core';
import { getDraftsStorage } from '@core';
import { ORDER_DATA } from '@core/constants/keys';
import { useSavedPolicyOrderMethods } from '@core/hooks/useSavedPolicyOrderMethods/useSavedPolicyOrderMethods';
import { PolicyOrderDrafts } from '@core/features/order/order.types';
import { useEvent } from '@core/hooks/useEvent';
import { UpsaleSettings } from '@core/features/upsale/upsale.types';
import { useDraftStorage } from '@core/common/components/StorageProvider/StorageProvider';

/** FIXME: убрать отсюда типы Upsales */
type UpsalesParams = {
  notRequired: boolean;
  required: boolean;
};

type StartCalcParams = {
  upsales?: UpsalesParams;
  advPId: string;
};

const DRAFT_KEY = 'draft';

const resetDraft = (search: URLSearchParams) => {
  search.delete(DRAFT_KEY);
  return search;
};

/** FIXME: ПОДУМАТЬ КУДА УБРАТЬ! */
export const useStartCalc = (params: StartCalcParams): void => {
  const store = useStore();
  const draftStorage = useDraftStorage();

  const { changeStepById } = useChangeStep();
  const [search] = useSearchParams();
  const { resetPolicyOrderLinks, getOrderKey, applyPolicyOrderToStore } = useSavedPolicyOrderMethods();

  const company = useRef('');
  const draftUrl = useRef('-1');

  // todo/arkadii wrap by useEffect
  //  moreover, it looks like the following params could be set during store initialization
  store.calc.upsales = params?.upsales || ({} as UpsaleSettings);

  const getSavedPolicyOrder = useEvent(async () => {
    const orderKey = (await getOrderKey({ captchaToken: store.ui.captchaToken })) || '';
    const calc = await store.calc.initCalculatorToStore({ orderKey, advPId: params?.advPId });
    return calc[ORDER_DATA];
  });

  const applyDraft = useEvent((id: string | null, _drafts: PolicyOrderDrafts | null) => {
    const drafts = _drafts || { drafts: [] };
    if (drafts?.drafts && drafts.drafts.length !== 0 && id !== null) {
      const draft = drafts.drafts.find((dr) => dr.id === id);
      if (draft) {
        store.setValueFromLocalObject(draft);
      }
    }
  });

  useEffect(() => {
    (async () => {
      const localCompany = search.get('company');
      const draftId = search.get(DRAFT_KEY);

      if (localCompany == company.current || draftUrl.current.toString() == draftId) return;

      draftUrl.current = draftId || '-1';
      company.current = localCompany || '';

      getSavedPolicyOrder().then(async (policyOrder) => {
        if (policyOrder && !Array.isArray(policyOrder)) {
          await applyPolicyOrderToStore(policyOrder as PolicyOrder);
          resetPolicyOrderLinks(resetDraft);
          changeStepById(store.step.stepForOpenSavedOrder);
        } else {
          resetPolicyOrderLinks();
          applyDraft(draftId, await getDraftsStorage(draftStorage));
        }
      });

      if (!store.ui.uiLoading) {
        await removeLoadingActiveOffersStorage();
      }

      store.ui.setUiLoading(true);
    })();
  }, [
    draftStorage,
    applyDraft,
    getSavedPolicyOrder,
    resetPolicyOrderLinks,
    search,
    store.ui,
    applyPolicyOrderToStore,
    store.step.stepForOpenSavedOrder,
    changeStepById,
  ]);
};
