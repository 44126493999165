export type partnerMap = {
  [key: number]: string;
};

import alfastrahovanie_DarkLarge from './assets/logo-insurance-companies/alfastrahovanie_dark_large.svg';
import alfastrahovanie_ColorLarge from './assets/logo-insurance-companies/alfastrahovanie_color_large.svg';
import alfastrahovanie_Color48x48 from './assets/logo-insurance-companies/alfastrahovanie_color_48x48.svg';

import astro_volga_DarkLarge from './assets/logo-insurance-companies/astro_volga_dark_large.svg';
import astro_volga_ColorLarge from './assets/logo-insurance-companies/astro_volga_color_large.svg';
import astro_volga_Color48x48 from './assets/logo-insurance-companies/astro_volga_color_48x48.svg';

import energo_DarkLarge from './assets/logo-insurance-companies/energo_dark_large.svg';
import energo_ColorLarge from './assets/logo-insurance-companies/energo_color_large.svg';
import energo_Color48x48 from './assets/logo-insurance-companies/energo_color_48x48.svg';

import evro_polis_DarkLarge from './assets/logo-insurance-companies/evro_polis_dark_large.svg';
import evro_polis_ColorLarge from './assets/logo-insurance-companies/evro_polis_color_large.svg';
import evro_polis_Color48x48 from './assets/logo-insurance-companies/evro_polis_color_48x48.svg';

import gaideDarkLarge from './assets/logo-insurance-companies/gaide_dark_large.svg';
import gaideColorLarge from './assets/logo-insurance-companies/gaide_color_large.svg';
import gaideColor48x48 from './assets/logo-insurance-companies/gaide_color_48x48.svg';

import gelios_rezerv_DarkLarge from './assets/logo-insurance-companies/gelios_rezerv_dark_large.svg';
import gelios_rezerv_ColorLarge from './assets/logo-insurance-companies/gelios_rezerv_color_large.svg';
import gelios_rezerv_Color48x48 from './assets/logo-insurance-companies/gelios_rezerv_color_48x48.svg';

import intouch_DarkLarge from './assets/logo-insurance-companies/intouch_dark_large.svg';
import intouch_ColorLarge from './assets/logo-insurance-companies/intouch_color_large.svg';
import intouch_Color48x48 from './assets/logo-insurance-companies/intouch_color_48x48.svg';

import maks_DarkLarge from './assets/logo-insurance-companies/maks_dark_large.svg';
import maks_ColorLarge from './assets/logo-insurance-companies/maks_color_large.svg';
import maks_Color48x48 from './assets/logo-insurance-companies/maks_color_48x48.svg';

import pari_DarkLarge from './assets/logo-insurance-companies/pari_dark_large.svg';
import pari_ColorLarge from './assets/logo-insurance-companies/pari_color_large.svg';
import pari_Color48x48 from './assets/logo-insurance-companies/pari_color_48x48.svg';

import osk_DarkLarge from './assets/logo-insurance-companies/osk_dark_large.svg';
import osk_ColorLarge from './assets/logo-insurance-companies/osk_color_large.svg';
import osk_Color48x48 from './assets/logo-insurance-companies/osk_color_48x48.svg';

import renessans_DarkLarge from './assets/logo-insurance-companies/renessans_dark_large.svg';
import renessans_ColorLarge from './assets/logo-insurance-companies/renessans_color_large.svg';
import renessans_Color48x48 from './assets/logo-insurance-companies/renessans_color_48x48.svg';

import reso_DarkLarge from './assets/logo-insurance-companies/reso_dark_large.svg';
import reso_ColorLarge from './assets/logo-insurance-companies/reso_color_large.svg';
import reso_Color48x48 from './assets/logo-insurance-companies/reso_color_48x48.svg';

import rosgosstrah_DarkLarge from './assets/logo-insurance-companies/rosgosstrah_dark_large.svg';
import rosgosstrah_ColorLarge from './assets/logo-insurance-companies/rosgosstrah_color_large.svg';
import rosgosstrah_Color48x48 from './assets/logo-insurance-companies/rosgosstrah_color_48x48.svg';

import sber_DarkLarge from './assets/logo-insurance-companies/sber_dark_large.svg';
import sber_ColorLarge from './assets/logo-insurance-companies/sber_color_large.svg';
import sber_Color48x48 from './assets/logo-insurance-companies/sber_color_48x48.svg';

import sogaz_DarkLarge from './assets/logo-insurance-companies/sogaz_dark_large.svg';
import sogaz_ColorLarge from './assets/logo-insurance-companies/sogaz_color_large.svg';
import sogaz_Color48x48 from './assets/logo-insurance-companies/sogaz_color_48x48.svg';

import sovcombank_DarkLarge from './assets/logo-insurance-companies/sovcombank_dark_large.svg';
import sovcombank_ColorLarge from './assets/logo-insurance-companies/sovcombank_color_large.svg';
import sovcombank_Color48x48 from './assets/logo-insurance-companies/sovcombank_color_48x48.svg';

import soglasie_DarkLarge from './assets/logo-insurance-companies/soglasie_dark_large.svg';
import soglasie_ColorLarge from './assets/logo-insurance-companies/soglasie_color_large.svg';
import soglasie_Color48x48 from './assets/logo-insurance-companies/soglasie_color_48x48.svg';

import vsk_DarkLarge from './assets/logo-insurance-companies/vsk_dark_large.svg';
import vsk_ColorLarge from './assets/logo-insurance-companies/vsk_color_large.svg';
import vsk_Color48x48 from './assets/logo-insurance-companies/vsk_color_48x48.svg';

import tinkoff_DarkLarge from './assets/logo-insurance-companies/tinkoff_dark_large.svg';
import tinkoff_ColorLarge from './assets/logo-insurance-companies/tinkoff_color_large.svg';
import tinkoff_Color48x48 from './assets/logo-insurance-companies/tinkoff_color_48x48.svg';

import yugoria_DarkLarge from './assets/logo-insurance-companies/yugoria_dark_large.svg';
import yugoria_ColorLarge from './assets/logo-insurance-companies/yugoria_color_large.svg';
import yugoria_Color48x48 from './assets/logo-insurance-companies/yugoria_color_48x48.svg';

import zurich_DarkLarge from './assets/logo-insurance-companies/zurich_dark_large.svg';
import zurich_ColorLarge from './assets/logo-insurance-companies/zurich_color_large.svg';
import zurich_Color48x48 from './assets/logo-insurance-companies/zurich_color_48x48.svg';

import evroins_DarkLarge from './assets/logo-insurance-companies/evroins_dark_large.svg';
import evroins_ColorLarge from './assets/logo-insurance-companies/evroins_color_large.svg';
import evroins_Color48x48 from './assets/logo-insurance-companies/evroins_color_48x48.svg';

import ingosstrakh_color_square from './assets/logo-insurance-companies/ingosstrakh_color_square.svg';
import ingosstrakh_colorfull from './assets/logo-insurance-companies/ingosstrakh_colorfull.svg';
import ingosstrakh_dark from './assets/logo-insurance-companies/ingosstrakh_dark.svg';

export const mapColorLarge: partnerMap = {
  [1]: alfastrahovanie_ColorLarge,
  [3]: ingosstrakh_colorfull,
  [5]: rosgosstrah_ColorLarge,
  [7]: soglasie_ColorLarge,
  [10]: maks_ColorLarge,
  [25]: yugoria_ColorLarge,
  [27]: zurich_ColorLarge,
  [32]: reso_ColorLarge,
  [33]: vsk_ColorLarge,
  [36]: renessans_ColorLarge,
  [38]: sovcombank_ColorLarge,
  [39]: intouch_ColorLarge,
  [46]: sogaz_ColorLarge,
  [47]: gaideColorLarge,
  [52]: gelios_rezerv_ColorLarge,
  [55]: pari_ColorLarge,
  [61]: osk_ColorLarge,
  [75]: evro_polis_ColorLarge,
  [98]: astro_volga_ColorLarge,
  [107]: tinkoff_ColorLarge,
  [127]: evroins_ColorLarge,
  [144]: sber_ColorLarge,
  [145]: energo_ColorLarge,
};

export const mapDarkLarge: partnerMap = {
  [1]: alfastrahovanie_DarkLarge,
  [3]: ingosstrakh_dark,
  [5]: rosgosstrah_DarkLarge,
  [7]: soglasie_DarkLarge,
  [10]: maks_DarkLarge,
  [25]: yugoria_DarkLarge,
  [27]: zurich_DarkLarge,
  [32]: reso_DarkLarge,
  [33]: vsk_DarkLarge,
  [36]: renessans_DarkLarge,
  [38]: sovcombank_DarkLarge,
  [39]: intouch_DarkLarge,
  [46]: sogaz_DarkLarge,
  [47]: gaideDarkLarge,
  [52]: gelios_rezerv_DarkLarge,
  [55]: pari_DarkLarge,
  [61]: osk_DarkLarge,
  [75]: evro_polis_DarkLarge,
  [98]: astro_volga_DarkLarge,
  [107]: tinkoff_DarkLarge,
  [127]: evroins_DarkLarge,
  [144]: sber_DarkLarge,
  [145]: energo_DarkLarge,
};

export const mapColor48x48: partnerMap = {
  [1]: alfastrahovanie_Color48x48,
  [3]: ingosstrakh_color_square,
  [5]: rosgosstrah_Color48x48,
  [7]: soglasie_Color48x48,
  [10]: maks_Color48x48,
  [25]: yugoria_Color48x48,
  [27]: zurich_Color48x48,
  [32]: reso_Color48x48,
  [33]: vsk_Color48x48,
  [36]: renessans_Color48x48,
  [38]: sovcombank_Color48x48,
  [39]: intouch_Color48x48,
  [46]: sogaz_Color48x48,
  [47]: gaideColor48x48,
  [52]: gelios_rezerv_Color48x48,
  [55]: pari_Color48x48,
  [61]: osk_Color48x48,
  [75]: evro_polis_Color48x48,
  [98]: astro_volga_Color48x48,
  [107]: tinkoff_Color48x48,
  [127]: evroins_Color48x48,
  [144]: sber_Color48x48,
  [145]: energo_Color48x48,
};
