import React from 'react';
import { Offer } from './components/Offer';
import { StepTitle } from '../../../common/components/StepTitle/StepTitle';
import { observer } from 'mobx-react-lite';
import { useStepCompanyLink } from '@core/hooks/useStepCompanyLink';
import { Button } from '../../../common/components/Button/Button';
import s from './StepCompanyLinkPay.module.scss';
import { useStore } from '@core';
import { ErrorBoundary } from '../../../common/components/ErrorBoundary/ErrorBoundary';

export const StepCompanyLinkPay: React.FC = observer(() => {
  const store = useStore();

  const {
    onBack,
    link,
    companyId,
    selectedUpsales,
    activeCompany,
    getLinkPay,
    groupedUpSales,
    loadingUpSales,
    loading,
    error,
    totalSum,
    onCheckUpsale,
    date,
  } = useStepCompanyLink();

  return (
    <div className={s.root}>
      <StepTitle className={s.title} />
      <ErrorBoundary>
        {!!companyId && !!activeCompany && (
          <Offer
            loadingUpSales={loadingUpSales}
            onCheckUpsale={onCheckUpsale}
            selectedUpsales={selectedUpsales}
            groupedUpsales={groupedUpSales}
            totalSum={totalSum}
            date={date}
            error={error}
            link={link}
            companyId={companyId}
            loadingLinkPay={loading}
            getLinkPay={getLinkPay}
          >
            {({ link: l }) => {
              if (typeof l !== 'string') return null;
              return (
                <div className={s.alert}>
                  <div className={s.caption}>Ссылка на оплату готова</div>
                  После оплаты на странице страховой компании, вам на почту {
                    store?.form.getValueField('email').value
                  }{' '}
                  придет полис
                </div>
              );
            }}
          </Offer>
        )}
        <Button styleType="second" className={s.back} onClick={onBack}>
          Назад
        </Button>
      </ErrorBoundary>
    </div>
  );
});

StepCompanyLinkPay.displayName = 'StepCompanyLink';
