import React, { useState } from 'react';
import cn from 'classnames';

import { Container } from '../../../../../common/components/Container/Container';

import s from './Questions.module.scss';

export type QuestionsProps = {
  className?: string;
};

export const Questions = ({ className }: QuestionsProps) => {
  const [active, setActive] = useState<boolean[]>([true]);

  const questions = [
    {
      index: 0,
      header: 'Как получить заявленный кешбэк и на что я могу его потратить?',
      text: `Кешбэк в размере 10% от суммы оформленного полиса ОСАГО будет начислен на номер t2, указанный при оформлении в анкете. Если вы указали в анкете номер телефон не t2, а другого оператора, кешбэк не начисляется.

Максимальная сумма кешбэка на один абонентский номер – 1000 рублей за весь период акции.

Кешбэк можно потратить только на оплату услуг связи оператора.

Начисление кешбэка производится на следующий месяц после месяца оформления страховки.`,
    },
    {
      index: 1,
      header: 'Как получить купленный полис ОСАГО?',
      text: `После оплаты на адрес электронной почты высылается полис в формате PDF, который вам нужно просто распечатать на любом принтере или сохранить на мобильном устройстве. Документ сразу регистрируется в базе данных страховой компании и будет доступен в личном кабинете. Все сведения о владельце поступают также в ГИБДД и РСА.`,
    },
    {
      index: 2,
      header: 'Зачем покупать ОСАГО через маркетплейс, а не на сайте страховой?',
      text: `Если не сравнивать предложения разных страховых компаний, есть риск существенно переплатить за полис:
      самый первый вариант может оказаться и самым дорогим. При этом самостоятельный расчёт в 15-20 компаниях займёт
      несколько часов, а то и пару дней. Маркетплейс даёт возможность получить стоимость полиса в разных компаниях,
      введя данные только один раз. Таким образом пользователь экономит время и получает возможность выбрать
      предложение с привлекательным ценником.`,
    },
    {
      index: 3,
      header: 'За год не было аварий, но полис подорожал. Почему?',
      text: `<div>
              Подорожание полиса ОСАГО возникает по нескольким причинам:
              <ul>
                <li>резкий рост стоимости запчастей из‑за санкций и нарушения зарубежных поставок;</li>
                <li>страховые компании рассчитывают компенсацию по новой версии справочников;</li>
                <li>увеличение ключевой ставки Центробанком России увеличило среднюю стоимость ОСАГО;</li>
                <li>страховые компании используют базовые тарифы в пределах установленного коридора, поэтому цена может отличаться.</li>
              </ul>
            </div>`,
    },
    {
      index: 4,
      header: 'Электронный полис равнозначен бумажному?',
      text: `Да, электронный полис ОСАГО имеет такую же юридическую силу, как и бумажный. Единственная разница — в формате оформления. Водитель может выбрать, в каком виде получить полис: бумажная версия оформляется на бланке с водяными знаками, а электронная хранится в виде файла в мобильном приложении или личном кабинете на сайте страховой компании. Е‑ОСАГО удобнее, так как его оформление полностью автоматизировано и не требует посещения офиса.`,
    },
    {
      index: 5,
      header: 'Что делать, если положенной скидки за безопасное вождение нет?',
      text: `Если скидки за безопасное вождение нет, нужно убедиться в правильности оценки своего <a href="https://finuslugi.ru/kalkulyator_osago/stat_bonus_malus" target="_blank" rel="noreferrer nofollow">КБМ</a>.
Дата должна соответствовать началу действия нового полиса. Важно проверить личные данные в базе РСА: серию и номер водительского удостоверения, дату рождения. При внесении данных в несколько страховок КБМ может быть уменьшен, поэтому нужно выяснить, с какого полиса берется скидка.`,
    },
    {
      index: 6,
      header: 'Куда обращаться, если попал в аварию?',
      text: `<div>
              Для оформления ДТП необходимо вызвать сотрудника ГИБДД по номеру 102 с мобильного телефона и сфотографировать место происшествия. Самостоятельно европротокол оформляется в следующих случаях:
              <ul>
               <li>отсутствуют пострадавшие или погибшие;</li><li>в аварии участвовали только два транспортных средства;</li><li>оба водителя имеют полисы ОСАГО;</li><li>отсутствует ущерб третьим лицам;</li><li>ущерб причинен только транспортным средствам, участвовавшим в ДТП.</li>
              </ul>
              Участники должны не иметь разногласий по повреждениям автомобилей и обстоятельствам ДТП.
            </div>`,
    },
    {
      index: 7,
      header: 'Что делать, если я хочу вписать в полис еще одного водителя?',
      text: `Чтобы добавить водителя в полис ОСАГО, нужно обратиться в страховую компанию и предоставить данные водительского удостоверения человека, которого хотите вписать. В случае с электронным полисом это можно сделать через личный кабинет на сайте страховой компании, выбрав опцию «редактировать полис». Далее потребуется указать данные нового водителя: ФИО, номер и серию его удостоверения. Стоимость страховки при этом пересчитывается с учетом наименее выгодного КБМ среди всех водителей.`,
    },
    {
      index: 8,
      header: 'Что такое европротокол?',
      text: `Европротокол – процедура оформления дорожно‑транспортного происшествия без вызова сотрудника ГИБДД. Извещение об аварии в любом регионе России можно оформить в электронном виде. Для этого надо иметь подтвержденную учетную запись на портале Госуслуги Страховая компания получает данные об аварии через мобильное приложение со спутника и фотографии, которые водитель не сможет изменить. Оформление ДТП занимает около 30 минут.`,
    },
    {
      index: 9,
      header: 'Почему стоимость страховок отличается? Цены ведь во всех страховых одинаковые\n',
      text: `Стоимость страховки может отличаться из‑за множества факторов. Хотя базовые тарифы регулируются государством, каждая страховая компания применяет свои коэффициенты для расчета стоимости. Эти коэффициенты зависят от таких факторов, как возраст и стаж водителя, регион регистрации, марка и модель автомобиля, а также история вождения. Также на цену могут влиять дополнительные услуги, включенные в полис, и специальные предложения или акции от страховой компании.`,
    },
    {
      index: 10,
      header: 'Куда обращаться, если у меня остались вопросы по оформлению полиса ОСАГО?\n',
      text: `Вы всегда можете обратиться по вопросам страхования по контактам той страховой компании, которую вы выбрали, или по горячей линии ООО «МБ маркетплейс» 8 (800) 222-90-71 c указанием, что расчет полиса ОСАГО производился в мобильном приложении t2.`,
    },
  ];

  return (
    <div className={cn(s.root, className)}>
      <Container>
        {questions.map(({ index, header, text }) => (
          <div
            key={index}
            className={s.question}
            onClick={() =>
              setActive((prev) => {
                const newActive = [...prev];
                newActive[index] = !newActive[index];
                return newActive;
              })
            }
          >
            <div className={s.questionHeader}>
              <div className={s.questionHeaderText}>{header}</div>
              <div className={cn(s.icon, active[index] && s.active)}>
                {active[index] ? (
                  <svg width="10" height="2" viewBox="0 0 10 2" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M5.71429 0.25H4.28571H0V1.75H4.28571H5.71429L10 1.75V0.25L5.71429 0.25Z" fill="#1E1E1E" />
                  </svg>
                ) : (
                  <svg width="10" height="10" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                      d="M5.66634 0.333328H4.33301V4.33333H0.333008V5.66666H4.33301V9.66666H5.66634V5.66666H9.66634V4.33333H5.66634V0.333328Z"
                      fill="white"
                    />
                  </svg>
                )}
              </div>
            </div>
            <div
              className={cn(s.questionText, { [s.questionTextHidden]: !active[index] })}
              dangerouslySetInnerHTML={{ __html: text }}
            />
          </div>
        ))}
      </Container>
    </div>
  );
};
