import React, { useEffect } from 'react';
import { useScrollToError } from '@core';

import { Container } from '../../../common/components/Container/Container';
import { FormNavControl } from '../../../common/components/FormNavControl/FormNavControl';
import { DriversForm } from './components/DriversForm/DriversForm';
import { StepTitle } from '../../../common/components/StepTitle/StepTitle';
import { Steps } from '../../../common/components/Steps/Steps';
import s from '../StepCarData/StepCarData.module.scss';
import { ErrorBoundary } from '../../../common/components/ErrorBoundary/ErrorBoundary';

export const StepDriversPage = () => {
  const { scrollToTop } = useScrollToError();
  useEffect(() => {
    scrollToTop();
  }, [scrollToTop]);

  return (
    <Container className={s.root}>
      <Steps />
      <ErrorBoundary>
        <StepTitle>Водители</StepTitle>
        <DriversForm />
        <FormNavControl />
      </ErrorBoundary>
    </Container>
  );
};
