import React, { FC } from 'react';
import cn from 'classnames';
import s from './Link.module.scss';

export type LinkProps = React.AnchorHTMLAttributes<HTMLAnchorElement>;

declare global {
  interface Window {
    Android?: {
      browse?: (href: string) => void;
    };
  }
}

export const Link: FC<LinkProps> = ({ className, href, onClick, ...props }) => (
  <a
    target="_blank"
    rel="noreferrer noopener"
    {...props}
    onClick={(e) => {
      onClick?.(e);
      if (
        href &&
        'Android' in window &&
        window.Android &&
        'browse' in window.Android &&
        typeof window.Android.browse === 'function'
      ) {
        e.preventDefault();
        window.Android.browse(href);
      }
    }}
    className={cn(s.root, className)}
    href={href}
  />
);
