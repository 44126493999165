import React from 'react';
import { OSAGOWrapper, OSAGORouter } from '@core';

import { steps } from '../common/constants/steps';
import { routes } from '../routes/routes';
import { Layout } from '../common/components/Layout/Layout';

import '../scss/base/index.scss';
import { ExpiredStorage } from '@core/services/storage/expired-storage';
import { ErrorBoundary } from '../common/components/ErrorBoundary/ErrorBoundary';
/*
  Чтобы отключить компании, нужно передать в useEnabledPartners({ insuranceCompanies: ['alfa', ...] }) 
  из baseOsago, массив строк с названием на латиницы компаний, которые нужно включить
*/
const App: React.FC = () => (
  <ErrorBoundary>
    <React.StrictMode>
      <OSAGOWrapper>
        <Layout>
          <OSAGORouter
            draftStorage={new ExpiredStorage({ expire: 60 * 15 })}
            prefix="iframe/t2/"
            routes={routes}
            steps={steps}
            stepForOpenSavedOrder={3}
            idAgent="c6f80de04077660f3beb2fcd76ebf9fa"
            chat={{
              style: {
                widgetHeaderBackground: '#333',
              },
            }}
            upsales={{
              notRequired: true,
              required: true,
            }}
          />
        </Layout>
      </OSAGOWrapper>
    </React.StrictMode>
  </ErrorBoundary>
);

export default App;
