import React, { FC } from 'react';
import cn from 'classnames';
import s from './PandaSvg3.module.scss';

export type PandaSvg3Props = {
  className?: string;
};

export const PandaSvg3: FC<PandaSvg3Props> = ({ className }) => (
  <svg
    className={cn(s.root, className)}
    width="256"
    height="256"
    viewBox="0 0 128 128"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M45.943 111.774C42.148 111.711 37.5272 111.695 33.1446 111.568C30.6516 111.505 0.0211296 109.822 10.3266 106.363C11.5651 105.95 16.6305 105.506 19.711 105.157L20.505 105.077C27.9998 109.251 38.607 110.933 45.943 111.774Z"
      fill="white"
      stroke="white"
      strokeWidth="0.6"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M85.6249 15.6351C84.2752 12.3024 82.6396 10.6996 80.496 10.7154C78.1459 10.7313 77.7172 13.8101 77.479 16.1588C77.3043 17.8728 79.051 19.3804 80.0196 20.0628C85.6566 24.1096 88.2449 22.11 85.6249 15.6351Z"
      fill="black"
      stroke="white"
      strokeWidth="0.6"
      strokeMiterlimit="22.9256"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M123.623 78.6859C122.749 82.5741 122.035 86.367 121.892 90.144C121.654 96.4602 121.273 102.84 116.366 110.442C107.506 114.774 92.9604 110.553 84.0841 114.123C84.0841 114.123 83.7189 123.693 75.0014 122.519C66.395 121.36 61.3296 117.916 59.7893 112.457C59.7417 112.298 59.7099 112.14 59.6782 111.981C59.7258 111.981 57.6139 111.997 55.5338 112.013C38.6862 112.14 18.4088 109.553 9.29427 93.5401C2.75214 82.0662 3.22851 68.3705 2.99033 55.5793C2.86329 49.1044 2.38692 42.9469 1.16425 36.5831C-0.0425541 30.2827 0.306781 26.2994 3.75251 22.5382C6.61072 19.396 11.819 16.3966 15.8205 15.3492C19.1075 14.4922 21.918 12.1593 26.2054 12.0165C33.0333 11.7943 31.0167 21.6336 26.3165 24.1411C26.3165 24.1411 26.3483 28.299 25.5543 29.9177L27.9997 33.5837C27.9997 33.5837 27.6821 24.6331 31.2072 20.618C34.7323 16.6029 44.0374 17.7931 44.0374 17.7931C44.0374 17.7931 43.3387 11.5404 52.1992 11.7467C61.0596 11.9689 69.3643 15.8887 77.0656 23.6967C81.0989 19.999 85.6561 16.5235 90.6262 14.143C101.027 9.1599 115.223 16.7298 120.542 25.7122C124.734 32.806 125.29 49.279 125.909 57.7059C126.56 66.8311 125.528 70.2749 123.623 78.6859ZM63.1557 40.2173C63.6162 36.3292 60.6944 32.6791 57.2169 31.346C56.423 31.0445 52.8661 30.7271 52.8661 30.7271C50.4684 32.5839 47.6737 30.9651 46.5145 32.076C45.3712 33.2028 47.2767 35.4722 47.2767 35.4722C50.9448 40.0745 51.3259 43.915 51.3576 44.9783C51.3735 44.9783 51.6593 45.1211 51.6911 45.137C56.0419 47.3429 59.2812 46.835 63.5685 47.7079C63.0763 45.248 62.854 42.7565 63.1557 40.2173Z"
      fill="black"
      stroke="black"
      strokeWidth="0.6"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M44.0845 18.8885C44.0845 18.8885 44.8467 24.8715 47.2762 27.5694C47.2762 27.5694 33.3503 28.4422 32.874 22.5228C32.874 22.5069 32.0324 17.8253 44.0845 18.8885Z"
      fill="white"
      stroke="black"
      strokeWidth="0.6"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M59.7412 112.251L64.6954 112.283C74.2704 101.697 88.8791 61.6101 77.2239 52.0882C74.1275 49.676 70.6341 48.692 66.7756 49.1047C63.2822 46.5496 56.6448 47.6605 51.6906 45.1531C47.9908 43.2804 36.7008 41.0745 32.2547 40.519C35.1606 44.1215 39.7813 48.6762 46.1012 54.1989C50.325 59.5788 51.9287 64.5619 50.9442 69.1642C49.9439 73.6395 46.7522 76.8135 41.3851 78.6703C34.9541 80.7651 26.1572 80.416 14.9943 77.6229C8.5157 75.7978 4.60948 72.3382 3.25977 67.2598C3.6091 76.4168 4.75238 85.5578 9.3414 93.588C11.8344 97.9839 15.1849 101.348 19.0593 103.951C25.4427 108.22 33.3027 110.362 41.274 111.331C46.1012 111.918 50.976 112.092 55.5968 112.061C57.6769 112.045 59.7888 112.029 59.7412 112.029C59.7094 112.092 59.7253 112.172 59.7412 112.251Z"
      fill="white"
      stroke="black"
      strokeWidth="0.6"
      strokeMiterlimit="22.9256"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M13.9785 27.696L24.6492 24.4109"
      stroke="white"
      strokeWidth="0.6"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M14.6133 15.7461C14.6133 15.7461 15.8518 19.1422 14.6133 21.9036"
      stroke="white"
      strokeWidth="0.6"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M22.8242 12.5723C22.8242 12.5723 24.3804 15.6193 22.8242 19.4598"
      stroke="white"
      strokeWidth="0.6"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M9.56445 18.0791C12.1368 16.619 14.5822 15.6192 16.8846 15.0638"
      stroke="black"
      strokeWidth="0.6"
      strokeMiterlimit="22.9256"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M20.0605 13.7155C22.0772 12.7628 23.9191 12.2229 25.5864 12.0959"
      stroke="black"
      strokeWidth="0.6"
      strokeMiterlimit="22.9256"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M35.0987 43.7882C33.876 42.4552 34.6382 40.8682 35.9879 41.0904C34.5112 40.8364 33.2091 40.6301 32.2246 40.519C33.0662 41.5347 34.0189 42.6139 35.0987 43.7882Z"
      fill="black"
      stroke="black"
      strokeWidth="0.6"
      strokeMiterlimit="22.9256"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M3.69061 69.6083C3.69061 69.6243 3.69061 69.6243 3.69061 69.6083C3.72264 69.6243 3.75466 69.6404 3.78668 69.6564C3.75466 69.6564 3.73865 69.6564 3.70662 69.6724C3.69061 69.6724 3.65859 69.6884 3.64258 69.7044C3.64258 69.6884 3.6746 69.6404 3.69061 69.6083Z"
      fill="black"
    />
    <path
      d="M3.62587 69.2592C3.60999 69.2592 3.59411 69.2592 3.59411 69.2592C3.46708 69.291 3.37181 69.5609 3.27654 69.6721C3.14951 69.8308 3.07012 70.0373 3.086 70.3231C3.10188 70.4819 3.22891 70.593 3.37181 70.593H3.38769C3.54648 70.5772 3.67351 70.4501 3.65763 70.2913C3.65763 70.1802 3.67351 70.1008 3.70527 70.0532C3.72114 70.0373 3.73703 70.0214 3.76879 70.0055C3.78466 70.0055 3.78467 70.0055 3.80054 70.0055C3.81642 70.0055 3.8323 70.0055 3.84818 70.0214C3.91169 70.0373 3.99109 70.1008 4.0546 70.2119C4.10224 70.3072 4.19751 70.3549 4.30866 70.3549C4.3563 70.3549 4.40394 70.339 4.45157 70.3231C4.59448 70.2437 4.64212 70.069 4.56273 69.9261C4.41982 69.6721 4.22927 69.5291 4.02284 69.4656C3.91169 69.4021 3.7529 69.2592 3.62587 69.2592Z"
      fill="black"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M72.3962 28.5532C72.3962 28.5532 69.7921 47.2956 88.1958 53.1833C92.7848 54.5481 99.8033 55.5479 105.996 54.564C111.077 53.7546 115.396 50.898 117.731 47.3114C120.049 43.7724 119.176 33.7744 117.159 29.7435C113.634 22.6814 101.868 16.9364 95.4207 12.8261C95.3096 12.7468 95.2143 12.7944 95.0714 12.8261C93.5311 13.0642 92.0385 13.5086 90.6094 14.191C83.7338 17.476 77.3822 22.8877 72.3962 28.5532Z"
      fill="white"
      stroke="white"
      strokeWidth="0.6"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M107.121 18.064C105.597 17.5876 104.755 17.5083 103.771 17.413C103.644 17.3971 103.517 17.3971 103.39 17.3812C102.628 17.3018 101.802 17.2701 100.944 17.2701C100.468 17.2701 99.9758 17.2859 99.4677 17.3018C99.4518 17.3018 99.436 17.3018 99.4201 17.3018C99.3089 17.3018 99.2295 17.2859 99.166 17.2701C99.2295 17.2542 99.3089 17.2224 99.4042 17.2224C99.9441 17.1907 100.468 17.1907 100.976 17.1907C101.897 17.1907 102.771 17.2383 103.596 17.3177C104.882 17.4447 106.057 17.6829 107.105 18.0005C107.121 18.0005 107.137 18.0164 107.153 18.0164C107.137 18.0323 107.121 18.0481 107.121 18.064Z"
      fill="black"
    />
    <path
      d="M100.977 16.8572C100.469 16.8572 99.945 16.8731 99.3893 16.8889C99.0241 16.9048 98.7382 17.0795 98.77 17.2859C98.7859 17.4765 99.0717 17.6194 99.421 17.6194C99.4369 17.6194 99.4528 17.6194 99.4845 17.6194C99.9927 17.6035 100.469 17.5876 100.945 17.5876C101.803 17.5876 102.597 17.6194 103.359 17.6988C104.518 17.8099 105.392 17.8417 107.186 18.4133C107.249 18.4292 107.297 18.4451 107.313 18.4451C107.408 18.4451 107.281 18.318 107.424 18.1751C107.615 18.0005 107.503 17.7782 107.202 17.6829C106.122 17.3494 104.931 17.1112 103.629 16.9842C102.787 16.9048 101.898 16.8572 100.977 16.8572Z"
      fill="black"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M88.211 15.4606C95.5153 18.1585 103.199 17.6481 103.199 17.6481C103.199 17.6481 102.2 17.1587 102.423 17.2539L95.4359 12.7944L95.3724 12.7468C95.2771 12.7627 95.1819 12.7786 95.1025 12.7944C93.5622 13.0325 92.0696 13.4768 90.6405 14.1593C89.4972 14.6988 89.0526 14.9686 88.211 15.4606Z"
      fill="black"
      stroke="white"
      strokeWidth="0.6"
      strokeMiterlimit="22.9256"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M88.8312 27.1726C89.1329 27.1567 89.3869 26.9504 89.4822 26.6647C92.2769 18.698 89.5934 17.5078 85.7507 19.7296C81.0822 22.4275 74.9053 28.5532 80.4312 33.8062C80.7329 34.0442 81.3046 33.8697 81.3998 33.4888C82.0032 31.3622 83.1783 29.7276 84.6709 28.6643C85.8777 27.7915 87.3068 27.2837 88.8312 27.1726Z"
      fill="black"
      stroke="black"
      strokeWidth="0.6"
      strokeMiterlimit="22.9256"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M81.7969 27.1725C81.7969 27.1725 84.7662 22.6496 87.6403 26.5695"
      stroke="white"
      strokeWidth="0.6"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M96.024 24.8397C95.8176 26.0934 95.6112 27.363 95.4841 28.3946C95.1983 30.6005 95.4206 33.346 96.3257 35.5677C97.5008 38.4561 99.8191 40.3605 102.884 40.3446C105.504 40.3287 108.552 38.8687 109.68 36.4406C111.982 31.4733 105.472 23.1416 101.185 21.3007C97.4055 19.682 96.6433 21.0944 96.024 24.8397Z"
      fill="black"
      stroke="black"
      strokeWidth="0.6"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M97.707 31.1239C97.707 31.1239 99.4061 27.7278 101.963 28.5213C104.503 29.2989 104.154 32.3618 104.154 32.3618"
      stroke="white"
      strokeWidth="0.6"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M81.1777 38.075C81.1777 38.075 81.3683 41.3125 82.9244 42.9788"
      stroke="black"
      strokeWidth="0.6"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M85.3225 29.0767C84.7827 28.9339 84.3063 29.5211 84.5286 30.0448C85.0208 31.1874 85.8624 32.1237 86.8469 32.8537C87.5615 33.3774 87.6409 33.3774 88.5142 33.2346C89.721 33.0283 90.9119 32.5839 91.8964 31.7904C92.341 31.4254 92.1981 30.6954 91.6423 30.5843L85.3225 29.0767Z"
      fill="black"
      stroke="black"
      strokeWidth="0.6"
      strokeMiterlimit="22.9256"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M85.084 34.9804C85.6239 35.3296 86.3702 35.4406 87.3229 34.8535C87.3229 34.8535 89.7683 38.0592 93.3887 34.9487C93.3887 34.9487 91.1498 38.3924 87.577 38.6464C85.2428 38.8051 84.5918 37.1546 84.9729 35.0122C84.9887 34.9011 85.0205 34.9487 85.084 34.9804Z"
      fill="black"
      stroke="black"
      strokeWidth="0.6"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M87.1017 38.6307C86.1172 38.6307 85.8155 38.2975 85.752 38.0118C85.5615 37.3135 86.4983 36.885 86.6571 36.8216C86.7841 36.7581 87.4669 36.4724 89.2295 36.3931C89.3565 36.3931 89.4836 36.3772 89.5947 36.3772C90.1346 36.3772 90.6586 36.4248 90.7856 36.8057C90.865 37.0437 90.7221 37.2659 90.5474 37.3929C90.3728 37.5516 89.2295 38.6148 87.3717 38.6148H87.3399C87.2764 38.6307 87.197 38.6307 87.1017 38.6307Z"
      fill="white"
    />
    <path
      d="M89.5785 36.6944C90.96 36.6944 90.3249 37.1546 90.3249 37.1546C90.3249 37.1546 89.2133 38.2972 87.3555 38.2972C87.3396 38.2972 87.3237 38.2972 87.3079 38.2972C87.3079 38.2972 87.2285 38.2972 87.1014 38.2972C86.768 38.2972 86.1487 38.2496 86.0534 37.9164C85.9264 37.4561 86.7839 37.107 86.7839 37.107C86.7839 37.107 87.4031 36.7896 89.2292 36.7102C89.3562 36.6944 89.4674 36.6944 89.5785 36.6944ZM89.5785 36.0596C89.4674 36.0596 89.3404 36.0596 89.1974 36.0754C87.4349 36.1548 86.7045 36.4404 86.5139 36.5357C85.8946 36.8055 85.2436 37.3609 85.4341 38.0909C85.5453 38.4877 85.9105 38.9479 87.1014 38.9479C87.1967 38.9479 87.292 38.9479 87.3237 38.9479H87.3396C89.261 38.9479 90.4995 37.8687 90.7218 37.6466C91.0235 37.4085 91.1506 37.0435 91.0553 36.7261C90.8489 36.0596 89.9914 36.0596 89.5785 36.0596Z"
      fill="black"
    />
    <path
      d="M83.9414 33.5682C84.4178 34.5209 85.1006 35.0925 85.9739 35.2672"
      stroke="black"
      strokeWidth="0.6"
      strokeMiterlimit="22.9256"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M87.3242 34.8535C87.4354 34.187 87.5783 33.5522 87.7529 32.965"
      stroke="black"
      strokeWidth="0.6"
      strokeMiterlimit="22.9256"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M81.9883 31.9177C83.2904 29.1246 85.7516 27.3948 88.8162 27.1567"
      stroke="black"
      strokeWidth="0.6"
      strokeMiterlimit="22.9256"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M75.5369 45.7304L83.587 62.1394C84.1299 63.2434 85.1782 64.0105 86.3951 64.1976L104.405 66.8358C107.475 67.2848 108.692 71.0643 106.464 73.2347L93.4342 86.0139C92.5543 86.8746 92.1612 88.1095 92.3671 89.3444L95.4374 107.381C95.9616 110.45 92.7603 112.788 90.027 111.329L73.9082 102.816C72.8223 102.236 71.5306 102.236 70.426 102.816L55.3556 110.786C54.2136 111.385 53.989 111.479 52.6223 111.497C50.4132 111.535 48.485 109.814 48.8968 107.362L51.9671 89.3256C52.173 88.1095 51.7799 86.8746 50.9 85.9952L37.8701 73.216C35.661 71.0456 36.8779 67.2661 39.9482 66.817L57.9578 64.1789C59.1747 64.0105 60.2231 63.2246 60.766 62.1207L68.816 45.7117C70.2014 42.9426 74.1515 42.9426 75.5369 45.7304Z"
      fill="#A7FC00"
      stroke="black"
      strokeWidth="0.6"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M48.9155 107.381L51.9858 89.3445C52.1917 88.1283 51.7986 86.8934 50.9187 86.0141L39.7422 75.0685L49.4959 87.0992C50.226 88.0161 50.4694 89.2322 50.1511 90.3549L45.2836 107.082L44.0855 111.217C44.8396 111.424 49.0984 111.848 51.9858 111.748C48.9155 110.947 48.616 109.103 48.9155 107.381Z"
      fill="black"
      stroke="black"
      strokeWidth="0.6"
      strokeMiterlimit="22.9256"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M59.6982 108.485L55.3549 110.787C54.6623 111.161 53.9137 111.423 53.4082 111.535C53.689 111.535 54.344 111.498 54.6248 111.498C57.0773 111.479 59.5672 111.46 59.511 111.46C59.5297 111.554 59.5484 111.647 59.5672 111.741H60.4096L59.8854 111.535C59.8854 111.535 59.5485 110.431 59.6982 108.485Z"
      fill="black"
      stroke="black"
      strokeWidth="0.6"
      strokeMiterlimit="22.9256"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M45.1722 107.531C44.4421 109.758 41.5917 111.083 38.9707 111.008C40.0752 111.158 42.3266 111.049 43.3376 111.161C43.5996 111.18 43.843 111.198 44.1051 111.217L45.1722 107.531Z"
      fill="black"
      stroke="black"
      strokeWidth="0.6"
      strokeMiterlimit="22.9256"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M110.825 84.3837C106.474 84.2567 101.806 83.7644 97.0898 83.7009"
      stroke="white"
      strokeWidth="0.6"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M59.9961 112.076C59.9961 112.076 57.2331 103.253 72.4928 92.5882C84.1003 84.4628 91.2617 83.9074 106.156 85.1294L102.186 108.506L72.858 117.155L59.9961 112.076Z"
      fill="black"
      stroke="black"
      strokeWidth="0.6"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M72.1909 122.059L75.0015 113.156"
      stroke="white"
      strokeWidth="0.6"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M63.9186 118.234C63.9186 118.234 63.6169 112.204 65.967 109.585"
      stroke="white"
      strokeWidth="0.6"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M61.5837 116.139C64.7437 120.011 69.6979 122.186 76.4623 122.63"
      stroke="black"
      strokeWidth="0.6"
      strokeMiterlimit="22.9256"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M98.582 11.5882L105.537 10.6836C105.537 10.6836 114.175 14.6193 118.161 20.5547C119.542 22.6178 119.86 24.3476 120.511 26.6963L98.582 11.5882Z"
      fill="white"
      stroke="black"
      strokeWidth="0.6"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M115.937 25.8555L87.3863 7.68443C87.2275 7.58921 87.1799 7.3829 87.291 7.2242L88.3708 5.52612C88.4661 5.36742 88.6725 5.31982 88.8313 5.43091L120.335 25.4428C120.605 26.76 120.7 28.1566 120.589 29.6325L115.937 25.8555Z"
      fill="white"
      stroke="black"
      strokeWidth="0.6"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M116.589 24.6653C116.589 24.6653 121.035 19.9361 125.179 21.3327C128.895 22.5864 127.339 27.3156 125.703 29.6326C124.544 31.2831 122.21 33.0605 117.716 32.0448C110.031 30.2991 114.636 26.8236 116.589 24.6653Z"
      fill="black"
      stroke="black"
      strokeWidth="0.6"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M77.0656 23.6967C69.3643 15.8887 61.0596 11.9689 52.1992 11.7467C43.3387 11.5404 44.0374 17.7931 44.0374 17.7931C44.0374 17.7931 34.7323 16.6029 31.2072 20.618C27.6821 24.6331 27.9997 33.5837 27.9997 33.5837L25.5543 29.9177C26.3483 28.299 26.3165 24.1411 26.3165 24.1411C31.0167 21.6336 33.0333 11.7943 26.2054 12.0165C21.918 12.1593 19.1075 14.4922 15.8205 15.3492C11.819 16.3966 6.61072 19.396 3.75251 22.5382C0.306781 26.2994 -0.0425541 30.2827 1.16425 36.5831C2.38692 42.9469 2.86329 49.1044 2.99033 55.5794C3.07756 60.2639 2.84486 65.0903 3.50398 69.7898L4.39669 71.508M51.3203 45.5864C51.2885 44.5231 50.9448 40.0745 47.2767 35.4722C47.2767 35.4722 45.3712 33.2028 46.5145 32.076C47.6737 30.9651 50.4684 32.5839 52.8661 30.7271C52.8661 30.7271 56.423 31.0445 57.2169 31.346C60.6944 32.6791 63.6162 36.3292 63.1557 40.2173C62.854 42.7565 63.1158 45.8057 63.608 48.2655M38.0906 110.229C44.6409 112.344 50.0165 112.054 55.5338 112.013C57.6139 111.997 59.7258 111.981 59.6782 111.981C59.7099 112.14 59.7417 112.298 59.7893 112.457C61.3296 117.916 66.395 121.36 75.0014 122.519C83.7189 123.693 84.0841 114.123 84.0841 114.123C92.9604 110.553 107.506 114.774 116.366 110.442C121.273 102.84 121.654 96.4602 121.892 90.144C122.035 86.3669 122.749 82.574 123.623 78.6859C125.528 70.2749 126.56 66.8311 125.909 57.7059C125.433 51.2272 124.995 39.9927 122.912 31.9208"
      stroke="white"
      strokeWidth="0.6"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M118.006 22.6545C119.686 21.4549 122.761 20.5181 125.178 21.3328C128.894 22.5865 127.338 27.3157 125.702 29.6327C125.135 30.4405 124.286 31.2788 123.04 31.805"
      stroke="white"
      strokeWidth="0.6"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
