import React from 'react';

import { FormWrapper } from '../../../../common/components/FormWrapper/FormWrapper';
import { FormRow } from '../../../../common/components/FormRow/FormRow';
import { InputMask } from '../../../../common/components/Input/InputMask/InputMask';
import { InputDefault } from '../../../../common/components/Input/InputDefault/InputDefault';

import s from './index.module.scss';

export const ContactForm: React.FC = () => (
  <div className={s.formWrapper}>
    <FormWrapper title="Контактные данные">
      <FormRow columns>
        <InputMask
          validOnInput
          view="fullWidth"
          placeholder="Номер телефона"
          name="phone"
          mask="phone"
          inputMode="numeric"
        />
        <InputDefault view="fullWidth" placeholder="Email" name="email" />
      </FormRow>
    </FormWrapper>
  </div>
);
