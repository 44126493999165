import React from 'react';

import { driverIndexToString, LICENSE_FOREIGN_LIST, useStore, observer } from '@core';

import { FormWrapper } from '../../../../../common/components/FormWrapper/FormWrapper';
import { FormRow } from '../../../../../common/components/FormRow';
import { Checkbox } from '../../../../../common/components/Checkbox';
import { Button } from '../../../../../common/components/Button';

import { InputDefault } from '../../../../../common/components/Input/InputDefault';
import { InputDate } from '../../../../../common/components/Input/InputDate';
import { InputMask } from '../../../../../common/components/Input/InputMask';
import { SelectDefault } from '../../../../../common/components/Input/SelectDefault';
import remove from '@assets/images/icons/remove.svg';

import { Switch } from '../../../../../common/components/Input/Switch';
import { transformHumanName } from '@utils/getValueByEventHumanName';
import s from './DriverFormItem.module.scss';

export const DriverFormItem: React.FC<{ index: number }> = observer(({ index }) => {
  const store = useStore();
  if (!store) {
    return null;
  }
  const driver = store.form.drivers[index];
  if (driver === undefined) {
    return null;
  }

  const title = (
    <div className={s.title}>
      <div>{driverIndexToString(index + 1)}</div>
      {index != 0 && (
        <Button
          className={s.buttonDelete}
          onClick={(e) => {
            e.preventDefault();
            store.form.removeDriver(index);
          }}
        >
          <img alt="add" src={remove} />
        </Button>
      )}
    </div>
  );

  return (
    <div>
      <FormWrapper title={title}>
        <FormRow columns>
          <InputDefault
            view="fullWidth"
            transform={transformHumanName}
            placeholder="Фамилия"
            index={index}
            name="lastname"
            validationSubscribe={false}
          />
          <InputDefault
            transform={transformHumanName}
            view="fullWidth"
            placeholder="Имя"
            index={index}
            name="firstname"
            validationSubscribe={false}
          />
        </FormRow>
        <FormRow columns>
          <InputDefault
            transform={transformHumanName}
            view="fullWidth"
            placeholder="Отчество"
            index={index}
            name="middlename"
            validationSubscribe={false}
          />
          <InputDate
            view="fullWidth"
            placeholder="Дата рождения"
            index={index}
            name="birthdate"
            validationSubscribe={false}
          />
        </FormRow>
        <FormRow columns>
          <SelectDefault
            view="fullWidth"
            placeholder="Документ"
            index={index}
            name="licenseForeign"
            options={LICENSE_FOREIGN_LIST}
            validationSubscribe={false}
          />
          {driver.licenseForeign.value == 0 && (
            <InputMask
              view="fullWidth"
              placeholder="Серия и номер"
              mask="passport"
              index={index}
              validationSubscribe={false}
              name="licenseSerial"
            />
          )}
        </FormRow>
        {driver.licenseForeign.value == 1 && (
          <FormRow columns>
            <InputDefault
              validationSubscribe={false}
              view="fullWidth"
              placeholder="Серия (если есть)"
              index={index}
              name="licenseSerialForeign0"
            />
            <InputDefault
              validationSubscribe={false}
              view="fullWidth"
              placeholder="Номер"
              index={index}
              name="licenseNumberForeign0"
            />
          </FormRow>
        )}
        {driver.expYearOnly.value === 0 && (
          <FormRow columns>
            <InputDate
              validationSubscribe={false}
              view="fullWidth"
              placeholder="Дата начала стажа"
              index={index}
              name="expdate"
            />
            <div />
          </FormRow>
        )}
        {driver.expYearOnly.value === 1 && (
          <FormRow columns>
            <InputDefault
              validationSubscribe={false}
              view="fullWidth"
              placeholder="Год начала стажа"
              index={index}
              validOnInput
              name="expyear"
            />
            <div />
          </FormRow>
        )}
        <FormRow columns>
          <Checkbox validationSubscribe={false} index={index} name="expYearOnly">
            В правах есть только год
          </Checkbox>
          <Checkbox validationSubscribe={false} index={index} name="prevLicense">
            Меняли ВУ в текущем году
          </Checkbox>
        </FormRow>
        {driver.prevLicense.value === 1 && (
          <div>
            <FormRow>
              <SelectDefault
                validationSubscribe={false}
                view="fullWidth"
                placeholder="Предыдущий документ"
                index={index}
                name="prevLicenseForeign"
                options={LICENSE_FOREIGN_LIST}
              />
            </FormRow>
            <FormRow columns>
              {driver.prevLicenseForeign.value == 0 && (
                <InputMask
                  validationSubscribe={false}
                  view="fullWidth"
                  placeholder="Серия и номер предыдущего ВУ"
                  mask="passport"
                  index={index}
                  name="prevLicenseSerial"
                />
              )}
              {driver.prevLicenseForeign.value == 1 && (
                <>
                  <InputDefault
                    validationSubscribe={false}
                    view="fullWidth"
                    placeholder="Серия (если есть)"
                    index={index}
                    name="prevLicenseSerialForeign0"
                  />
                  <InputDefault
                    validationSubscribe={false}
                    view="fullWidth"
                    placeholder="Номер"
                    index={index}
                    name="prevLicenseNumberForeign0"
                  />
                </>
              )}
              <InputDate
                validationSubscribe={false}
                view="fullWidth"
                placeholder="Дата выдачи предыдущего ВУ"
                index={index}
                name="prevLicenseDate"
              />
            </FormRow>
            <FormRow>
              <Switch index={index} name="prevLicenseName">
                ФИО предыдущего ВУ отличается от текущего
              </Switch>
            </FormRow>
            {driver.prevLicenseName.value == 1 && (
              <div>
                <FormRow>
                  <InputDefault
                    validationSubscribe={false}
                    view="fullWidth"
                    transform={transformHumanName}
                    placeholder="Фамилия"
                    index={index}
                    name="prevLicenseLastname"
                  />
                </FormRow>
                <FormRow columns>
                  <InputDefault
                    validationSubscribe={false}
                    view="fullWidth"
                    transform={transformHumanName}
                    placeholder="Имя"
                    index={index}
                    name="prevLicenseFirstname"
                  />
                  <InputDefault
                    validationSubscribe={false}
                    view="fullWidth"
                    transform={transformHumanName}
                    placeholder="Отчество"
                    index={index}
                    name="prevLicenseMiddlename"
                  />
                </FormRow>
              </div>
            )}
          </div>
        )}
      </FormWrapper>
    </div>
  );
});
