import React, { FC, useReducer } from 'react';
import cn from 'classnames';
import s from './SuccessScreen.module.scss';
import { Container } from '../../../../../common/components/Container/Container';
import { Button } from '../../../../../common/components/Button';
import { observer } from 'mobx-react-lite';
import { useStore } from '@core';
import { Questions } from '../Questions/Questions';
import { SuccessBlock } from './components/SuccessBlock/SuccessBlock';
import { URL_INDEX } from '../../../../../common/constants/url';

export type SuccessScreenProps = {
  className?: string;
};

export const SuccessScreen: FC<SuccessScreenProps> = observer(({ className }) => {
  const store = useStore();
  const email = store?.form.getValueField('email').value;
  const [opened, toggle] = useReducer((v) => !v, false);
  const toHome = () => window.location.replace(URL_INDEX);
  return (
    <Container className={cn(s.root, className)}>
      <div className={s.title}>{opened ? 'Остались вопросы?' : 'Полис оплачен'}</div>
      {opened ? <Questions className={s.questions} /> : <SuccessBlock email={email} />}
      <div className={s.btns}>
        <Button className={s.btn} onClick={toggle}>
          {opened ? 'Нет вопросов' : 'У меня остались вопросы'}
        </Button>
        <Button onClick={toHome} styleType="second" className={s.btn}>
          На главную
        </Button>
      </div>
    </Container>
  );
});

SuccessScreen.displayName = 'SuccessScreen';
