import React from 'react';
import cn from 'classnames';
import cover320 from './cover.320.jpg';
import cover320x2 from './cover.320@2x.jpg';
import cover375 from './cover.375.jpg';
import cover375x2 from './cover.375@2x.jpg';
import cover480 from './cover.480.jpg';
import cover480x2 from './cover.480@2x.jpg';
import cover667 from './cover.667.jpg';
import cover667x2 from './cover.667@2x.jpg';
import cover812 from './cover.812.jpg';
import cover812x2 from './cover.812@2x.jpg';
import cover932 from './cover.932.jpg';
import cover932x2 from './cover.932@2x.jpg';
import coverFull from './cover.jpg';
import s from './Cover.module.scss';

export type CoverProps = {
  className?: string;
};

const sizes =
  '(max-width: 320px) 320px, (max-width: 375px) 375px, (max-width: 480px) 480px, (max-width: 667px) 667px, (max-width: 812px) 812px, (max-width: 932px) 932px, 1920px';

const srcSet = `${cover320} 320w,
     ${cover320x2} 640w,
     ${cover320} 960w,
     ${cover375} 375w,
     ${cover375x2} 750w,
     ${cover480} 480w,
     ${cover480x2} 960w,
     ${cover667} 667w,
     ${cover667x2} 1334w,
     ${cover812} 812w,
     ${cover812x2} 1724w,
     ${cover932} 932w,
     ${cover932x2} 1864w,
     ${coverFull} 1920w`;

export const Cover = ({ className }: CoverProps) => (
  <img sizes={sizes} className={cn(s.root, className)} alt="" src={coverFull} srcSet={srcSet} />
);
