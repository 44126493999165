import React, { FC } from 'react';
import cn from 'classnames';
import s from './WelcomeComponent.module.scss';
import { Button } from '../../../../../common/components/Button';

export type WelcomeComponentProps = {
  className?: string;
  onClick: () => void;
};

export const WelcomeComponent: FC<WelcomeComponentProps> = ({ className, onClick }) => (
  <div className={cn(s.root, className)}>
    <ul className={s.list}>
      <li>
        <div className={s.point}>1</div>
        <div>Заполните заявку за 3 минуты</div>
      </li>
      <li>
        <div className={s.point}>2</div>
        <div>Получите предложения от 20 страховых компаний</div>
      </li>
      <li>
        <div className={s.point}>3</div>
        <div>Оформите полис на лучших условиях</div>
      </li>
      <li>
        <div className={s.point}>4</div>
        <div>Получите полис на электронную почту после оплаты</div>
      </li>
    </ul>
    <div className={s.title}>Вам понадобятся данные:</div>
    <ul className={s.items}>
      <li>паспорта</li>
      <li>водительского удостоверения</li>
      <li>свидетельства о регистрации авто</li>
    </ul>
    <Button className={s.btn} styleType="accent" onClick={onClick}>
      Оформить заявку
    </Button>
  </div>
);
