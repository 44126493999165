import React, { useEffect } from 'react';

import { observer, useStore, useVehicleByLicensePlate, PERIOD_OF_USE } from '@core';

import { PreliminaryPageForm } from './components/PreliminaryPageForm/PreliminaryPageForm';
import { PreliminaryCost } from './components/PreliminaryCost/PreliminaryCost';
import { LicenseWarning } from './components/LicenseWarning/LicenseWarning';

import { Container } from '../../../common/components/Container/Container';
import { Skeleton } from '../../../common/components/Skeleton';

import { SelectDefault } from '../../../common/components/Input/SelectDefault';
import { ENABLE_PRELIMINARY_COST } from '@core/common/constants/preliminaryCost';
import { Switch } from '../../../common/components/Input/Switch';
import { StepTitle } from '../../../common/components/StepTitle/StepTitle';
import { usePreliminaryCost } from '@core/store/utils/usePreliminaryCost';
import { FormNavControl } from '../../../common/components/FormNavControl/FormNavControl';
import { Steps } from '../../../common/components/Steps/Steps';
import s from './StepPreliminaryPage.module.scss';
import { ErrorBoundary } from '../../../common/components/ErrorBoundary/ErrorBoundary';

export const StepPreliminaryPage: React.FC = observer(() => {
  usePreliminaryCost();
  const store = useStore();

  const { getVehicleByLicensePlate, loading } = useVehicleByLicensePlate();
  const preliminaryLoading = store.form.dataByForm.preliminaryData.loading;

  useEffect(() => {
    // FIXME: Заменить на store.form.vehicleByLicensePlateLoading
    getVehicleByLicensePlate();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const brand = store.form.getValueField('brand');
  const model = store.form.getValueField('model');
  const enablePeriod = store.form.getValueField('enablePeriod');

  const renderPreliminaryCost = () => {
    if (!ENABLE_PRELIMINARY_COST) return null;
    return loading || preliminaryLoading ? (
      <Skeleton className={s.skeleton} />
    ) : (
      <PreliminaryCost className={s.preliminaryCost} />
    );
  };

  return (
    <Container className={s.root}>
      <Steps />
      <ErrorBoundary>
        {renderPreliminaryCost()}
        {(!brand || !model) && <LicenseWarning />}
        <StepTitle>Автомобиль</StepTitle>
        <div className={s.preliminaryPage}>
          <div>
            <PreliminaryPageForm loading={loading} />
            <div className={s.preliminarySwitchers}>
              <div className={s.switcherBox}>
                <Switch name="enablePeriod">Полис на несколько месяцев</Switch>
                {enablePeriod.value ? (
                  <div className={s.preliminaryPeriodInputBox}>
                    <SelectDefault
                      view="fullWidth"
                      placeholder="Период использования"
                      inputMode="none"
                      name="usePeriod"
                      options={PERIOD_OF_USE.map((item) => {
                        if (item.value == '6') {
                          return { value: item.value, title: '6  месяцев' };
                        }
                        return { value: item.value, title: item.title };
                      })}
                    />
                  </div>
                ) : null}
              </div>
            </div>
            <FormNavControl />
          </div>
        </div>
      </ErrorBoundary>
    </Container>
  );
});
