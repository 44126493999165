import React from 'react';
import cn from 'classnames';
import { CompanyLogo, PartnerLogoType } from '../../../../../../../partners/src/components/CompanyLogo';
import { upsales } from '@core/constants/texts';
import { PolicyOfferUpsale } from '@core/features/upsale/upsale.types';
import { UpsalesProps } from '@core/common/components/_factories/createUpsales';
import { observer } from 'mobx-react-lite';
import { useTableRow } from '@core/hooks/useTableRow';
import { PolicyOffer } from '@core/features/offer/offer.types';
import s from './createTableOffersRow.module.scss';
import { getBeautifulPrice } from '@utils/format';

export type TableOffersRowProps = {
  withoutUpsales?: boolean;
  special?: boolean;
  isTopOffer?: boolean;
  className?: string;
  date: string;
  companyId: number;
  offer?: PolicyOffer;
  companyName: string;
  upsaleList?: PolicyOfferUpsale[];
  hasExtraUpsales?: boolean;
  hasExtraRequiredUpsales?: boolean;
  loading?: boolean;
  error?: string | null;
  price?: number;
  logoType?: PartnerLogoType;
  setActiveOffer?: (id: string) => Promise<boolean>;
};

export type TableOffersRowStyle = {
  root?: string;
  companyName?: string;
  wrapper?: string;
  upsaleText?: string;
  upsale?: string;
  logoWrapper?: string;
  btn?: string;
  error?: string;
  price?: string;
  date?: string;
  logo?: string;
  bottom?: string;
  separatedPrice?: string;
};

export const createTableOffersRow = <T extends TableOffersRowProps>(
  {
    Upsales,
    Skeleton,
    Button,
    btnProps,
    separatedPrice,
    skeletonStyle = { width: 230, height: 20 },
  }: {
    Upsales: React.ComponentType<UpsalesProps>;
    Skeleton: React.ComponentType<React.HTMLAttributes<HTMLDivElement>>;
    skeletonStyle?: { width: number; height: number };
    Button: React.ComponentType<{
      children?: React.ReactNode;
      onClick?: (e: React.MouseEvent<HTMLButtonElement>) => void;
      className?: string;
    }>;
    separatedPrice?: boolean;
    btnProps?: Record<string, unknown>;
  },
  style?: TableOffersRowStyle
): React.FC<T> => {
  const cls = {
    root: cn(s.root, style?.root),
    companyName: cn(s.companyName, style?.companyName),
    wrapper: cn(s.wrapper, style?.wrapper),
    logoWrapper: cn(s.logoWrapper, style?.logoWrapper),
    btn: cn(s.btn, style?.btn),
    upsaleText: cn(s.upsaleText, style?.upsaleText),
    upsale: cn(s.upsale, style?.upsale),
    error: cn(s.error, style?.error),
    price: cn(s.price, style?.price),
    date: cn(s.date, style?.date),
    logo: cn(s.logo, style?.logo),
    bottom: cn(s.bottom, style?.bottom),
    separatedPrice: cn(s.separatedPrice, style?.separatedPrice),
  };
  return observer(
    ({
      className,
      date,
      companyId,
      companyName,
      hasExtraUpsales,
      hasExtraRequiredUpsales,
      upsaleList,
      logoType = 'colorful',
      loading = false,
      error = '',
      price = 0,
      setActiveOffer,
    }) => {
      const { selectedUpsales, onCheckUpsale, groupedUpSales, totalSum, onPay } = useTableRow({
        setActiveOffer,
        upsaleList,
        price,
        companyId,
      });

      return (
        <div className={cn(cls.root, className)}>
          <div className={cls.wrapper}>
            <div className={cls.logoWrapper}>
              <CompanyLogo className={cls.logo} type={logoType} companyId={companyId} />
              <div className={cls.companyName}>{companyName}</div>
            </div>
            {!!price && !!date && <div className={cls.date}>с {date}</div>}
            {(() => {
              if (error) return <div className={cls.error}>{error}</div>;
              if (loading) return <Skeleton style={skeletonStyle} />;
              if (price) {
                const sum = `${getBeautifulPrice(totalSum) || ''} ₽`;
                if (separatedPrice) {
                  return (
                    <>
                      <div className={cls.separatedPrice}>{sum}</div>
                      <div className={cls.bottom}>
                        <Button {...(btnProps || {})} className={cls.btn} onClick={onPay}>
                          Оформить
                        </Button>
                      </div>
                    </>
                  );
                }
                return (
                  <div className={cls.bottom}>
                    <Button {...(btnProps || {})} className={cls.btn} onClick={onPay}>
                      {sum}
                    </Button>
                  </div>
                );
              }

              return null;
            })()}
          </div>
          <div className={cls.upsaleText}>{hasExtraUpsales && upsales.enable}</div>
          <div className={cls.upsaleText}>{hasExtraRequiredUpsales && upsales.required}</div>
          <Upsales
            className={cls.upsale}
            onCheckUpsale={onCheckUpsale}
            selectedUpsales={selectedUpsales}
            groupedUpsales={groupedUpSales}
          />
        </div>
      );
    }
  );
};
