import React, { useState } from 'react';

import { LicensePlateBlock } from '../LicensePlateBlock/LicensePlateBlock';
import s from './MainScreen.module.scss';
import { Cover } from '../Cover';
import { WelcomeComponent } from '../WelcomeComponent/WelcomeComponent';
import { CacheBackLink } from '../../../../../common/components/CacheBackLink';
import { PrivacyPolicyBlock } from '../../../../../common/components/PrivacyPolicyBlock';

export const MainScreen: React.FC = () => {
  const [first, setFirst] = useState(true);
  return (
    <div className={s.root}>
      <Cover />
      <div className={s.base}>
        <div className={s.wrapper}>
          <div className={s.caption}>Оформите полис ОСАГО и получите кешбэк 10% на связь</div>
          {first ? <WelcomeComponent onClick={() => setFirst(false)} /> : <LicensePlateBlock />}
        </div>
        <div className={s.info}>
          {first && <PrivacyPolicyBlock />}
          <CacheBackLink />
          <br />
          <br />
          Участвуя в акции, вы соглашаетесь с тем, что ООО "МБ Маркетплейс" передаст информацию о вашем номере телефона
          и иную информацию, необходимую Организатору акции для расчета вознаграждения.
        </div>
      </div>
    </div>
  );
};
