import React from 'react';
import cn from 'classnames';
import { useStore, observer } from '@core';

import s from './PreliminaryCost.module.scss';
import { getBeautifulNumber } from '@utils/getBeautifulNumber';

export type PreliminaryCostProps = {
  className?: string;
};

export const PreliminaryCost = observer(({ className }: PreliminaryCostProps) => {
  const store = useStore();
  const average = Math.round(store.form.dataByForm.preliminaryData.data?.results.avg || 0);

  if (average === 0) return null;

  return (
    <div className={cn(s.root, className)}>
      <div>
        <div className={s.caption}>Предварительно</div>без учета стажа вождения
      </div>
      <div className={s.price}>
        {getBeautifulNumber(average)}
        <span>₽</span>
      </div>
      <div className={s.desc}>Средняя стоимость</div>
    </div>
  );
});

PreliminaryCost.displayName = 'PreliminaryCost';
