import React from 'react';

import { MainScreen } from './components/MainScreen/MainScreen';
import { useLocation } from 'react-router-dom';
import { SuccessScreen } from './components/SuccessScreen/SuccessScreen';
import { MistakeScreen } from './components/MistakeScreen/MistakeScreen';

export const MainPage: React.FC = () => {
  const { hash } = useLocation();
  const success = new URLSearchParams(hash.replace('#', '')).get('success');

  if (success === '1') return <SuccessScreen />;
  if (success === '0') return <MistakeScreen />;
  return <MainScreen />;
};
