import React from 'react';

import { useStore, observer, POLICY_SERIAL_LIST } from '@core';
import { createBlurTransform } from '@utils/createBlurTransform';

import { SelectDefault } from '../../../../common/components/Input/SelectDefault';
import { InputDefault } from '../../../../common/components/Input/InputDefault';
import { FormRow } from '../../../../common/components/FormRow';
import { FormWrapper } from '../../../../common/components/FormWrapper/FormWrapper';
import { Switch } from '../../../../common/components/Input/Switch';

import s from './index.module.scss';

export const OtherForm: React.FC = observer(() => {
  const store = useStore();

  if (!store) {
    return null;
  }
  const hasPrevPolicy = store.form.fields.hasPrevPolicy.value;
  const transform = createBlurTransform(() => store.form.setValueField('prevPolicyUnmaskId', ''));

  return (
    <div className={s.formWrapper}>
      <FormWrapper title="Прочее">
        <FormRow columns>
          <Switch name="hasPrevPolicy">Указать предыдущий полис</Switch>
          <Switch name="useTrailer">Управление автомобилем с прицепом</Switch>
        </FormRow>
        {hasPrevPolicy === 1 && (
          <FormRow columns>
            <SelectDefault
              view="fullWidth"
              placeholder="Серия полиса"
              name="prevPolicySerial"
              options={POLICY_SERIAL_LIST}
            />
            <InputDefault
              transform={transform}
              view="fullWidth"
              placeholder="Номер полиса"
              name="prevPolicyNumber"
              maxLength={10}
            />
          </FormRow>
        )}
      </FormWrapper>
    </div>
  );
});
