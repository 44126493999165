import { FetchApiError, http } from '@utils/http/http';
import { InfoOldPolicyData } from '@core/features/policy/policy.types';
import { API_INGURU_6_2 } from '@core/constants/api';

export const getInfoOldPolicy = (licensePlate: string) =>
  http.get({
    url: API_INGURU_6_2,
    params: {
      licensePlate,
      q: 'policy',
    },
  }) as Promise<FetchApiError<InfoOldPolicyData>>;
