import React from 'react';
import cn from 'classnames';
import arrow from '../../../../../assets/images/icons/arrow.svg';
import s from './SelectIcon.module.scss';

export type SelectIconProps = { view: boolean; open: boolean } & React.HTMLAttributes<HTMLSpanElement>;

export const SelectIcon: React.FC<SelectIconProps> = ({ view, open, ...props }) => {
  if (!view) {
    return null;
  }

  return (
    <span {...props} className={cn(s.selectIconWrapper, { [s.listIconActive]: open })}>
      <img className={cn(s.listIcon)} src={arrow} alt="<" />
    </span>
  );
};
