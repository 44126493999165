import { apiGetToken } from '../../services/api/token';
import {
  InitCalculatorProps,
  InitCalculatorReturn,
  InitInsuranceData,
  InfoOldPolicyData,
} from '../../typings/init.typings';

import { ERROR_TYPE_CONTROLLER_TEXT } from '../../constants/error';
import {
  TOKEN_KEY,
  ORDER_KEY,
  OLD_ORDER_KEY,
  ADV_P_ID,
  OLD_ADV_P_ID,
  TOKEN_KEY_TIME,
  ORDER_DATA,
} from '../../constants/keys';

import { dataByKey } from '../../utils/data-by-key/data-by-key';

import { apiGetListInsurance, apiGetInfoOldPolicy } from '../../services/api/insurance';

import { reloadCaptcha } from '../../services/captcha/captcha';
import { logError } from '@utils/logger';
import { ApiGetTokenProp, ApiGetTokenPropReturn } from '@core/typings/token.typings';
import { storage } from '@core/services/storage';

const saveToLocalStorage = (ob: InitCalculatorReturn) => {
  Object.keys(ob).forEach((key) => {
    if (key === ORDER_DATA) return;
    const val = ob[key as keyof typeof ob];
    if (val) storage.setItem(key, val);
  });
};

const query = async (params: ApiGetTokenProp) => {
  try {
    const data = await apiGetToken(params);
    if (!data || !data.token) {
      logError(new Error(`${ERROR_TYPE_CONTROLLER_TEXT} initCalculator`));
      return null;
    }
    return data;
  } catch (e) {
    logError(e);
    return null;
  }
};

const getInitCalculatorObj = async (props?: InitCalculatorProps): Promise<InitCalculatorReturn> => {
  const { token, fromOrderKey, oldOrderKey, advPId, oldAdvPId } = props || {};
  const newOrderKey = (await dataByKey(ORDER_KEY)) || fromOrderKey || '';

  return {
    [ORDER_KEY]: newOrderKey,
    [TOKEN_KEY_TIME]: (await dataByKey(TOKEN_KEY_TIME)) || '',
    [TOKEN_KEY]: (await dataByKey(TOKEN_KEY)) || token || '',
    [OLD_ORDER_KEY]: (await dataByKey(OLD_ORDER_KEY)) || oldOrderKey || '',
    [ADV_P_ID]: (await dataByKey(ADV_P_ID)) || advPId || '',
    [OLD_ADV_P_ID]: (await dataByKey(OLD_ADV_P_ID)) || oldAdvPId || '',
  };
};

const setToken = (ob: InitCalculatorReturn, data: ApiGetTokenPropReturn | null) => {
  if (data?.token) {
    ob[TOKEN_KEY] = data.token;
    ob[TOKEN_KEY_TIME] = new Date().toString();
  }
};

function checkTokenTime(date: string | undefined | null) {
  if (!date) {
    return true;
  }

  const tokenTime = new Date(date);
  const now = new Date();
  const delta = Math.abs(Math.round((now.getTime() - tokenTime.getTime()) / 1000 / 60));

  return !(delta <= 60);
}

/**
 * Инициализация калькулятора.
 * @param props {InitCalculatorProps}
 */
export const initCalculator = async (props?: InitCalculatorProps): Promise<InitCalculatorReturn> => {
  const { orderKey, advPId } = props || {};

  const ob: InitCalculatorReturn = await getInitCalculatorObj(props);

  if (orderKey) {
    const data = await query({ adv_p_id: advPId, order_key: orderKey, from: null });
    if (data?.form_data) {
      ob[ORDER_DATA] = data.form_data;
    }
    setToken(ob, data);
  } else if (!ob[TOKEN_KEY] || !ob[TOKEN_KEY_TIME] || checkTokenTime(ob[TOKEN_KEY_TIME])) {
    const data = await query({ adv_p_id: advPId, order_key: 'new', from: ob[ORDER_KEY] || null });
    setToken(ob, data);
  }

  saveToLocalStorage(ob);

  return ob;
};

export const initInsurance = async (): Promise<InitInsuranceData> => await apiGetListInsurance();

export const getInfoOldPolicy = async (rgz: string): Promise<InfoOldPolicyData> => {
  await reloadCaptcha();

  return apiGetInfoOldPolicy({ rgz });
};
