import React, { useEffect } from 'react';
import { useScrollToError } from '@core';

import { Container } from '../../../common/components/Container/Container';
import { FormNavControl } from '../../../common/components/FormNavControl/FormNavControl';
import { OwnerForm } from './components/OwnerForm/OwnerForm';

import s from './StepOwnerPage.module.scss';
import { StepTitle } from '../../../common/components/StepTitle/StepTitle';
import { Steps } from '../../../common/components/Steps/Steps';
import { ErrorBoundary } from '../../../common/components/ErrorBoundary/ErrorBoundary';

export const StepOwnerPage = () => {
  const { scrollToTop } = useScrollToError();
  useEffect(() => {
    scrollToTop();
  }, [scrollToTop]);

  return (
    <Container className={s.root}>
      <Steps />
      <ErrorBoundary>
        <StepTitle>Собственник</StepTitle>
        <OwnerForm />
        <FormNavControl />
      </ErrorBoundary>
    </Container>
  );
};
