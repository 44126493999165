import React from 'react';
import { useStore, observer, PERIOD_OF_USE } from '@core';
import { SelectDefault } from '../Input/SelectDefault';
import { Switch } from '../Input/Switch';
import s from './CarPageSwitchers.module.scss';

export const CarPageSwitchers: React.FC = observer(() => {
  const store = useStore();

  if (!store) {
    return null;
  }

  const enablePeriod = store.form.getValueField('enablePeriod');
  return (
    <div className={s.carPageSwitchers}>
      <div className={s.switcherBox}>
        <Switch name="enablePeriod">Полис на несколько месяцев</Switch>
        {!!enablePeriod.value && (
          <div className={s.carPagePeriodInputBox}>
            <SelectDefault
              view="fullWidth"
              placeholder="Период использования"
              inputMode="none"
              name="usePeriod"
              options={PERIOD_OF_USE}
            />
          </div>
        )}
      </div>
    </div>
  );
});
