import React, { FC } from 'react';
import cn from 'classnames';
import s from './Alert.module.scss';

export type AlertProps = {
  className?: string;
  children?: React.ReactNode;
};

export const Alert: FC<AlertProps> = ({ className, children }) => (
  <div className={cn(s.root, className)}>
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22ZM11 7C11 6.44772 11.4477 6 12 6C12.5523 6 13 6.44772 13 7V14C13 14.5523 12.5523 15 12 15C11.4477 15 11 14.5523 11 14V7ZM13 17C13 17.5523 12.5523 18 12 18C11.4477 18 11 17.5523 11 17C11 16.4477 11.4477 16 12 16C12.5523 16 13 16.4477 13 17Z"
        fill="white"
      />
    </svg>
    {children}
  </div>
);
