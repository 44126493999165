import React, { FC } from 'react';
import cn from 'classnames';
import s from './SuccessBlock.module.scss';
import { PandaSvg } from '../PandaSvg/PandaSvg';

export type SuccessBlockProps = {
  className?: string;
  email: string;
};

export const SuccessBlock: FC<SuccessBlockProps> = ({ className, email }) => (
  <div className={cn(s.root, className)}>
    <PandaSvg className={s.panda} />
    <div>{['Полис ОСАГО отправлен на указанный вами адрес электронной почты', email].filter(Boolean).join('—')}</div>
  </div>
);
