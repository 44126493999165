import React, { FC } from 'react';
import cn from 'classnames';
import s from './MistakeScreen.module.scss';
import { Container } from '../../../../../common/components/Container/Container';
import { Button } from '../../../../../common/components/Button';
import { PandaSvg } from './components/PandaSvg';
import { URL_INDEX } from '../../../../../common/constants/url';

export type MistakeScreenProps = {
  className?: string;
};

export const MistakeScreen: FC<MistakeScreenProps> = ({ className }) => {
  const toHome = () => window.location.replace(URL_INDEX);
  return (
    <Container className={cn(s.root, className)}>
      {/* Это заглушка для корректного выравнивания без заморочек */}
      <div />
      <div className={s.main}>
        <PandaSvg />
        <div className={s.title}>Что-то пошло не так</div>
        <div>Данные сохранились. Пожалуйста, попробуйте оформить полис еще раз</div>
      </div>
      <div className={s.btns}>
        <Button onClick={toHome} className={s.btn}>
          Попробовать еще раз
        </Button>
      </div>
    </Container>
  );
};
