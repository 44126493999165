import React, { FC, useMemo } from 'react';
import cn from 'classnames';
import s from './PandasByStep.module.scss';
import { observer } from 'mobx-react-lite';
import { useStore } from '@core';
import { PandaSvg1 } from '../PandaSvg1';
import { PandaSvg2 } from '../PandaSvg2';
import { PandaSvg3 } from '../PandaSvg3';

export type PandasByStepProps = {
  className?: string;
};

export const PandasByStep: FC<PandasByStepProps> = observer(({ className }) => {
  const store = useStore();
  const step = store.step.currentStep;
  const pandas = useMemo(
    () => [
      <PandaSvg1 className={className} />,
      <PandaSvg2 className={className} />,
      <PandaSvg3 className={className} />,
    ],
    [className]
  );
  return <div className={cn(s.root, className)}>{pandas[step % pandas.length]}</div>;
});

PandasByStep.displayName = 'PandasByStep';
