import axios, { AxiosResponse } from 'axios';

import { reloadCaptcha } from '@core';
import { HttpProperties, HttpPropertiesReturn, HttpMethods, HttpHeaders } from '../../typings/http.typings';

import { ERROR_TYPE_HTTP_TEXT, ERROR_TYPE_TOKEN_TEXT } from '../../constants/error';
import { AUTH_HEADER_CONST, HTTP_TIMEOUT, URLS_NOT_AUTH } from '../../constants/api';

import { getTokenStorage } from '../../services/storage/methods';

const instance = axios.create({
  timeout: HTTP_TIMEOUT,
});

const _handlerHttpResponse = <T>(response: AxiosResponse): T => {
  if (response.status === 200 || response.status === 201) {
    return response.data;
  }

  throw new Error(`${ERROR_TYPE_HTTP_TEXT}${response.status}`);
};

const _createHeaders = async (url: string): Promise<HttpHeaders> => {
  const token = await getTokenStorage();
  const headers: HttpHeaders = {
    [`Content-Type`]: `application/json;charset=utf-8`,
  };

  if (URLS_NOT_AUTH.indexOf(url) === -1) {
    if (!token) {
      throw new Error(ERROR_TYPE_TOKEN_TEXT);
    }
    headers.Authorization = `${AUTH_HEADER_CONST} ${token}`;
  }
  return headers;
};

const _checkCaptchaTokenError = (response: AxiosResponse): boolean => {
  if (response.data.error && response.data.cod == 102 && response.data.error.indexOf('Капча не прошла проверку')) {
    return true;
  }

  return false;
};

const _createHttpProperty = async (method: HttpMethods, props: HttpProperties): Promise<HttpPropertiesReturn> => {
  const instanceBody: HttpPropertiesReturn = {
    ...props,
    method,
    url: `${props.url}`,
    params: props.params,
    headers: await _createHeaders(props.url),
  };

  if (props.data) {
    instanceBody.data = props.data;
  }

  return instanceBody;
};

export type HTTPResponse<T> = {
  url: string;
  data: T | null;
  error: string | null;
  status: number;
};

export type FetchApiError<T> = T & {
  errors: ErrorBackend[];
};

export type ErrorBackend = {
  code: number;
  error: string;
};

export const http = {
  get: async <T>(props: HttpProperties): Promise<T> => {
    let resp = await instance.request(await _createHttpProperty('get', props));

    if (_checkCaptchaTokenError(resp)) {
      const token = await reloadCaptcha();
      resp = await instance.request(
        await _createHttpProperty('get', {
          ...props,
          params: {
            ...props.params,
            captcha_v: 3,
            captcha_token: token,
          },
        })
      );
    }

    return _handlerHttpResponse(resp);
  },

  post: async <T>(props: HttpProperties): Promise<T> => {
    let resp = await instance.request(await _createHttpProperty('post', props));

    if (_checkCaptchaTokenError(resp)) {
      const token = await reloadCaptcha();
      resp = await instance.request(
        await _createHttpProperty('post', {
          ...props,
          data: {
            ...props.data,
            captcha_v: 3,
            captcha_token: token,
          },
          options: {
            ...props.options,
            headers: {
              ...props.options?.headers,
              'Cache-Control': 'no-cache',
            },
          },
        })
      );
    }

    return _handlerHttpResponse(resp);
  },
};
