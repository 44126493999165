import { getInfoOldPolicy } from '../policy.requests';
import { useEffect } from 'react';
import { useStore } from '@core';
import { useEvent } from '@core/hooks/useEvent';
import { useValidator } from '@core/providers/ValidatorProvider';

export const useFetchPrevPolicy = () => {
  const store = useStore();
  const { validateByKey } = useValidator();

  const licensePlate = store.form.getValueField('licensePlate')?.value?.replace(/\s/g, '');

  const effect = useEvent(() => {
    if (typeof window === 'undefined') return;
    const id = window.setTimeout(async () => {
      if (!licensePlate) return;

      const errAuto = validateByKey(licensePlate, 'licensePlateAuto', { type: 'soft' });
      const errBike = validateByKey(licensePlate, 'licensePlateBike', { type: 'soft' });

      if (errAuto || errBike) return;

      const info = await getInfoOldPolicy(licensePlate);
      if (!info || info.errors?.length || !info?.results || !info?.results.policy) return;

      store.form.setValueField('hasPrevPolicy', 1);

      const [serial, number] = info.results.policy.split(' ');
      if (serial && number) {
        store.form.setValueField('prevPolicySerial', serial);
        store.form.setErrorField('prevPolicySerial', '');

        store.form.setValueField('prevPolicyNumber', number);
        store.form.setErrorField('prevPolicyNumber', '');

        store.form.setValueField('prevPolicyUnmaskId', info.results.unmaskId);
      }

      if (info.results.skId) {
        store.form.setValueField('prevPolicyCompany', info.results.skId);
        store.form.setErrorField('prevPolicyCompany', '');
      }
    }, 700);

    return () => clearTimeout(id);
  });

  useEffect(effect, [effect, licensePlate]);
};
