import React, { ErrorInfo } from 'react';

import s from './ErrorBoundary.module.scss';
import { Button } from '../Button';
import { Alert } from '../Alert';

export type ErrorBoundaryProps = { children: React.ReactNode };
export type ErrorBoundaryState = { hasError: boolean };

export class ErrorBoundary extends React.Component<ErrorBoundaryProps, ErrorBoundaryState> {
  constructor(props: ErrorBoundaryProps) {
    super(props);

    this.state = { hasError: false };
  }

  static getDerivedStateFromError() {
    return { hasError: true };
  }

  componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    console.warn({ error, errorInfo }); // eslint-disable-line no-console
    if (error.name === 'ChunkLoadError') {
      typeof window !== 'undefined' && window.location.reload();
    }
  }

  render() {
    if (!this.state.hasError) return <>{this.props.children}</>;

    const reset = () => {
      this.setState({ hasError: false });
      window.location.reload();
    };

    return (
      <div className={s.root}>
        <Alert className={s.alert}>Упс! Что-то пошло не так</Alert>
        <Button className={s.button} onClick={reset}>
          Попробовать еще раз
        </Button>
      </div>
    );
  }
}
