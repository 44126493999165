import React from 'react';

import { Container } from '../../../common/components/Container/Container';
import { TableOffers } from './components/TableOffers';

import { StepTitle } from '../../../common/components/StepTitle/StepTitle';
import { Button } from '../../../common/components/Button';
import { NotFound } from './components/TableOffers/NotFound';
import { observer } from 'mobx-react-lite';
import { useStepCompanyOffers } from '@core/hooks/useStepCompanyOffers';
import s from './StepCompanyOffers.module.scss';
import { Steps } from '../../../common/components/Steps/Steps';
import { ErrorBoundary } from '../../../common/components/ErrorBoundary/ErrorBoundary';

export const StepCompanyOffers: React.FC = observer(() => {
  const { statusInitPartners, offers, setActiveOffer, prevStep, setNotFound, notFound } = useStepCompanyOffers();

  return (
    <Container className={s.root}>
      <Steps />
      <ErrorBoundary>
        <StepTitle>Выбор компании и оплата</StepTitle>
        {statusInitPartners &&
          (notFound ? (
            <NotFound className={s.notFound} />
          ) : (
            <TableOffers
              setActiveOffer={setActiveOffer}
              offers={offers}
              onNotFound={() => setNotFound(true)}
              logoType="square"
            />
          ))}
        <div className={s.nav}>
          <Button styleType={notFound ? 'accent' : 'second'} className={s.back} onClick={() => prevStep()}>
            {notFound ? 'Редактировать данные' : 'Назад'}
          </Button>
        </div>
      </ErrorBoundary>
    </Container>
  );
});

StepCompanyOffers.displayName = 'StepCompanyOffers';
