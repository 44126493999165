import React, { FC } from 'react';
import s from './Layout.module.scss';
import { PandasByStep } from '../pandas/PandasByStep/PandasByStep';

export const Layout: FC<{ children?: React.ReactNode }> = ({ children }) => (
  <div className={s.root}>
    <main className={s.main}>{children}</main>
    <PandasByStep className={s.panda} />
  </div>
);
